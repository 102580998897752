import React from "react";
import { Menu, Icon, Row, Col, Avatar } from "antd";
import logo from "../../../assets/web/logo_Desktop.svg";
import "../../../styles/header.less";
import { Link } from "react-router-dom";
import Can from "../../../util/helpers/Can";
import { getUserRole } from "../../../services/api/axiosDefaults";


const { SubMenu } = Menu;

class WebHeader extends React.Component {
    
    render() {
        //get user role from localstorage
        const role = getUserRole();
        const insurance_only = role.length === 1 && role.includes("insurance_only")

        const {
            loggedInUser,
            showLoginModal,
            logout,
            downloadHelpFile,
            editProfile,
        } = this.props;
        return (
            <Row id="lowerheader">
                <Col md={6} sm={12} id="logo">
                    <Link to="/">
                        <img src={logo} alt="logo" />
                    </Link>
                </Col>
                {loggedInUser ? (
                    !insurance_only ?
                    <>
                    {console.log(role.length === 1 && role.includes("insurance_only"), "--------<<")}
                        <Col md={10} sm={6}>
                            <Menu mode="horizontal" className="menu">
                                <Menu.Item key="1">
                                    <Link to="/home">Home</Link>
                                </Menu.Item>
                                <SubMenu
                                    className="submenu"
                                    title={
                                        <span>
                                            Organization &nbsp;
                                            <Icon type="caret-down" />
                                        </span>
                                    }
                                >
                                    <Menu.Item
                                        key="organizations"
                                        className="ant-menu-item"
                                        onItemHover={() => {}}
                                        onClick={() => {}}
                                    >
                                        <Link to="/organizations">
                                            My Organization
                                        </Link>
                                    </Menu.Item>
                                    <Can
                                        role={role}
                                        perform="location:list"
                                        yes={() => (
                                            <Menu.Item
                                                key="reports:5"
                                                className="ant-menu-item"
                                                onItemHover={() => {}}
                                                onClick={() => {}}
                                            >
                                                <Link to="/locations">
                                                    Offices
                                                </Link>
                                            </Menu.Item>
                                        )}
                                        no={() => (
                                            <Menu.Item hidden="true"></Menu.Item>
                                        )}
                                    />

                                    <Can
                                        role={role}
                                        perform="location:revision"
                                        yes={() => (
                                            <Menu.Item
                                                key="reports:5"
                                                className="ant-menu-item"
                                                onItemHover={() => {}}
                                                onClick={() => {}}
                                            >
                                                <Link to="/revisions">
                                                    Office Revisions
                                                </Link>
                                            </Menu.Item>
                                        )}
                                        no={() => (
                                            <Menu.Item hidden="true"></Menu.Item>
                                        )}
                                    />
                                </SubMenu>
                                <SubMenu
                                    className="submenu"
                                    title={
                                        <span>
                                            Reports &nbsp;
                                            <Icon type="caret-down" />
                                        </span>
                                    }
                                >
                                    <Menu.Item
                                        key="reports:1"
                                        onItemHover={() => {}}
                                        onClick={() => {}}
                                    >
                                        <Link to="/incidents">
                                            Incident Reports
                                        </Link>
                                    </Menu.Item>
                                    {role.includes("approver") && <Menu.Item
                                        key="reports:2"
                                        onItemHover={() => {}}
                                        onClick={() => {}}
                                    >
                                        <Link to="/incidents/pending-approval">Incident Approval</Link>
                                    </Menu.Item>}
                                    
                                    <Can
                                        role={role}
                                        perform="insurance:manage"
                                        yes={() => (
                                            <Menu.Item
                                                key="reports:5"
                                                className="ant-menu-item"
                                                onItemHover={() => {}}
                                                onClick={() => {}}
                                            >
                                                <Link to="/reports/insurance-reports">
                                                    Insurance Reports
                                                </Link>
                                            </Menu.Item>
                                        )}
                                        no={() => (
                                            <Menu.Item hidden="true"></Menu.Item>
                                        )}
                                    />
                                    <Menu.Item
                                        key="reports:4"
                                        onItemHover={() => {}}
                                        onClick={() => {}}
                                    >
                                        <Link to="/drafts">My Drafts</Link>
                                    </Menu.Item>
                                </SubMenu>
                                <Menu.Item
                                    key="2"
                                    onClick={() => {
                                        downloadHelpFile();
                                    }}
                                >
                                    How to use SIMSon{" "}
                                </Menu.Item>
                            </Menu>
                        </Col>
                        <Col md={8} sm={6}>
                            <Menu mode="horizontal">
                                <SubMenu
                                    className="submenu"
                                    title={
                                        <>
                                            <Avatar
                                                size="large"
                                                icon="user"
                                                style={{
                                                    border: "2px solid #FE7B00",
                                                }}
                                            />
                                        </>
                                    }
                                >
                                    <Menu.Item
                                        key="profile:1"
                                        onClick={(e) => editProfile(e)}
                                    >
                                        Profile Edit
                                    </Menu.Item>
                                    {/* <Menu.Item key="profile:2">Settings</Menu.Item> */}
                                    <Menu.Item key="profile:3" onClick={logout}>
                                        Logout
                                    </Menu.Item>
                                </SubMenu>
                            </Menu>
                            <Menu mode="horizontal">
                                <Menu.Item key="4" className="orange_buttons">
                                    <Link to="/add/incident">
                                        Report Incident
                                    </Link>
                                </Menu.Item>
                            </Menu>
                        </Col>
                    </> : 
                    <>
                    <Col md={10} sm={6}>
                            <Menu mode="horizontal" className="menu">
                                <SubMenu
                                    className="submenu"
                                    title={
                                        <span>
                                            Organization &nbsp;
                                            <Icon type="caret-down" />
                                        </span>
                                    }
                                >
                                    <Menu.Item
                                        key="organizations"
                                        className="ant-menu-item"
                                        onItemHover={() => {}}
                                        onClick={() => {}}
                                    >
                                        <Link to="/organizations">
                                            My Organization
                                        </Link>
                                    </Menu.Item>
                                    <Can
                                        role={role}
                                        perform="location:list"
                                        yes={() => (
                                            <Menu.Item
                                                key="reports:5"
                                                className="ant-menu-item"
                                                onItemHover={() => {}}
                                                onClick={() => {}}
                                            >
                                                <Link to="/locations">
                                                    Offices
                                                </Link>
                                            </Menu.Item>
                                        )}
                                        no={() => (
                                            <Menu.Item hidden="true"></Menu.Item>
                                        )}
                                    />

                                    <Can
                                        role={role}
                                        perform="location:revision"
                                        yes={() => (
                                            <Menu.Item
                                                key="reports:5"
                                                className="ant-menu-item"
                                                onItemHover={() => {}}
                                                onClick={() => {}}
                                            >
                                                <Link to="/revisions">
                                                    Office Revisions
                                                </Link>
                                            </Menu.Item>
                                        )}
                                        no={() => (
                                            <Menu.Item hidden="true"></Menu.Item>
                                        )}
                                    />
                                </SubMenu>
                            </Menu>
                            </Col>
                            <Col md={8} sm={6}>
                            <Menu mode="horizontal">
                                <SubMenu
                                    className="submenu"
                                    title={
                                        <>
                                            <Avatar
                                                size="large"
                                                icon="user"
                                                style={{
                                                    border: "2px solid #FE7B00",
                                                }}
                                            />
                                        </>
                                    }
                                >
                                    <Menu.Item
                                        key="profile:1"
                                        onClick={(e) => editProfile(e)}
                                    >
                                        Profile Edit
                                    </Menu.Item>
                                    {/* <Menu.Item key="profile:2">Settings</Menu.Item> */}
                                    <Menu.Item key="profile:3" onClick={logout}>
                                        Logout
                                    </Menu.Item>
                                </SubMenu>
                            </Menu>
                        </Col>
                    </>
                ) : (
                    <Col span={12}>
                        <Menu mode="horizontal" className="menu">
                            <Menu.Item key="1">
                                <Link to="/">Home</Link>
                            </Menu.Item>
                            <Menu.Item
                                key="2"
                                onClick={() => {
                                    downloadHelpFile();
                                }}
                            >
                                How to use SIMSon{" "}
                            </Menu.Item>
                            <Menu.Item
                                key="3"
                                className="orange_buttons"
                                onClick={showLoginModal}
                            >
                                Login
                            </Menu.Item>
                        </Menu>
                    </Col>
                )}
            </Row>
        );
    }
}

export default WebHeader;
