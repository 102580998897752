import React, {Suspense} from 'react';
import PageHeader from "../../../reusable-components/Header/Index"; 
import PageFooter from "../../../reusable-components/PageFooter";
import {Layout, Spin} from "antd";
import {Loader} from "../../../reusable-components/Spinner";

const {Content} = Layout;
class LayoutPage extends React.Component {
    componentDidMount() {
        
    }

  

    render() {
        const {children} = this.props;
        const user = localStorage.getItem('user-access') && JSON.parse(localStorage.getItem('user-access'));
        return(
            <>
            <div id="main-wrapper">
             {/* pass the logged in user object to the header component i.e loggedInUser={object} */}
            <PageHeader loggedInUser={user} logoutMethod={this.props.logout}/>
            <Content style={{width: "100%"}}>
                <Suspense fallback={<Loader />}>
                    {/* Render the pages here */}
                    {children}
                </Suspense>
            </Content>
            <PageFooter />
            </div>
            </>
        );
    }
}

export default LayoutPage;

const styles = {
    spin: {
        textAlign: "center",   
    }
}