import { apiRequest, apiRequestOthers } from "../apiRequest";
import {
  GET_OFFICES_ENDPOINT,
  GET_PROPERTY_OWNERSHIP_STATUSES,
  GET_VEHICLE_OWNERSHIP_TYPES,
  GET_LOCATION_TYPES,
  GET_VEHICLE_CONDITION,
  GET_CHILDREN_OFFICES,
  OFFICE_REVISION_ENDPOINT,
  GET_ASSET_TYPES,
} from "../urls";
import { pageSize } from "../../../util/helpers/enum";

export const getOffices = (page, search) => {
  let url = `${GET_OFFICES_ENDPOINT}?page_size=${pageSize}`;
  if (page) {
    url = `${url}&page=${page}`;
  }
  if (search) {
    url = `${url}&search=${search}`;
  }
  return apiRequest("GET", url, {});
};

export const getChildrenOffices = () => {
  return apiRequest("GET", GET_CHILDREN_OFFICES, {});
};

export const getOffice = (id) => {
  return apiRequest("GET", `${GET_OFFICES_ENDPOINT}${id}/`, {});
};

export const downloadOfficeData = (id) => {
  return apiRequestOthers(
    "GET",
    `${GET_OFFICES_ENDPOINT}${id}/`,
    {},
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "arraybuffer"
  );
};

export const getOfficeRevisions = (page) => {
  let url = `${OFFICE_REVISION_ENDPOINT}?page_size=${pageSize}`;
  if (page) {
    url = `${url}&page=${page}`;
  }
  return apiRequest("GET", url, {});
};

export const approveRevision = (office_id, revision_id) => {
  return apiRequest(
    "POST",
    `${GET_OFFICES_ENDPOINT}${office_id}/accept-revision/${revision_id}/`,
    {}
  );
};

export const declineRevision = (office_id, revision_id) => {
  return apiRequest(
    "DELETE",
    `${GET_OFFICES_ENDPOINT}${office_id}/revisions/${revision_id}/`,
    {}
  );
};

export const updateOffice = (id, data) => {
  return apiRequest("PATCH", `${GET_OFFICES_ENDPOINT}${id}/`, data);
};

export const getLocationTypes = () => {
  return apiRequest("GET", GET_LOCATION_TYPES, {});
};

export const getPropertyOwnershipStatuses = () => {
  return apiRequest("GET", GET_PROPERTY_OWNERSHIP_STATUSES, {});
};

export const getVehicleOwnershipStatuses = () => {
  return apiRequest("GET", GET_VEHICLE_OWNERSHIP_TYPES, {});
};

export const getVehicleCondition = () => {
  return apiRequest("GET", GET_VEHICLE_CONDITION, {});
};
/* properties CRUD */

export const getLocationProperties = (id) => {
  return apiRequest("GET", `${GET_OFFICES_ENDPOINT}${id}/properties/`, {});
};

export const addLocationProperties = (id, data) => {
  console.log(data);
  return apiRequest("POST", `${GET_OFFICES_ENDPOINT}${id}/properties/`, data);
};

export const updateLocationProperties = (id, data, property_id) => {
  console.log(data);
  return apiRequest(
    "PATCH",
    `${GET_OFFICES_ENDPOINT}${id}/properties/${property_id}/`,
    data
  );
};

export const deleteLocationProperties = (id, property_id) => {
  return apiRequest(
    "DELETE",
    `${GET_OFFICES_ENDPOINT}${id}/properties/${property_id}/`
  );
};
/* vehicle CRUD */

export const getLocationVehicles = (id) => {
  return apiRequest("GET", `${GET_OFFICES_ENDPOINT}${id}/vehicles/`, {});
};

export const addLocationVehicles = (id, data) => {
  return apiRequest("POST", `${GET_OFFICES_ENDPOINT}${id}/vehicles/`, data);
};

export const updateLocationVehicles = (id, data, vehicle_id) => {
  return apiRequest(
    "PATCH",
    `${GET_OFFICES_ENDPOINT}${id}/vehicles/${vehicle_id}/`,
    data
  );
};

export const deleteLocationVehicles = (id, vehicle_id) => {
  return apiRequest(
    "DELETE",
    `${GET_OFFICES_ENDPOINT}${id}/vehicles/${vehicle_id}/`
  );
};

/* asset types */
export const getAssetTypesApi = () => {
  return apiRequest("GET", GET_ASSET_TYPES, {});
};

export const getAssetsApi = (id) => {
  return apiRequest("GET", `${GET_OFFICES_ENDPOINT}${id}/assets/`, {});
};

export const addAssetsApi = (id, data) => {
  return apiRequest("POST", `${GET_OFFICES_ENDPOINT}${id}/assets/`, data);
};

export const updateAssetsApi = (id, data, asset_id) => {
  return apiRequest(
    "PATCH",
    `${GET_OFFICES_ENDPOINT}${id}/assets/${asset_id}/`,
    data
  );
};

export const deleteAssetApi = (id, asset_id) => {
  return apiRequest(
    "DELETE",
    `${GET_OFFICES_ENDPOINT}${id}/assets/${asset_id}/`
  );
};
