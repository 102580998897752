import React from "react";
import { Spin, Icon } from 'antd';

const Spinner = () => {
    const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
    return(
        <Spin indicator={antIcon} style={{color: "#E84B07"}} />
    );
}
export default  Spinner;


export const Loader = props => {
  const { tip } = props;
  const antIcon = <Icon type="loading" style={{ fontSize: 48, color: "#8092a5" }} spin />;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "5%"
      }}
    >
      <Spin indicator={antIcon} size="large" tip="Please wait..." style={{color: "#E84B07"}} data-testid="global-loading-spinner" />
    </div>
  );
};