export const captchaSiteKeys = {
    prod: process.env.REACT_APP_CAPTCHA_SITE_KEY,
    testing: process.env.REACT_APP_CAPTCHA_SITE_KEY_DEBUG,
  };

  export const pageSize = 20;

  export const urls = {
    prod: process.env.REACT_APP_PRODUCTION_URL,
    staging: process.env.REACT_APP_STAGING_URL,
  }
  
  export const roles = [
    {id: 1, name: "Insurance User", roleName: "insurance_user"},
    {id: 2, name: "Senior", roleName: "senior"},
    {id: 3, name: "Manager", roleName: "manager"},
    {id: 4, name: "Approver", roleName: "approver"},
    {id: 5, name: "Office Data Editor", roleName: "office_data_editor"},
    {id: 6, name: "Insurance Only", roleName: "insurance_only",}
  ]

  export const officesHints = {
    construction: {
      title: "Construction",
      description: "Construction (e.g., frame, masonry, masonry veneer, superior construction, mixed—masonry/frame)"
    },
    occupancy: {
      title: "Occupancy",
      description: "Occupancy (e.g., when building was built, last large renovated, how the building is being used, different levels)"
    },
    protection: {
      title: "Protection",
      description: "Protection (e.g., building has fire repressing sprinklers, alarms, security guards, distance to a good responding fire department, distance to a good fire hydrant)"
    },
    exposure: {
      title: "Exposure",
      description: "Exposure (what is neighboring this property or the surrounding area, such as an office building, a subdivision, or a fireworks factory)"
    },
    surfaceArea: {
      title: "Surface Area",
      description: "Please provide the total surface area of the building in square metres or square feet"
    },
    personalProperty: {
      title: "Personal Property (PP)",
      description: "Replacement values for CARE owned, or fully responsible for, furnishings and equipment. Examples are computers, desks, equipment, warehouse items, etc. These values, per CARE owned or leased/rented locations, are OPTIONAL under the CAREUSA GRI program. By adding a value next to the property location’s full physical address, you are enrolling in this optional coverage for damage and/or theft to these CARE assets. If no value is listed, then you are not enrolling in this optional coverage and this is only recommended if the total PP value for the location is under $10,000, you have a strong local policy, have no donor requirements, or simply have no threat of loss at this location (i.e. SubOffice where we transport mobile equipment to and from and nothing is usually kept at the location)."
    },
    realProperty: {
      title: "Real Property (RP)",
      description: "Replacement value for the CARE owned, or fully responsible for, building itself. This is the actual brick and mortar HARD aspects that are part of the building itself. Examples are the roof, windows, plumbing, lightening, etc. If CARE owns the location this is a MANDATORY value under CAREUSA GRI program that would account for the building’s appraised value or what it would cost us to replace the building in the event it was totally destroyed. For leased/rented location it is extremely rare to have this value as it is the landlord’s/owner’s responsible to cover their own building damage. Now if CARE is somehow found legally negligent for having caused damaged (we left the water or a heater running for example) to the building itself, then we have another policy that would cover this liability and that is why all CARE locations, owned or leased/rented, must be listed with full physical addresses and we have 180 days to any add newly acquired locations to our policies."
    }
  }

