import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
  {
    getClaimStatus: null,
    getClaimStatusSuccess: ["responseData"],
    getClaimStatusFailure: ["error"],

    getClaimTypes: null,
    getClaimTypesSuccess: ["responseData"],
    getClaimTypesFailure: ["error"],

    createInsuranceReport: ["data"],
    createInsuranceReportSuccess: ["responseData"],
    createInsuranceReportFailure: ["error"],

    getInsuranceReports: [
      "page",
      "search",
      "status",
      "office",
      "from_date",
      "end_date",
    ],
    getInsuranceReportsSuccess: ["responseData"],
    getInsuranceReportsFailure: ["error"],

    getSingleReport: ["data"],
    getSingleReportSuccess: ["responseData"],
    getSingleReportFailure: ["error"],

    updateReport: ["data"],
    updateReportSuccess: ["responseData"],
    updateReportFailure: ["error"],

    downloadReport: ["data"],
    downloadReportSuccess: ["responseData"],
    downloadreportFailure: ["error"],

    downloadInsurenceReportXls: [
      "search",
      "status",
      "office",
      "from_date",
      "end_date",
    ],
    downloadInsurenceReportXlsSuccess: ["responseData"],
    downloadInsurenceReportXlsFailure: ["errors"],

    deleteAttachment: ["report_id", "attachment_id"],
    deleteAttachmentSuccess: ["responseData"],
    deleteAttachmentFailure: ["error"],

    deleteClaim: ["report_id", "claim_id"],
    deleteClaimSuccess: ["responseData"],
    deleteClaimFailure: ["error"],

    updateClaim: ["report_id", "claim_id", "data"],
    updateClaimSuccess: ["responseData"],
    updateClaimFailure: ["error"],

    addComment: ["report_id", "data"],
    addCommentSuccess: ["responseData"],
    addCommentFailure: ["error"],

    deleteOwnComment: ["report_id", "comment_id"],
    deleteOwnCommentSuccess: ["responseData"],
    deleteOwnCommentFailure: ["error"],

    deleteReport: ["report_id"],
    deleteReportSuccess: ["responseData"],
    deleteReportFailure: ["error"],

    resetInsuranceReport: null,
  },
  {}
);
