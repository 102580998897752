import { createReducer } from "reduxsauce";
import Types from "./actionTypes";

export const INITIAL_STATE = {
  error: false,
  errors: null,
  offices: null,
  office: null,
  locationTypes: null,
  propertyStatuses: null,
  vehicleStatuses: null,
  message: "",
  variant: "",
  loading: false,
  step: null,
  properties: null,
  vehicles: null,
  conditions: null,
  isVehicleDeleted: false,
  allOffices: null,
  officeUpdateSuccess: null,
  officeRevisions: null,
  approveMessage: "",
  declineMessage: "",
  approveVariant: "",
  declineVariant: "",
  originalOfficeRecord: null,
  downloadLoading: false,
  deleteVehicleLoading: false,
  deletePropertyLoading: false,
  assets: null,
  assetTypes: null,
  isAssetDeleted: false,
  deleteAssetLoading: false,
};

export const getOffices = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, loading: true };
};

export const getOfficesSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    offices: action.responseData,
    loading: false,
    step: null,
    office: null,
  };
};

export const getOfficesFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, loading: false, message: action.error };
};

export const getOfficeRevision = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, loading: true };
};

export const getOfficesRevisionSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    officeRevisions: action.responseData,
    loading: false,
  };
};

export const getOfficesRevisionFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, loading: false, message: action.error };
};

export const approveRevision = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, loading: true };
};

export const approveRevisionSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    approveMessage: action.responseData,
    approveVariant: "success",
    loading: false,
  };
};

export const approveRevisionFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: true,
    loading: false,
    approveMessage: action.error,
    approveVariant: "error",
  };
};

export const declineRevision = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, loading: true };
};

export const declineRevisionSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    declineMessage: action.responseData,
    declineVariant: "success",
    loading: false,
  };
};

export const declineRevisionFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: true,
    loading: false,
    declineMessage: action.error,
    declineVariant: "error",
  };
};

export const getAllOffices = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, loading: true };
};

export const getAllOfficesSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    allOffices: action.responseData,
    loading: false,
  };
};

export const getAllOfficesFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, loading: false, message: action.error };
};

export const getOffice = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, loading: true, step: null };
};

export const getOfficeSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    office: action.responseData,
    originalOfficeRecord: action.responseData,
    loading: false,
  };
};

export const getOfficeFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, loading: false, message: action.error };
};

export const downloadOffice = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, downloadLoading: true };
};

export const downloadOfficeSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    officeExcelData: action.responseData,
    downloadLoading: false,
  };
};

export const downloadOfficeFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, downloadLoading: false };
};

export const updateOffice = (state = INITIAL_STATE, action) => {
  console.log(action);
  return { ...state, error: false, loading: true };
};

export const updateOfficeSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    loading: false,
    office: action.responseData,
  };
};

export const updateOfficeFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, errors: action.error, loading: false };
};

export const updateOfficeRecords = (state = INITIAL_STATE, action) => {
  console.log(action);
  return { ...state, error: false, loading: true, officeUpdateSuccess: null };
};

export const updateOfficeRecordsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    officeUpdateSuccess: action.responseData,
    loading: false,
  };
};

export const updateOfficeRecordsFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, errors: action.error, loading: false };
};

export const addProperties = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, loading: true };
};

export const addPropertiesSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    loading: false,
    properties: action.responseData,
  };
};

export const addPropertiesFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, errors: action.error, loading: false };
};

export const updateProperties = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, loading: true };
};

export const updatePropertiesSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    loading: false,
    properties: action.responseData,
  };
};

export const updatePropertiesFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, errors: action.error, loading: false };
};

export const deleteProperty = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, deletePropertyLoading: true };
};

export const deletePropertySuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    loading: false,
    properties: action.responseData,
    deletePropertyLoading: false,
  };
};

export const deletePropertyFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: true,
    errors: action.error,
    loading: false,
    deletePropertyLoading: false,
  };
};

export const addVehicles = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, loading: true };
};

export const addVehiclesSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    loading: false,
    vehicles: action.responseData,
  };
};

export const addVehiclesFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, errors: action.error, loading: false };
};

export const updateVehicle = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, loading: true };
};

export const updateVehicleSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    loading: false,
    vehicles: action.responseData,
  };
};

export const updateVehicleFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, errors: action.error, loading: false };
};

export const deleteVehicle = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    isVehicleDeleted: false,
    deleteVehicleLoading: true,
  };
};

export const deleteVehicleSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    loading: false,
    isVehicleDeleted: true,
    deleteVehicleLoading: false,
  };
};

export const deleteVehicleFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: true,
    errors: action.error,
    loading: false,
    deleteVehicleLoading: false,
  };
};

export const getLocationTypes = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, loading: true };
};

export const getLocationTypesSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    locationTypes: action.responseData,
    loading: false,
  };
};

export const getLocationTypesFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, loading: false, message: action.error };
};

export const getConditions = (state = INITIAL_STATE, action) => {
  return { ...state, error: false };
};

export const getConditionsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    conditions: action.responseData,
    loading: false,
  };
};

export const getConditionsFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, loading: false, message: action.error };
};

export const getVehicleStatuses = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, loading: true };
};

export const getVehicleStatusesSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    vehicleStatuses: action.responseData,
    loading: false,
  };
};

export const getVehicleStatusesFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, loading: false, message: action.error };
};

export const getPropertyStatuses = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, loading: true };
};

export const getPropertyStatusesSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    propertyStatuses: action.responseData,
    loading: false,
  };
};

export const getPropertyStatusesFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, loading: false, message: action.error };
};

export const getAssetTypes = (state = INITIAL_STATE, action) => {
  console.log("Reducer: getAssetTypes");
  return { ...state, error: false, loading: true };
};

export const getAssetTypesSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    assetTypes: action.responseData,
    loading: false,
  };
};

export const getAssetTypesFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, loading: false, message: action.error };
};

export const addAssets = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, loading: true };
};

export const addAssetsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    loading: false,
    assets: action.responseData,
  };
};

export const addAssetsFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, errors: action.error, loading: false };
};

export const updateAssets = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, loading: true };
};

export const updateAssetsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    loading: false,
    assets: action.responseData,
  };
};

export const updateAssetsFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, errors: action.error, loading: false };
};

export const deleteAsset = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    isAssetDeleted: false,
    deleteAssetLoading: true,
  };
};

export const deleteAssetSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    loading: false,
    isAssetDeleted: true,
    deleteAssetLoading: false,
  };
};

export const deleteAssetFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: true,
    errors: action.error,
    loading: false,
    deleteAssetLoading: false,
  };
};

export const nextPage = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    office: { ...state.office, ...action.data },
    step: action.nextStep,
  };
};

export const previousPage = (state = INITIAL_STATE, action) => {
  return { ...state, step: action.step, officeExcelData: null };
};

export const updateLocationData = (state = INITIAL_STATE, action) => {
  return { ...state, office: { ...state.office, ...action.data } };
};

export const resetLocation = (state = INITIAL_STATE, action) => {
  return { ...state, office: null, step: null };
};

export const HANDLERS = {
  [Types.GET_OFFICES]: getOffices,
  [Types.GET_OFFICES_SUCCESS]: getOfficesSuccess,
  [Types.GET_OFFICES_FAILURE]: getOfficesFailure,

  [Types.GET_CHILDREN_OFFICES]: getAllOffices,
  [Types.GET_CHILDREN_OFFICES_SUCCESS]: getAllOfficesSuccess,
  [Types.GET_CHILDREN_OFFICES_FAILURE]: getAllOfficesFailure,

  [Types.GET_OFFICE]: getOffice,
  [Types.GET_OFFICE_SUCCESS]: getOfficeSuccess,
  [Types.GET_OFFICE_FAILURE]: getOfficeFailure,

  [Types.DOWNLOAD_OFFICE]: downloadOffice,
  [Types.DOWNLOAD_OFFICE_SUCCESS]: downloadOfficeSuccess,
  [Types.DOWNLOAD_OFFICE_FAILURE]: downloadOfficeFailure,

  [Types.GET_OFFICE_REVISIONS]: getOfficeRevision,
  [Types.GET_OFFICE_REVISIONS_SUCCESS]: getOfficesRevisionSuccess,
  [Types.GET_OFFICE_REVISIONS_FAILURE]: getOfficesRevisionFailure,

  [Types.APPROVE_REVISION]: approveRevision,
  [Types.APPROVE_REVISION_SUCCESS]: approveRevisionSuccess,
  [Types.APPROVE_REVISION_FAILURE]: approveRevisionFailure,

  [Types.DECLINE_REVISION]: declineRevision,
  [Types.DECLINE_REVISION_SUCCESS]: declineRevisionSuccess,
  [Types.DECLINE_REVISION_FAILURE]: declineRevisionFailure,

  [Types.UPDATE_OFFICE]: updateOffice,
  [Types.UPDATE_OFFICE_SUCCESS]: updateOfficeSuccess,
  [Types.UPDATE_OFFICE_FAILURE]: updateOfficeFailure,

  [Types.UPDATE_OFFICE_RECORDS]: updateOfficeRecords,
  [Types.UPDATE_OFFICE_RECORDS_SUCCESS]: updateOfficeRecordsSuccess,
  [Types.UPDATE_OFFICE_RECORDS_FAILURE]: updateOfficeRecordsFailure,

  [Types.ADD_VEHICLES]: addVehicles,
  [Types.ADD_VEHICLES_SUCCESS]: addVehiclesSuccess,
  [Types.ADD_VEHICLES_FAILURE]: addVehiclesFailure,

  [Types.DELETE_VEHICLE]: deleteVehicle,
  [Types.DELETE_VEHICLE_SUCCESS]: deleteVehicleSuccess,
  [Types.DELETE_VEHICLE_FAILURE]: deleteVehicleFailure,

  [Types.UPDATE_VEHICLES]: updateVehicle,
  [Types.UPDATE_VEHICLES_SUCCESS]: updateVehicleSuccess,
  [Types.UPDATE_VEHICLES_FAILURE]: updateVehicleFailure,

  [Types.ADD_PROPERTIES]: addProperties,
  [Types.ADD_PROPERTIES_SUCCESS]: addPropertiesSuccess,
  [Types.ADD_PROPERTIES_FAILURE]: addPropertiesFailure,

  [Types.UPDATE_PROPERTIES]: updateProperties,
  [Types.UPDATE_PROPERTIES_SUCCESS]: updatePropertiesSuccess,
  [Types.UPDATE_PROPERTIES_FAILURE]: updatePropertiesFailure,

  [Types.DELETE_PROPERTY]: deleteProperty,
  [Types.DELETE_PROPERTY_SUCCESS]: deletePropertySuccess,
  [Types.DELETE_PROPERTY_FAILURE]: deletePropertyFailure,

  [Types.GET_LOCATION_TYPES]: getLocationTypes,
  [Types.GET_LOCATION_TYPES_SUCCESS]: getLocationTypesSuccess,
  [Types.GET_LOCATION_TYPES_FAILURE]: getLocationTypesFailure,

  [Types.GET_VEHICLE_CONDITION]: getConditions,
  [Types.GET_VEHICLE_CONDITION_SUCCESS]: getConditionsSuccess,
  [Types.GET_VEHICLE_CONDITION_FAILURE]: getConditionsFailure,

  [Types.GET_PROPERTY_STATUSES]: getPropertyStatuses,
  [Types.GET_PROPERTY_STATUSES_SUCCESS]: getPropertyStatusesSuccess,
  [Types.GET_PROPERTY_STATUSES_FAILURE]: getPropertyStatusesFailure,

  [Types.GET_VEHICLE_STATUSES]: getVehicleStatuses,
  [Types.GET_VEHICLE_STATUSES_SUCCESS]: getVehicleStatusesSuccess,
  [Types.GET_VEHICLE_STATUSES_FAILURE]: getVehicleStatusesFailure,

  [Types.GET_ASSET_TYPES]: getAssetTypes,
  [Types.GET_ASSET_TYPES_SUCCESS]: getAssetTypesSuccess,
  [Types.GET_ASSET_TYPES_FAILURE]: getAssetTypesFailure,

  [Types.ADD_ASSETS]: addAssets,
  [Types.ADD_ASSETS_SUCCESS]: addAssetsSuccess,
  [Types.ADD_ASSETS_FAILURE]: addAssetsFailure,

  [Types.UPDATE_ASSETS]: updateAssets,
  [Types.UPDATE_ASSETS_SUCCESS]: updateAssetsSuccess,
  [Types.UPDATE_ASSETS_FAILURE]: updateAssetsFailure,

  [Types.DELETE_ASSET]: deleteAsset,
  [Types.DELETE_ASSET_SUCCESS]: deleteAssetSuccess,
  [Types.DELETE_ASSET_FAILURE]: deleteAssetFailure,

  [Types.NEXT_PAGE]: nextPage,
  [Types.PREVIOUS_PAGE]: previousPage,
  [Types.UPDATE_LOCATION_DATA]: updateLocationData,
  [Types.RESET_LOCATION_DATA]: resetLocation,
};

export default createReducer(INITIAL_STATE, HANDLERS);
