import React, { Component } from 'react';
import { Layout, Button, Row, Col, Card } from 'antd';
import "../../../styles/welcome.less";
import observe from "../../../assets/web/observe_incident.png";
import report from "../../../assets/web/report_incident.png";
import LoginComponent from "../components/LoginComponent";
import get from 'lodash/get';

export default class WelcomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginModalVisible: false
    }
  }

  showModal = () => {
    this.setState({
      loginModalVisible: true,
    });
  };

  handleCancel = e => {
    this.setState({
      loginModalVisible: false,
    });
  };

   componentDidUpdate(prevProps) {
    const { auth, history } = this.props;
    if (auth.user !== prevProps.auth.user && auth.user) {
      this.setState(previousState => (
        { loginModalVisible: false }
      ), () => {
        // history.push('/home');
      });
    }
  }

  render() {
    const {Content} = Layout;
    const {loginModalVisible} = this.state;


    return (
        <>
        <div id="landing-page">
         <div id="landing-container">
         <div className="centered">
                <h1 id="layer_text">
                  <span>Help to report</span> <br/>
                  <span> an incident</span>
                </h1>
                <h4 id="layer_text2">
                  <span>SIMSon: report and view safety & security incidents within CARE International</span>                 
                </h4>
                 <Button onClick={this.showModal}>
                  LOG IN NOW
                 </Button> 
            </div>
         </div>


         <h2 id="howTo">How to use SIMSon</h2>

        <div className="content-container">
            <Row gutter={16}>
            <Col xs={24} sm={12} md={12} id="mobile-show">
                      <img src={observe} alt="observe incident"/>
              </Col>

              <Col xs={24} sm={12} md={12}>
                    <Card title="Report Incidents" bordered={false} style={{textAlign: "left"}} id="card-text1">
                    Whenever an incident occurs that affects CARE staff, assets or operations it should be reported. The SIMSon platform allows for confidentially reporting and storing incident information.                      </Card>
              </Col>
              <Col xs={24} sm={12} md={12} id="web-show">
                      <img src={observe} alt="observe incident"/>
              </Col>
            </Row>

         <Row gutter={16}>
           <Col xs={24} sm={12} md={12}>
                  <img src={report} alt="report incident"/>
           </Col>
           <Col xs={24} sm={12} md={12}>
                      <Card title="View Incidents" bordered={false} style={{textAlign: "left"}} id="card-text2">
                      CARE International tracks safety and security incidents and information through this online and web-based system to monitor and manage risk in the areas where CARE operates. The correct staff are automatically informed when a new incident is reported to ensure a proper follow-up.
                        </Card>
           </Col>
         </Row>
        </div>
      </div>



        

           {/* login modal component  */}
           <LoginComponent 
            showModal={loginModalVisible} 
            onCancel={this.handleCancel} 
             />

            

        </>
    );
  }
}

