import { call, put, takeLeading, takeEvery } from "redux-saga/effects";
import { Creators, Types } from "../../redux/insurance-reports/actions";
import {
  getClaimStatus as getClaimStatusApi,
  getClaimTypes as getClaimtypesApi,
  createInsuranceReport as createInsuranceReportApi,
  uploadAttachment as uploadAttachmentApi,
  getInsuranceReports as getInsuranceReportsApi,
  getSingleReport as getSingleReportApi,
  updateReport as updateReportApi,
  downloadReport as downloadReportApi,
  deleteAttachment as deleteAttachmentApi,
  deleteClaimSettlement as deleteClaimApi,
  deleteOwnComment as deleteOwnCommentApi,
  deleteInsuranceReport,
  addComment as addCommentApi,
  updateClaimSettlement,
  downloadInsurenceReportXls as downloadInsurenceReportXlsApi,
} from "../../api/insurance-reports";
import { message } from "antd";
import { generateGenericFormData } from "../../../util/helpers/formdata";
import { history } from "../../../util/helpers/browserHistory";
import {
  formatAmountWithSpaces,
  downloadBlob,
  getFileNameFromResponse,
} from "../../../util/helpers/reusable-functions";

export function* fetchClaimStatus() {
  try {
    const response = yield call(getClaimStatusApi);
    const data = response.data;
    if (response.status === 200 || response.status === 201) {
      console.log(data);
      yield put(Creators.getClaimStatusSuccess(data));
    }
  } catch (e) {
    yield put(Creators.getClaimStatusFailure(e));
  }
}

export function* fetchClaimTypes() {
  try {
    const response = yield call(getClaimtypesApi);
    const data = response.data;
    if (response.status === 200 || response.status === 201) {
      console.log(data);
      yield put(Creators.getClaimTypesSuccess(data));
    }
  } catch (e) {
    yield put(Creators.getClaimTypesFailure(e));
  }
}

export function* createInsuranceReport(actions) {
  try {
    const { data } = actions;
    const response = yield call(createInsuranceReportApi, data);
    const respdata = response.data;
    if (response.status === 200 || response.status === 201) {
      console.log(respdata);
      if (data.files && data.files.length > 0) {
        //attachment data
        const upload = {
          insurance_report: respdata.id,
        };
        //convert to multipart-formdata
        const data2 = generateGenericFormData(upload);

        data.files.forEach((file) => {
          data2.append("file", file);
        });
        //call upload endpoint
        const attachment_response = yield call(
          uploadAttachmentApi,
          respdata.id,
          data2
        );

        if (
          attachment_response.status === 200 ||
          attachment_response.status === 201
        ) {
          yield put(Creators.createInsuranceReportSuccess(respdata));
          message.success("Report created successfully with attachments");
          history.push(`/reports/insurance-report/${respdata.id}`);
        }
      } else {
        yield put(Creators.createInsuranceReportSuccess(respdata));
        message.success("Report created successfully.");
        history.push(`/reports/insurance-report/${respdata.id}`);
      }
    }
  } catch (e) {
    var err = { e }.e.response;

    //this is to show errors related to incident report since it is not a field on the form
    if (err.data && err.data.incident_report) {
      message.error(err.data.incident_report);
    } else {
      message.error("Error occurred while creating insurance report.");
    }
    yield put(Creators.createInsuranceReportFailure(err && err.data));
  }
}

export function* downloadReport(actions) {
  try {
    const { data } = actions;
    const download_resp = yield call(downloadReportApi, data);

    const ddata = download_resp && download_resp.data;

    const file = new Blob([ddata], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_blank");
    yield put(Creators.downloadReportSuccess(ddata));
  } catch (e) {
    yield put(Creators.downloadreportFailure(e));
  }
}

export function* fetchInsuranceReports(actions) {
  try {
    const { page, search, status, office, from_date, end_date } = actions;
    const response = yield call(
      getInsuranceReportsApi,
      page,
      search,
      status,
      office,
      from_date,
      end_date
    );
    const data = response.data;
    if (response.status === 200 || response.status === 201) {
      yield put(Creators.getInsuranceReportsSuccess(data));
    }
  } catch (e) {
    yield put(Creators.getInsuranceReportsFailure(e));
  }
}
export function* downloadInsurenceReportXls(actions) {
  try {
    const { search, status, office, from_date, end_date } = actions;
    const response = yield call(
      downloadInsurenceReportXlsApi,
      search,
      status,
      office,
      from_date,
      end_date
    );
    const file = new Blob([response && response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    let filename = getFileNameFromResponse(response);
    filename = filename ? filename : "insurance_report.xlsx";
    downloadBlob(file, filename);
    // const fileURL = URL.createObjectURL(file);
    // window.open(fileURL, "_blank");
    yield put(
      Creators.downloadInsurenceReportXlsSuccess(response && response.data)
    );
  } catch (error) {
    let errorsFound = error.response && error.response.data;
    yield put(Creators.downloadInsurenceReportXlsFailure(errorsFound));
    message.error("Failed to download file");
  }
}
export function* fetchSingleReport(actions) {
  try {
    const { data } = actions;
    console.log(actions);
    const response = yield call(getSingleReportApi, data);
    let respdata = response.data;
    if (response.status === 200 || response.status === 201) {
      //format the amount fields to show spaces
      respdata.third_party_property_loss_value =
        respdata.third_party_property_loss_value &&
        formatAmountWithSpaces(respdata.third_party_property_loss_value);
      respdata.own_property_losses_value =
        respdata.own_property_losses_value &&
        formatAmountWithSpaces(respdata.own_property_losses_value);
      if (respdata.claim_settlements) {
        respdata.claim_settlements.map(
          (c) => (c.claim_amount = formatAmountWithSpaces(c.claim_amount))
        );
      }
      //set the status value to the id
      if (respdata.status) {
        respdata.status = respdata.status.id;
        console.log(respdata);
      }
      yield put(Creators.getSingleReportSuccess(respdata));
    }
  } catch (e) {
    yield put(Creators.getSingleReportFailure(e));
  }
}

export function* updateClaimStlment(actions) {
  try {
    const { report_id, claim_id, data } = actions;
    const response = yield call(
      updateClaimSettlement,
      report_id,
      claim_id,
      data
    );
    const respdata = response.data;
    if (response.status === 200 || response.status === 201) {
      console.log(respdata);
      yield put(Creators.updateClaimSuccess(respdata));
    }
  } catch (e) {
    yield put(Creators.updateClaimFailure(e));
  }
}

export function* updateReport(actions) {
  try {
    const { data } = actions;
    console.log(actions);

    const response = yield call(updateReportApi, data);
    const respdata = response.data;
    if (response.status === 200 || response.status === 201) {
      if (data.files && data.files.length > 0) {
        //attachment data
        const upload = {
          insurance_report: respdata.id,
        };
        //convert to multipart-formdata
        const data2 = generateGenericFormData(upload);

        data.files.forEach((file) => {
          data2.append("file", file);
        });
        //call upload endpoint
        const attachment_response = yield call(
          uploadAttachmentApi,
          respdata.id,
          data2
        );

        if (
          attachment_response.status === 200 ||
          attachment_response.status === 201
        ) {
          yield put(Creators.updateReportSuccess(respdata));
          message.success("Report updated successfully with attachments.");
          history.push(`/reports/insurance-report/${respdata.id}`);
        }
      } else {
        yield put(Creators.updateReportSuccess(respdata));
        message.success("Report updated successfully.");
        history.push(`/reports/insurance-report/${respdata.id}`);
      }
    }
  } catch (e) {
    var err = { e }.e.response;

    //this is to show errors related to incident report since it is not a field on the form
    //  if(err.data && err.data.incident_report)
    //  {
    // 	 message.error(err.data.incident_report);
    //  } else {
    // 	message.error("Error occurred while updating insurance report.");
    //  }
    yield put(Creators.updateReportFailure(err));
  }
}

export function* deleteAttachment(actions) {
  try {
    const { report_id, attachment_id } = actions;
    console.log(actions);
    const response = yield call(deleteAttachmentApi, report_id, attachment_id);
    const respdata = response.data;

    yield put(Creators.deleteAttachmentSuccess(respdata));
    yield call(fetchSingleReport, { data: report_id });
    message.success("Attachment deleted successfully.");
  } catch (e) {
    yield put(Creators.deleteAttachmentFailure(e));
    message.error("Error deleting attachment.");
  }
}

export function* deleteClaim(actions) {
  try {
    const { report_id, claim_id } = actions;

    const response = yield call(deleteClaimApi, report_id, claim_id);
    const respdata = response.data;

    yield put(Creators.deleteClaimSuccess(respdata));
    yield call(fetchSingleReport, { data: report_id });
    message.success("Claim settlement deleted successfully.");
  } catch (e) {
    yield put(Creators.deleteClaimFailure(e));
    message.error("Error deleting claim settlement.");
  }
}

export function* deleteReport(actions) {
  try {
    const { report_id } = actions;
    console.log(actions);
    const response = yield call(deleteInsuranceReport, report_id);
    const respdata = response.data;

    yield put(Creators.deleteReportSuccess(respdata));
    message.success("Report deleted successfully.");
  } catch (e) {
    yield put(Creators.deleteReportFailure(e));
    message.error("Error deleting report.");
  }
}

export function* deleteOwnComment(actions) {
  try {
    const { report_id, comment_id } = actions;

    const response = yield call(deleteOwnCommentApi, report_id, comment_id);
    const respdata = response.data;

    yield put(Creators.deleteOwnCommentSuccess(respdata));
    yield call(fetchSingleReport, { data: report_id });
    message.success("Comment deleted successfully.");
  } catch (e) {
    yield put(Creators.deleteOwnCommentFailure(e));
    message.error("Error deleting comment.");
  }
}

export function* addComments(actions) {
  try {
    const { report_id, data } = actions;

    const response = yield call(addCommentApi, report_id, data);
    const respdata = response.data;

    yield put(Creators.addCommentSuccess(respdata));
    yield call(fetchSingleReport, { data: report_id });
    message.success("Comment added successfully.");
  } catch (e) {
    yield put(Creators.addCommentFailure(e));
    message.error("Error adding comment.");
  }
}

export function* watchGetStatus() {
  yield takeEvery(Types.GET_CLAIM_STATUS, fetchClaimStatus);
}

export function* watchGetClaimTypes() {
  yield takeEvery(Types.GET_CLAIM_TYPES, fetchClaimTypes);
}

export function* watchCreateInsuranceReport() {
  yield takeLeading(Types.CREATE_INSURANCE_REPORT, createInsuranceReport);
}

export function* watchFetchInsuranceReports() {
  yield takeEvery(Types.GET_INSURANCE_REPORTS, fetchInsuranceReports);
}

export function* watchFetchSingleReport() {
  yield takeLeading(Types.GET_SINGLE_REPORT, fetchSingleReport);
}

export function* watchUpdateReport() {
  yield takeLeading(Types.UPDATE_REPORT, updateReport);
}

export function* watchDownloadReport() {
  yield takeEvery(Types.DOWNLOAD_REPORT, downloadReport);
}

export function* watchDeleteAttachment() {
  yield takeLeading(Types.DELETE_ATTACHMENT, deleteAttachment);
}

export function* watchDeleteClaim() {
  yield takeLeading(Types.DELETE_CLAIM, deleteClaim);
}

export function* watchDeleteReport() {
  yield takeLeading(Types.DELETE_REPORT, deleteReport);
}

export function* watchAddComment() {
  yield takeLeading(Types.ADD_COMMENT, addComments);
}

export function* watchDeleteOwnComment() {
  yield takeLeading(Types.DELETE_OWN_COMMENT, deleteOwnComment);
}

export function* watchUpdateClaim() {
  yield takeLeading(Types.UPDATE_CLAIM, updateClaimStlment);
}
export function* watchDownloadInsurenceReportXls() {
  yield takeEvery(
    Types.DOWNLOAD_INSURENCE_REPORT_XLS,
    downloadInsurenceReportXls
  );
}
