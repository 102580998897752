import React from "react";
import { Form, Modal } from "antd";
import { withFormik, Field } from "formik";
import { validationSchema } from "../../../util/validations";
import { InputField } from "../../../reusable-components/InputField";
import "../../../styles/modals.less";
import RequestPasswordComponent from "./RequestPassword";
import { connect } from "react-redux";
import { Creators } from "../../../services/redux/auth/actions";
import Spinner from "../../../reusable-components/Spinner";
import Notify from "../../../reusable-components/Notify";

const FormItem = Form.Item;

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requestPasswordVisible: false,
    };
  }

  showRequestModal = () => {
    // close login modal
    // this.props.onCancel();
    this.setState({
      requestPasswordVisible: true,
    });
  };

  handleCancelRequest = (e) => {
    this.setState({
      requestPasswordVisible: false,
    });
  };

  render() {
    const { handleSubmit, showModal, onCancel, auth } = this.props;
    const { requestPasswordVisible } = this.state;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
      },
    };

    return (
      <>
        <Modal
          className="modal"
          title=""
          visible={showModal}
          onCancel={onCancel}
          footer={
            <>
              <p
                key="top-text"
                className="footer-top-text"
                style={{ marginTop: "10px" }}
              >
                Forgot Password? &nbsp;
                <span
                  className="footer-lower-text"
                  onClick={this.showRequestModal}
                >
                  Request a new password
                </span>
              </p>
            </>
          }
        >
          <Form onSubmit={handleSubmit} {...formItemLayout}>
            <p className="modal-header">Log In</p>
            {/* Notify the user of any issues */}
            {auth.loginmessage && (
              <Notify
                message={auth.loginmessage}
                variant={auth.error ? "error" : "success"}
              />
            )}

            <div id="form-container">
              <FormItem>
                <Field
                  name="email"
                  placeholder="Username/Email"
                  component={InputField}
                  className="input-field"
                />
              </FormItem>
              <FormItem>
                <Field
                  name="password"
                  type="password"
                  placeholder="Password"
                  component={InputField}
                  className="input-field"
                />
              </FormItem>
              <FormItem>
                {!auth.loginloading ? (
                  <button type="submit">Login</button>
                ) : (
                  <Spinner />
                )}
              </FormItem>
            </div>
          </Form>
        </Modal>

        {/* reset password modal component  */}
        <RequestPasswordComponent
          showModal={requestPasswordVisible}
          onCancel={this.handleCancelRequest}
        />
      </>
    );
  }
}

const LoginComponent = withFormik({
  validationSchema: validationSchema,

  handleSubmit: (values, { props, setSubmitting }) => {
    props.loginUser(values.email, values.password);
  },

  displayName: "LoginComponent",
})(Login);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (email, password) => {
      dispatch(Creators.loginRequest(email, password));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
