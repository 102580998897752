import rules from "../../rbac-rules";

const check = (rules, role, action, data) => {

  let permissions = {
    static: [],
    dynamic: []
  };

  //since we have users that can have multiple roles, i did this to get the permissions of the various roles and merge them
  // into one permissions object. that way the user has all the access that all it's roles should have 

  if (Array.isArray(role)) {
    role.forEach(r => {
      const permission = rules[r];

      if (permission) { 
        if (permission.static) {
          permissions.static = [...permissions.static, ...permission.static];
        } else {
          permissions.dynamic = [...permissions.dynamic, ...permission.dynamic];
        }
      }   else {
        return false;
      }
    })
  } else {
    const permission = rules[role];
    permissions = permission;
  }
  
  const staticPermissions = [...new Set(permissions.static)];

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;       
  }

  const dynamicPermissions = [...new Set(permissions.dynamic)];

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

const Can = props => 
  check(rules, props.role, props.perform, props.data)
    ? props.yes()
    : props.no();

Can.defaultProps = {
  yes: () => null,
  no: () => null
};


export default Can;