/**
 * function to generate a formData from the recieved object
 * NOTE: This does not include files
 * @param  {Object} payload
 * @return {FormData} formData
 */
export const generateGenericFormData = (payload = {}) => {
  const formData = new FormData();
  const data = Object.keys(payload);
  data.forEach(key => {
    if (payload[key] !== "") {
      formData.append(key, payload[key]);
    }
  });

  return formData;
};
