import { createReducer } from "reduxsauce";
import Types from "./actionTypes";
import { processData } from "../../../util/helpers/reusable-functions";

export const INITIAL_STATE = {
  query: null,
  count: 0,
  nextUrl: null,
  prevUrl: null,
  isloading: false,
  isEditting: false,
  error: false,
  errors: null,
  data: null,
  incident: null,
  draft: null,
  draftCopy: null,
  nextStep: null,
  autoSaveOn: false,
  isIdRetrieved: false,
  autoCompleteFilters: null,
  isDownloading: false,
  downloadErrors: null,
  isFetchingCategories: false,
  isFetchingStatistics: false,
  statistics: null,
  categories: null,
  isApproving: false,
  isCommenting: false,
  victims: null,
  isDeletingStaff: false,
  isDeleting: false,
};

export const fetchIncidents = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isloading: true,
    error: false,
    query: action.query,
    data: null,
  };
};

export const fetchIncidentsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isloading: false,
    error: false,
    data: action.responseData,
    count: action.count,
    nextUrl: action.nextUrl,
    prevUrl: action.prevUrl,
  };
};

export const fetchIncidentsFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isloading: false,
    error: true,
    errors: action.errors,
    count: 0,
  };
};

export const fetchIncident = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: true, error: false };
};

export const fetchIncidentSuccess = (state = INITIAL_STATE, action) => {
  let data = processData(action.responseData, state);
  let draftData = { ...data };
  delete draftData.reporter_ref_string;
  return {
    ...state,
    isloading: false,
    error: false,
    incident: data,
    draft: draftData,
  };
};

export const fetchIncidentFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, error: true, errors: action.errors };
};

export const createIncident = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: true, isEditting: true, error: false };
};

export const createIncidentSuccess = (state = INITIAL_STATE, action) => {
  const data = processData(action.responseData, state);
  return {
    ...state,
    isloading: false,
    error: false,
    errors: null,
    isIdRetrieved: true,
    // incident: {...state.incident, ...state.draft, id: action.responseData.data.id, simson_reference: action.responseData.data.simson_reference}
    // incident: {...state.incident, ...state.draft, ...data, id: action.responseData.id, simson_reference: action.responseData.simson_reference}
    incident: {
      ...state.draft,
      ...data,
      id: action.responseData.id,
      simson_reference: action.responseData.simson_reference,
    },
  };
};

export const createIncidentFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, error: true, errors: action.errors };
};

export const updateIncident = (state = INITIAL_STATE, action) => {
  return { ...state, isSaving: true, isEditting: true, error: false };
};

export const updateIncidentSuccessful = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isSaving: false,
    error: false,
    errors: null,
    isIdRetrieved: false,
  };
};

export const updateIncidentFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isSaving: false, error: true, errors: action.errors };
};

export const setCoordinates = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    incident: { ...state.incident, coordinates: action.latLng },
  };
};

export const moveNext = (state = INITIAL_STATE, action) => {
  let victimsData = [];
  if (action.victimsPayload) {
    victimsData = [...action.victimsPayload];
  } else if (state.victims) {
    victimsData = [...state.victims];
  }
  return {
    ...state,
    nextStep: action.nextStep,
    incident: { ...state.incident, ...action.incidentsPayload },
    victims: victimsData,
  };
};

export const moveBack = (state = INITIAL_STATE, action) => {
  return { ...state, nextStep: action.step };
};

export const resetSteps = (state = INITIAL_STATE, action) => {
  return { ...state, nextStep: 1 };
};

export const startAutoSave = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    autoSaveOn: true,
    draft: { ...state.incident, ...action.payload },
  };
};

export const stopAutoSave = (state = INITIAL_STATE, action) => {
  return { ...state, autoSaveOn: false, draft: {} };
};

export const saveDraft = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: true };
};

export const saveDraftSuccessful = (state = INITIAL_STATE, action) => {
  const data = processData(action.responseData, state);
  let draftCopy = { ...state.draft };
  // return { ...state, draftCopy, isloading: false, incident: {...state.draft, ...data} };
  return { ...state, draftCopy, isloading: false };
};

export const saveDraftFailed = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.errors };
};

export const resetForm = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    nextStep: 1,
    isEditting: false,
    isIdRetrieved: false,
    draft: {},
    draftCopy: {},
    incident: {},
    victims: null,
  };
};

export const autoComplete = (state = INITIAL_STATE, action) => {
  return { ...state, isloadingAutoComplete: true, error: false };
};

export const autoCompleteSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isloadingAutoComplete: false,
    error: false,
    autoCompleteFilters: action.responseData,
  };
};

export const autoCompleteFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isloadingAutoComplete: false,
    error: true,
    errors: action.errors,
  };
};

export const resetAutoComplete = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isloadingAutoComplete: false,
    error: false,
    autoCompleteFilters: null,
  };
};

export const setDraftForEdit = (state = INITIAL_STATE, action) => {
  return { ...state, isEditting: true };
};

export const uploadFile = (state = INITIAL_STATE, action) => {
  return { ...state, isUploading: true, fileErrors: null };
};

export const uploadFileSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isUploading: false,
    currentUploadedFile: action.responseData,
  };
};

export const uploadFileFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isUploading: false, fileErrors: action.errors };
};

export const downloadIncidentReport = (state = INITIAL_STATE, action) => {
  return { ...state, isDownloading: true, downloadErrors: null };
};

export const downloadIncidentReportSuccess = (
  state = INITIAL_STATE,
  action
) => {
  return {
    ...state,
    isDownloading: false,
    currentUploadedFile: action.responseData,
  };
};

export const downloadIncidentReportFailure = (
  state = INITIAL_STATE,
  action
) => {
  return { ...state, isDownloading: false, downloadErrors: action.errors };
};

export const fetchCategories = (state = INITIAL_STATE, action) => {
  return { ...state, isFetchingCategories: true, errors: null };
};

export const fetchCategoriesSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingCategories: false,
    categories: action.responseData,
  };
};

export const fetchCategoriesFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isFetchingCategories: false, errors: action.errors };
};

export const approveIncident = (state = INITIAL_STATE, action) => {
  return { ...state, isApproving: true, error: false, errors: null };
};

export const approveIncidentSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, isApproving: false, incident: action.responseData };
};

export const approveIncidentFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isApproving: false, error: true, errors: action.errors };
};

export const postComment = (state = INITIAL_STATE, action) => {
  return { ...state, isCommenting: true, error: false, errors: null };
};

export const postCommentSuccess = (state = INITIAL_STATE, action) => {
  let data = processData(action.responseData, state);
  return {
    ...state,
    isCommenting: false,
    incident: {
      ...state.incident,
      notes_collection:
        action.responseData && action.responseData.notes_collection,
    },
  };
};

export const postCommentFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isCommenting: false, error: true, errors: action.errors };
};

export const downloadPdf = (state = INITIAL_STATE, action) => {
  return { ...state, isDownloading: true, downloadErrors: null };
};

export const downloadPdfSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isDownloading: false,
    currentUploadedFile: action.responseData,
  };
};

export const downloadPdfFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isDownloading: false, downloadErrors: action.errors };
};

export const saveFilters = (state = INITIAL_STATE, action) => {
  return { ...state, savedFilters: action.filters };
};

export const createStaffImpactDetails = (state = INITIAL_STATE, action) => {
  return { ...state, isUpdatingStaffImpact: true };
};

export const createStaffImpactDetailsSuccess = (
  state = INITIAL_STATE,
  action
) => {
  // return { ...state, incident: {...state.incident, victims: action.responseData}, isUpdatingStaffImpact: false };
  return {
    ...state,
    victims: action.responseData,
    isUpdatingStaffImpact: false,
  };
};

export const createStaffImpactDetailsFailure = (
  state = INITIAL_STATE,
  action
) => {
  return { ...state, errors: action.errors, isUpdatingStaffImpact: false };
};

export const updateStaffImpactDetails = (state = INITIAL_STATE, action) => {
  return { ...state, isUpdatingStaffImpact: true };
};

export const updateStaffImpactDetailsSuccess = (
  state = INITIAL_STATE,
  action
) => {
  return {
    ...state,
    victim: action.responseData,
    isUpdatingStaffImpact: false,
  };
};

export const updateStaffImpactDetailsFailure = (
  state = INITIAL_STATE,
  action
) => {
  return { ...state, errors: action.errors, isUpdatingStaffImpact: false };
};

export const deleteStaffImpactDetails = (state = INITIAL_STATE, action) => {
  return { ...state, isDeletingStaff: true };
};

export const deleteStaffImpactDetailsSuccess = (
  state = INITIAL_STATE,
  action
) => {
  return { ...state, isDeletingStaff: false };
};

export const deleteStaffImpactDetailsFailure = (
  state = INITIAL_STATE,
  action
) => {
  return { ...state, errors: action.errors, isDeletingStaff: false };
};

export const deleteIncident = (state = INITIAL_STATE, action) => {
  return { ...state, isDeleting: true };
};

export const deleteIncidentSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, isDeleting: false };
};

export const deleteIncidentFailure = (state = INITIAL_STATE, action) => {
  return { ...state, errors: action.errors, isDeleting: false };
};

export const fetchStaffImpactDetails = (state = INITIAL_STATE, action) => {
  return { ...state, isUpdatingStaffImpact: true };
};

export const fetchStaffImpactDetailsSuccess = (
  state = INITIAL_STATE,
  action
) => {
  // return { ...state, incident: {...state.incident, victims: action.responseData}, isUpdatingStaffImpact: false };
  return {
    ...state,
    victims: action.responseData,
    isUpdatingStaffImpact: false,
  };
};

export const fetchStaffImpactDetailsFailure = (
  state = INITIAL_STATE,
  action
) => {
  return { ...state, errors: action.errors, isUpdatingStaffImpact: false };
};

export const deleteIncidentComment = (state = INITIAL_STATE, action) => {
  return { ...state, isDeleting: true };
};

export const deleteIncidentCommentSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, isDeleting: false };
};

export const deleteIncidentCommentFailure = (state = INITIAL_STATE, action) => {
  return { ...state, errors: action.errors, isDeleting: false };
};

export const fetchStatistics = (state = INITIAL_STATE, action) => {
  return { ...state, isFetchingStatistics: true, errors: null };
};

export const fetchStatisticsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingStatistics: false,
    statistics: action.responseData,
  };
};

export const fetchStatisticsFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingStatistics: false,
    errors: action.errors,
  };
};
export const deleteIncidentFile = (state = INITIAL_STATE, action) => {
  return { ...state, error: false };
};

export const deleteIncidentFileSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, error: false };
};

export const deleteIncidentFileFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, errors: action.error };
};
export const HANDLERS = {
  [Types.FETCH_INCIDENTS]: fetchIncidents,
  [Types.FETCH_INCIDENTS_SUCCESS]: fetchIncidentsSuccess,
  [Types.FETCH_INCIDENTS_FAILURE]: fetchIncidentsFailure,
  [Types.FETCH_INCIDENT]: fetchIncident,
  [Types.FETCH_INCIDENT_SUCCESS]: fetchIncidentSuccess,
  [Types.FETCH_INCIDENT_FAILURE]: fetchIncidentFailure,
  [Types.CREATE_INCIDENT]: createIncident,
  [Types.CREATE_INCIDENT_SUCCESS]: createIncidentSuccess,
  [Types.CREATE_INCIDENT_FAILURE]: createIncidentFailure,
  [Types.MOVE_NEXT]: moveNext,
  [Types.MOVE_BACK]: moveBack,
  [Types.RESET_STEPS]: resetSteps,
  [Types.SET_DRAFT_FOR_EDIT]: setDraftForEdit,
  [Types.SET_COORDINATES]: setCoordinates,
  [Types.START_AUTO_SAVE]: startAutoSave,
  [Types.STOP_AUTO_SAVE]: stopAutoSave,
  [Types.UPDATE_INCIDENT]: updateIncident,
  [Types.UPDATE_INCIDENT_SUCCESSFUL]: updateIncidentSuccessful,
  [Types.UPDATE_INCIDENT_FAILURE]: updateIncidentFailure,
  [Types.SAVE_DRAFT]: saveDraft,
  [Types.SAVE_DRAFT_SUCCESSFUL]: saveDraftSuccessful,
  [Types.SAVE_DRAFT_FAILED]: saveDraftFailed,
  [Types.AUTO_COMPLETE]: autoComplete,
  [Types.AUTO_COMPLETE_SUCCESSFUL]: autoCompleteSuccess,
  [Types.AUTO_COMPLETE_FAILURE]: autoCompleteFailure,
  [Types.RESET_AUTO_COMPLETE]: resetAutoComplete,
  [Types.RESET_FORM]: resetForm,
  [Types.UPLOAD_FILE]: uploadFile,
  [Types.UPLOAD_FILE_SUCCESS]: uploadFileSuccess,
  [Types.UPLOAD_FILE_FAILURE]: uploadFileFailure,
  [Types.DOWNLOAD_INCIDENT_REPORT]: downloadIncidentReport,
  [Types.DOWNLOAD_INCIDENT_REPORT_SUCCESS]: downloadIncidentReportSuccess,
  [Types.DOWNLOAD_INCIDENT_REPORT_FAILURE]: downloadIncidentReportFailure,
  [Types.FETCH_CATEGORIES]: fetchCategories,
  [Types.FETCH_CATEGORIES_SUCCESS]: fetchCategoriesSuccess,
  [Types.FETCH_CATEGORIES_FAILURE]: fetchCategoriesFailure,
  [Types.APPROVE_INCIDENT]: approveIncident,
  [Types.APPROVE_INCIDENT_SUCCESS]: approveIncidentSuccess,
  [Types.APPROVE_INCIDENT_FAILURE]: approveIncidentFailure,
  [Types.POST_COMMENT]: postComment,
  [Types.POST_COMMENT_SUCCESS]: postCommentSuccess,
  [Types.POST_COMMENT_FAILURE]: postCommentFailure,
  [Types.DOWNLOAD_PDF]: downloadPdf,
  [Types.DOWNLOAD_PDF_SUCCESS]: downloadPdfSuccess,
  [Types.DOWNLOAD_PDF_FAILURE]: downloadPdfFailure,
  [Types.SAVE_FILTERS]: saveFilters,
  [Types.CREATE_STAFF_IMPACT_DETAILS]: createStaffImpactDetails,
  [Types.CREATE_STAFF_IMPACT_DETAILS_SUCCESS]: createStaffImpactDetailsSuccess,
  [Types.CREATE_STAFF_IMPACT_DETAILS_FAILURE]: createStaffImpactDetailsFailure,
  [Types.UPDATE_STAFF_IMPACT_DETAILS]: updateStaffImpactDetails,
  [Types.UPDATE_STAFF_IMPACT_DETAILS_SUCCESS]: updateStaffImpactDetailsSuccess,
  [Types.UPDATE_STAFF_IMPACT_DETAILS_FAILURE]: updateStaffImpactDetailsFailure,
  [Types.DELETE_STAFF_IMPACT_DETAILS]: deleteStaffImpactDetails,
  [Types.DELETE_STAFF_IMPACT_DETAILS_SUCCESS]: deleteStaffImpactDetailsSuccess,
  [Types.DELETE_STAFF_IMPACT_DETAILS_FAILURE]: deleteStaffImpactDetailsFailure,
  [Types.FETCH_STAFF_IMPACT_DETAILS]: fetchStaffImpactDetails,
  [Types.FETCH_STAFF_IMPACT_DETAILS_SUCCESS]: fetchStaffImpactDetailsSuccess,
  [Types.FETCH_STAFF_IMPACT_DETAILS_FAILURE]: fetchStaffImpactDetailsFailure,
  [Types.DELETE_INCIDENT]: deleteIncident,
  [Types.DELETE_INCIDENT_SUCCESS]: deleteIncidentSuccess,
  [Types.DELETE_INCIDENT_FAILURE]: deleteIncidentFailure,
  [Types.DELETE_INCIDENT_COMMENT]: deleteIncidentComment,
  [Types.DELETE_INCIDENT_COMMENT_SUCCESS]: deleteIncidentCommentSuccess,
  [Types.DELETE_INCIDENT_COMMENT_FAILURE]: deleteIncidentCommentFailure,
  [Types.FETCH_STATISTICS]: fetchStatistics,
  [Types.FETCH_STATISTICS_SUCCESS]: fetchStatisticsSuccess,
  [Types.FETCH_STATISTICS_FAILURE]: fetchStatisticsFailure,
  [Types.DELETE_INCIDENT_FILE]: deleteIncidentFile,
  [Types.DELETE_INCIDENT_FILE_SUCCESS]: deleteIncidentFileSuccess,
  [Types.DELETE_INCIDENT_FILE_FAILURE]: deleteIncidentFileFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
