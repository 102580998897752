import { apiRequest } from "../apiRequest";
import { 
	GET_ROLES_ENDPOINT, 
	USERS_ENDPOINT,
	RESET_NEW_ACCOUNT_ENDPOINT,
	GET_ORGANIZATION
 } from '../urls';

export const getOrganization = () => { 
	return apiRequest('GET', GET_ORGANIZATION, {});
}

export const getRoles = () => { 
	return apiRequest('GET', GET_ROLES_ENDPOINT, {});
}

export const createUsers = (data) => { 
	return apiRequest('POST', USERS_ENDPOINT, data);
}

export const getUserById = (user_id) => { 
	return apiRequest('GET', `${USERS_ENDPOINT}${user_id}/`);
}

export const updateUser = (user_id, data) => { 
	return apiRequest('PATCH', `${USERS_ENDPOINT}${user_id}/`, data);
}

export const resetNewAccount = (data) => { 
	return apiRequest('POST', RESET_NEW_ACCOUNT_ENDPOINT, data);
}
