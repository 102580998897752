export const programme_impact_options = [
	{ label: "Closure", value: "closure" },
	{ label: "Evacuation", value: "evacuation" },
	{ label: "Expulsion", value: "expulsion" },
	{ label: "Hibernation", value: "hibernation" },
	{ label: "Relocation", value: "relocation" },
	{ label: "Suspension", value: "suspension" },
	{
		label: "Temporary Alternative Work Location",
		value: "temporary alternative work location",
	},
	{
		label: "None",
		value: "none",
	},
];

export const gender_options = [
	{ label: "male", value: "male" },
	{ label: "female", value: "female" },
];

export const impact_options = [
	{ label: "Fatality", value: "fatality" },
	{ label: "Injured Physical", value: "injuries_physical" },
	{ label: "Injured Social", value: "injuries_social" },
	{ label: "Kidnapped", value: "kidnapping" },
];

export const group_options = [
	{ label: "International", value: "international" },
	{ label: "National", value: "national" },
	{ label: "Other", value: "other" },
];

export const org_affected_options = [
	{ label: "Yes", value: "yes" },
	{ label: "No", value: "no" },
	{ label: "Don't know", value: "don't know" },
	{ label: "Not applicable", value: "N/A" },
];

export const aid_workers_involved_options = [
	{ label: "Yes", value: "yes" },
	{ label: "No", value: "no" },
	{ label: "Don't know", value: "don't know" },
];

export const local_context_significant_change_options = [
	{ label: "Yes", value: "yes" },
	{ label: "No", value: "no" },
	{ label: "Don't know", value: "don't know" },
];

export const org_concequence_options = [
	{ label: "Yes", value: "yes" },
	{ label: "No", value: "no" },
	{ label: "Don't know", value: "don't know" },
	{ label: "Not applicable", value: "N/A" },
];

export const on_look_out_options = [
	{ label: "Elections", value: "elections", hintKey: "elections" },
	{ label: "Announcement", value: "announcement", hintKey: "announcement" },
	{
		label: "Demonstrations",
		value: "demonstrations",
		hintKey: "demonstrations",
	},
];

export const sexual_violence_options = [
	{
		label: "Sexual comments",
		value: "sexual comments",
		hintKey: "sexual_comments",
	},
	{
		label: "Unwanted touching",
		value: "unwanted touching",
		hintKey: "unwanted_touching",
	},
	{
		label: "Rape or attempted rape",
		value: "rape or attempted rape",
		hintKey: "rape",
	},
];

export const attack_options = [
	{
		label: "Bodily Assault",
		value: "bodily assault",
		hintKey: "bodily_assault",
	},
	{
		label: "Sexual Harassment",
		value: "sexual harassment",
		hintKey: "sexual_harassment",
	},
	{ label: "Murder", value: "murder", hintKey: "murder" },
	{ label: "Cross-fire", value: "cross-fire", hintKey: "cross_fire" },
	{
		label: "Tribal/Clan clash",
		value: "tribal/clan clash",
		hintKey: "tribal_clash",
	},
	{ label: "Sniper-fire", value: "sniper-fire", hintKey: "sniper_fire" },
	{
		label: "Complex Attack",
		value: "complex attack",
		hintKey: "complex_attack",
	},
	{ label: "Shooting", value: "shooting", hintKey: "shooting" },
	{ label: "Ambush", value: "ambush", hintKey: "ambush" },
	{ label: "AOG Attack", value: "aog attack", hintKey: "aog_attack" },
];

export const bombardment_options = [
	{ label: "Mortar", value: "mortar", hintKey: "mortar" },
	{ label: "Artillery", value: "artillery", hintKey: "artillery" },
	{ label: "RPG", value: "rpg", hintKey: "rpg" },
	{
		label: "Aerial Bombing",
		value: "aerial bombing",
		hintKey: "aerial_bombing",
	},
	{ label: "CBRN", value: "cbrn", hintKey: "cbrn" },
];

export const landmine_uxo_options = [
	{ label: "Landmine", value: "landmine", hintKey: "landmine" },
	{ label: "UXO", value: "uxo", hintKey: "uxo" },
];

export const bomb_attack_options = [
	{ label: "Hand Grenade", value: "hand grenade", hintKey: "hand_grenade" },
	{ label: "IED", value: "ied", hintKey: "ied" },
	{ label: "BBIED", value: "bbied", hintKey: "bbied" },
	{ label: "VBIED-RC", value: "vbied-rc", hintKey: "vbied_rc" },
	{
		label: "VBIED-Suicide",
		value: "vbied-suicide",
		hintKey: "vbied_suicide",
	},
];

export const theft_options = [
	{ label: "Burglary", value: "burglary", hintKey: "burglary" },
	{ label: "Car-jacking", value: "car-jacking", hintKey: "car_jacking" },
	{ label: "Fraud", value: "fraud", hintKey: "fraud" },
	{ label: "Robbery", value: "robbery", hintKey: "robbery" },
];

export const abduction_hostage_options = [
	{ label: "Abduction", value: "abduction", hintKey: "abduction" },
	{
		label: "Hostage Situation",
		value: "hostage situation",
		hintKey: "hostage",
	},
];

export const detention_arrest_options = [
	{ label: "Detention", value: "detention", hintKey: "detention" },
	{ label: "Arrest", value: "arrest", hintKey: "arrest" },
];

export const threats_options = [
	{
		label: "Observed surveillance",
		value: "observed surveillance",
		hintKey: "observed_surveillance",
	},
	{ label: "Harassment", value: "harassment", hintKey: "harassment" },
	{ label: "Death Threat", value: "death threat", hintKey: "death_threat" },
	{ label: "Bribery", value: "bribery", hintKey: "bribery" },
	{
		label: "Blackmail/Extortion",
		value: "blackmail/extortion",
		hintKey: "blackmail",
	},
	{
		label: "Conflict escalation",
		value: "conflict escalation",
		hintKey: "conflict",
	},
];

export const sabotage_options = [
	{ label: "Arson", value: "arson", hintKey: "arson" },
	{ label: "Sabotage", value: "sabotage", hintKey: "sabotage" },
];

export const civil_unrest_options = [
	{
		label: "Demonstration",
		value: "demonstration",
		hintKey: "demonstration",
	},
	{ label: "Rioting", value: "rioting", hintKey: "rioting" },
	{ label: "Looting", value: "looting", hintKey: "looting" },
];

export const traffic_accidents_options = [
	{
		label: "Boat Accident",
		value: "boat accident",
		hintKey: "boat_accident",
	},
	{
		label: "Motorbike Accident",
		value: "motorbike accident",
		hintKey: "motorbike_accident",
	},
	{
		label: "Vehicle Accident",
		value: "vehicle accident",
		hintKey: "vehicle_accident",
	},
	{
		label: "Aircraft Accident",
		value: "aircraft accident",
		hintKey: "aircraft_accident",
	},
	{ label: "Road Block", value: "road block", hintKey: "road_block" },
];

export const natural_disasters_options = [
	{ label: "Flood", value: "flood", hintKey: "flood" },
	{ label: "Avalanche", value: "avalanche", hintKey: "avalanche" },
	{ label: "Landslide", value: "landslide", hintKey: "landslide" },
	{ label: "Earthquake", value: "earthquake", hintKey: "earthquake" },
	{ label: "Tsunami", value: "tsunami", hintKey: "tsunami" },
	{
		label: "Cyclone/Typhoon/Hurricane",
		value: "cyclone/typhoon/hurricane",
		hintKey: "cyclone",
	},
	{
		label: "Volcanic Eruption",
		value: "volcanic eruption",
		hintKey: "volcanic_eruption",
	},
];

export const fire_workplace_incident_options = [
	{
		label: "Occupational Accident",
		value: "occupational accident",
		hintKey: "occupational_accident",
	},
	{
		label: "Property Damage",
		value: "property damage",
		hintKey: "property_damage",
	},
];

export const health_options = [
	{ label: "Illness/Disease", value: "illness/disease", hintKey: "illness" },
];

export const information_security_incident_options = [
	{
		label: "Information Breach",
		value: "information breach",
		hintKey: "information_breach",
	},
	{ label: "Disruption", value: "disruption", hintKey: "disruption" },
	{
		label: "Data corruption",
		value: "data corruption",
		hintKey: "data_corruption",
	},
];

export const radio_field_names = [
	{ key: "be_on_the_look_out", value: "be on the look out" },
	{ key: "sexual_violence", value: "sexual violence" },
	{ key: "attack", value: "attack" },
	{ key: "bombardment", value: "bombardment" },
	{ key: "landmine_uxo", value: "landmine/uxo" },
	{ key: "bomb_attack", value: "bomb attack" },
	{ key: "theft", value: "theft" },
	{ key: "abduction_hostage", value: "abduction/hostage" },
	{ key: "detention_arrest", value: "detention/arrest" },
	{ key: "threats", value: "threats" },
	{ key: "sabotage", value: "sabotage" },
	{ key: "civil_unrest", value: "civil unrest" },
	{ key: "traffic_accidents", value: "traffic accidents" },
	{ key: "natural_disasters", value: "natural disasters" },
	{ key: "fire_workplace_incident", value: "fire/workplace incident" },
	{ key: "health", value: "health" },
	{
		key: "information_security_incident",
		value: "information security incident",
	},
];

export const category_filter_options = {
	be_on_the_look_out: on_look_out_options,
	sexual_violence: sexual_violence_options,
	attack: attack_options,
	bombardment: bombardment_options,
	landmine_uxo: landmine_uxo_options,
	bomb_attack: bomb_attack_options,
	theft: theft_options,
	abduction_hostage: abduction_hostage_options,
	detention_arrest: detention_arrest_options,
	threats: threats_options,
	sabotage: sabotage_options,
	civil_unrest: civil_unrest_options,
	traffic_accidents: traffic_accidents_options,
	natural_disasters: natural_disasters_options,
	fire_workplace_incident: fire_workplace_incident_options,
	health: health_options,
	information_security_incident: information_security_incident_options,
};

export const hints = {
	be_on_the_look_out: {
		header: "Be on the look out!",
		message: "Be on the look out!",
	},
	elections: {
		header: "Elections",
		message: "Elections",
	},
	announcement: {
		header: "Announcement",
		message: "Announcement",
	},
	demonstrations: {
		header: "Demonstrations",
		message: "Demonstrations",
	},
	sexual_violence: {
		header: "Sexual Violence",
		message:
			"Any sexual act, attempt to obtain a sexual act, unwanted sexual comments or advances, or acts to traffic, or otherwise directed, against a person’s sexuality using coercion, by any person regardless of their relationship to the victim, in any setting, including but not limited to home and work.",
	},
	sexual_comments: {
		header: "Sexual comments",
		message:
			"Unwanted comments of a sexual nature, including requests, advances, jokes and innuendos.",
	},
	unwanted_touching: {
		header: "Unwanted touching",
		message:
			"Unwanted and/or forced touching of a sexual nature, including groping, grazing, attempted or actual assault without penetration.",
	},
	rape: {
		header: "Rape or attempted rape",
		message:
			"Penetration or attempted penetration of one person’s bodily orifice(s) with another person’s body part or object, without consent.",
	},
	attack: {
		header: "Attack",
		message:
			"Attack on individual people or groups of people with intent to cause damage (not to rob) to person or a specific group.",
	},
	bodily_assault: {
		header: "Bodily Assault",
		message:
			"Intended use of unlawful physical force to another person without his/her consent.",
	},
	sexual_harassment: {
		header: "Sexual Harassment",
		message:
			"Any unwanted sexual contact that is forced on another person through violence, occurs as result of threats to harm them or another, intimidation or coercion, or during circumstances when a person is incapable of giving consent, such as intoxication; this includes rape, attempted rape, or indecent assault (unwanted touching, fondling, or groping of sexual body parts).",
	},
	murder: {
		header: "Murder",
		message:
			"Murder is the unlawful killing, with malice forethought, of another human.",
	},
	cross_fire: {
		header: "Cross-fire",
		message:
			"Where individuals, who are not the target, get caught up in exchange of fire between to opposing armed actors. For example, agency staff get caught in the middle of shooting between different factions forcing them to flee or take cover",
	},
	tribal_clash: {
		header: "Tribal/Clan Clash",
		message:
			"Short-term fighting between different social groups (ethnicity, religion, clan, tribe or family). For example, opposing communities may clash over resources (cattle, water sources) or in retaliation to actions taken by members of the other community.",
	},
	sniper_fire: {
		header: "Sniper-fire",
		message:
			"One or a number of shots deliberately fired at others from a concealed place.",
	},
	complex_attack: {
		header: "Complex Attack",
		message: "Attack with a combination of explosives and small arms.",
	},
	shooting: {
		header: "Shooting",
		message:
			"The act of firing a gun. Also includes: accidents with weapons (drunk soldiers etc), shots fired in the air.",
	},
	ambush: {
		header: "Ambush",
		message:
			"A sudden attack against a person or vehicle made from a concealed position. Target is moving (vehicles/trucks) and guns are used.",
	},
	aog_attack: {
		header: "AOG Attack",
		message:
			"An attack by an Armed Opposition Group (AOG) using weapons and/or explosives. The attack by an AOG is often directed against forces of the official international recognized government but can also be directed against another AOG.",
	},
	bombardment: {
		header: "Bombardment",
		message: "Use of shells/bombs, see subcategory for details.",
	},
	mortar: {
		header: "Mortar",
		message:
			"Indirect fire weapon that fires explosive projectiles known as (mortar) bombs at low speed, short ranges and high-arcing ballistic trajectories.",
	},
	artillery: {
		header: "Artillery",
		message:
			"The use of heavy artillery fire to destroy an area rather than hit a specific target, usually fired over long distances.",
	},
	rpg: {
		header: "RPG",
		message:
			"Shoulder-fired, anti-tank weapon system that fires rockets equipped with an explosive warhead.",
	},
	aerial_bombing: {
		header: "Aerial Bombing",
		message:
			"An attack or bombardment by air on towns, villages, buildings, specific sites or targets. For example air attacks on distribution sites.",
	},
	cbrn: {
		header: "CBRN",
		message:
			"Deliberate release of chemical, biological, radiological or nuclear material, for example through an explosive device such as a ‘dirty bomb’.",
	},
	landmine_uxo: {
		header: "Landmine/UXO",
		message: "Location or use of landmine/UXOs.",
	},
	landmine: {
		header: "Landmine",
		message:
			"Type of explosive device which is placed onto or into the ground and explodes when triggered by a vehicle or person.",
	},
	uxo: {
		header: "UXO",
		message:
			"UneXploded Ordnance, are explosive weapons (bombs, shells, grenades, land mines, naval mines, etc.) that did not explode when they were employed and still pose a risk of detonation, potentially many decades after they were used or discarded.",
	},
	bomb_attack: {
		header: "Bomb Attack",
		message:
			"Location or use of Improvised Explosive Devices (for example road side bombs) / Chemical-Biological or nuclear agent.",
	},
	hand_grenade: {
		header: "Hand Grenade",
		message: "A hand grenade is any small bomb that can be thrown by hand.",
	},
	ied: {
		header: "IED",
		message:
			"Improvised explosive device which can be placed in more or less anything, for example in a bicycle, bag, toy, clothing, letter or parcel.",
	},
	bbied: {
		header: "BBIED",
		message:
			"Body-Borne Improvised Explosive Device. Individual wearing explosives and detonating them in order to kill others including themselves.",
	},
	vbied_rc: {
		header: "VBIED-RC",
		message:
			"A car bomb, or truck bomb, also known as a Vehicle Borne Improvised Explosive Device (VBIED), is an improvised explosive device placed inside a car or other vehicle and then detonated. (RC=Remote Control Detonation)",
	},
	vbied_suicide: {
		header: "VBIED-Suicide",
		message:
			"A car bomb, or truck bomb, also known as a Vehicle Borne Improvised Explosive Device (VBIED), is an improvised explosive device placed inside a car or other vehicle and then detonated with the perpetrator still inside.",
	},
	theft: {
		header: "Theft",
		message:
			"Taking and appropriating property without the consent of its owner or any lawful authority.",
	},
	burglary: {
		header: "Burglary",
		message:
			"Illegal entry into a building (house, office, warehouse) for the purposes of stealing property.",
	},
	car_jacking: {
		header: "Car-jacking",
		message:
			"Theft by force or intimidation of (land or non-land) vehicle that has a driver and/or passenger(s) present.",
	},
	fraud: {
		header: "Fraud",
		message:
			"Intentional deception made for personal gain or to damage another individual/organisation.",
	},
	robbery: {
		header: "Robbery",
		message:
			"The unlawfully taking the property by the use of violence or intimidation. Includes robbery of moving targets (vehicles/trucks) with gun presence but no usage of gun (no shots fired).",
	},
	abduction_hostage: {
		header: "Abduction/Hostage",
		message:
			"A person or group is forcibly taken against their will, see subcategories for details.",
	},
	abduction: {
		header: "Abduction",
		message:
			"Where a person or group is forcibly taken against their will. Abductors/kidnappers intend to cause harm or force the abductees to do something for their benefit.",
	},
	hostage: {
		header: "Hostage Situation",
		message:
			"Where a person or group is held with their safety and subsequent release dependent on the fulfilment of certain conditions. These conditions may include: propagation of a political cause; exchange of hostages for political prisoners; evasion of criminal prosecution when their activity has been discovered by the authorities.",
	},
	detention_arrest: {
		header: "Detention/Arrest",
		message:
			"A person or group is held against their will by an individual or group (eg, villagers, local authority or military) or law enforcement, see subcategories for details.",
	},
	detention: {
		header: "Detention",
		message:
			"A person or group is held against their will by an individual or group (eg, villagers, local authority or military). While there is no intention to cause harm, there is also no clear pre-condition for their release. Reasons for detention can range from discontent with an agency or its programmes, to concerns for the 'security' of the detainees.",
	},
	arrest: {
		header: "Arrest",
		message:
			"The confinement of a person whether or not by physical force by someone acting under authority (e.g. police) in connection with a supposed crime and where the person is not free to leave.",
	},
	threats: {
		header: "Threats",
		message:
			"any verbal or physical conduct directed at a person or group with the intent of causing distress, this can also include death threats.",
	},
	observed_surveillance: {
		header: "Observed surveillance",
		message:
			"Monitoring of the behavior, activities, or other changing information of an organisation, location or persons, usually for the purpose of influencing or hurting them.",
	},
	harassment: {
		header: "Harassment",
		message:
			"Any verbal or physical conduct, directed at a person with the intent of causing distress, for example based on a person’s race, religion, nationality or merely to interfere with that persons work.",
	},
	death_threat: {
		header: "Death Threat",
		message:
			"Any verbal or physical threat made to either kill or psychically harm a person. For example threats can be made by individuals or groups, may be politically, ethnically, religiously, personally motivated and could be targeted against and individual staff member, an organisation or he humanitarian community as a whole.",
	},
	bribery: {
		header: "Bribery",
		message:
			"An act of offering, giving or receiving something (usually money) in exchange for favours or influence.",
	},
	blackmail: {
		header: "Blackmail/Extortion",
		message: "Demanding money, goods or services from a person by threat",
	},
	conflict: {
		header: "Conflict escalation",
		message:
			"The escalation of a conflict between two or more parties causing an increase in the security risks in an area. The escalation may be caused by political developments or the result of military activity. The conflict take place between states or within a state.",
	},
	sabotage: {
		header: "Sabotage",
		message:
			"The deliberate destruction of property or assets. Includes: Burning of houses/villages/property on purpose by all actors.",
	},
	arson: {
		header: "Arson",
		message: "Deliberately destroying or damaging property, by fire.",
	},
	organisation_sabotage: {
		header: "Sabotage",
		message:
			"The deliberate destruction of property or assets with the intention of damaging the agency’s programme.",
	},
	civil_unrest: {
		header: "Civil Unrest",
		message:
			"A public display of group opinion or protest, see subcategories for details.",
	},
	demonstration: {
		header: "Demonstration",
		message:
			"A public display of group opinion or protest, usually peaceful in nature but tensions may escalate and turn violent.",
	},
	rioting: {
		header: "Rioting",
		message:
			"Civil disorder involving a group of people intent on committing damage to property or acts of violence.",
	},
	looting: {
		header: "Looting",
		message:
			"Large scale theft or plunder, usually by violence, that takes advantage of certain conditions such as war, natural disaster or civil disorder.",
	},
	traffic_accidents: {
		header: "Traffic Incidents",
		message:
			"Unintentional damage, injury or death involving accidents during transportation - cars, airplanes, motorbikes, trucks, buses, boats etc.",
	},
	boat_accident: {
		header: "Boat Accident",
		message:
			"Accident resulting in unintentional damage, injury or death involving a boat.",
	},
	motorbike_accident: {
		header: "Motorbike Accident",
		message:
			"Accident resulting in unintentional damage, injury or death involving a motorbike.",
	},
	vehicle_accident: {
		header: "Vehicle Accident",
		message:
			"Accident resulting in unintentional damage, injury or death involving (non flying) vehicles - cars, trucks, buses, etc.",
	},
	aircraft_accident: {
		header: "Aircraft Accident",
		message:
			"Accident resulting in unintentional damage, injury or death involving aircraft (planes, helicopters etc) as a result of a technical or pilot error.",
	},
	road_block: {
		header: "Road Block",
		message:
			"A barricade or construction across a road forcing a vehicle to stop, often set up by military, police, rebel groups.",
	},
	natural_disasters: {
		header: "Natural Disasters",
		message:
			"Accident from weather conditions resulting in significant damage to property, vehicles or resources, injury or death. See subcategories for details.",
	},
	flood: {
		header: "Flood",
		message:
			"Substantial rise and overflow of water that results in significant damage to property, vehicles or resources, injury or death.",
	},
	avalanche: {
		header: "Avalanche",
		message:
			"A fall or slide of a large mass of snow and/or ice that results in significant damage to property, vehicles or resources, injury or death.",
	},
	landslide: {
		header: "Landslide",
		message:
			"A substantial ground movement, such as rock fall, mudflows, which results in significant damage to property, vehicles or resources, injury or death.",
	},
	earthquake: {
		header: "Earthquake",
		message:
			"A trembling or shaking movement of the earth’s surface, including slight tremors, violent shocks or aftershocks that results in significant damage to property, vehicles or resources, injury or death.",
	},
	tsunami: {
		header: "Tsunami",
		message:
			"A series of large ocean waves, usually caused by undersea earthquake, volcanic eruption, or landslide, which on encountering land results in significant damage to property, vehicles or resources, injury or death.",
	},
	cyclone: {
		header: "Cyclone/Typhoon/Hurricane",
		message:
			"A violent rotating storm resulting in significant damage to property, vehicles, or resources, or injury or death.",
	},
	volcanic_eruption: {
		header: "Volcanic Eruption",
		message:
			"Violent discharge of molten lava, ash and gases from an active volcano which results in significant damage to property, vehicles or resources, injury or death.",
	},
	fire_workplace_incident: {
		header: "Fire/Workplace Incident",
		message:
			"An unplanned or uncontrolled work related incident/uncontrolled fire at workplace. See subcategories for details.",
	},
	occupational_accident: {
		header: "Occupational Accident",
		message:
			"An unexpected and unplanned occurrence, arising out of or in connection with work which results in one or more persons incurring a personal injury, disease or death.",
	},
	property_damage: {
		header: "Property Damage",
		message:
			"An unplanned or uncontrolled incident that resulted in, or could have resulted in significant damage to property, vehicles or resources. The damage can be caused by people by accident or by negligence, or can be caused by natural phenomena.",
	},
	fire: {
		header: "Fire",
		message:
			"Uncontrolled fire that results in significant damage to property, vehicles, or resources, or injury or death.",
	},
	health: {
		header: "Health",
		message:
			"Significant poor health as a result of illness or disease resulting hospitalisation or medical evacuation from the mission, or death.",
	},
	illness: {
		header: "Illness/Disease",
		message:
			"Significant poor health as a result of illness or disease resulting in hospitalization or medical evacuation from the mission, or death.",
	},
	information_security_incident: {
		header: "Information Security Incident",
		message:
			"An actual or suspected incident where information, networks, computers, data storage media or other digital devices are temporarily or definitively intruded, rendered inaccessible, damaged or altered.",
	},
	information_breach: {
		header: "Information Breach",
		message:
			"Any incident where a person or computer gained access to confidential information or computer devices without prior authorization. This includes the loss of confidential documents, intrusion into computers or networks, the use of spyware software, eavesdropping on communications, social engineering or any other incident where confidential information was leaked.",
	},
	disruption: {
		header: "Disruption",
		message:
			"The intentional disruption of computers, computer services, networks, or other digital devices. This may include the use of digital attacks such as malware, Denial of Service attacks and hacking or by use of other means such as cutting cables, damaging computer devices or jamming wireless signals.",
	},
	data_corruption: {
		header: "Data corruption",
		message:
			"The deliberate alteration or corruption of data with malicious intent. The goal is not to disrupt but to spread misinformation and lowering the trust in data or computers.",
	},
};


const timesList = () => {
	const times = genTimesList()
	return times.map(time => ({ label: time, value: time }))
}
const genTimesList = () => {
	const hours = [...Array(24).keys()]
	const minutes = [...Array(60).keys()]
	let times = []
	for(let hour of  hours){
		for(let min of minutes){
			times = [...times, `${hour.toString().padStart(2, "0")}:${min.toString().padStart(2, "0")}:00`]
		}
	}
	return times

}

const times = timesList()

export const time_options = [
	// { label: "Morning 9.00", value: "2017-02-16T09:00:00Z" },
	// { label: "Afternoon 15.00", value: "2017-02-16T15:00:00Z" },
	// { label: "Evening 21.00", value: "2017-02-16T21:00:00Z" },
	// { label: "Night 3.00", value: "2017-02-16T03:00:00Z" },
	// { label: "Unknown 12.00(noon)", value: "2017-02-16T12:00:00Z" },
	{ label: "Morning 9.00", value: "09:00:00" },
	{ label: "Afternoon 15.00", value: "15:00:00" },
	{ label: "Evening 21.00", value: "21:00:00" },
	{ label: "Night 3.00", value: "03:00:00" },
	...times
];
