const rules = {
    insurance_user: {
      static: [
          "insurance:list", 
          "insurance:manage",
          "insurance:view",
          "insurance:edit",
          "home:view",
          "incident:list",
          "incident:view",
          "incident:addupdate",
          "incident:unpublish",
          "user:manage",
          "location:list",
          "organization:view",
          "location:manage",
          "location:revision",
          "insurance:export"
        ]
    },
    regular_user: {
          static: [
            "home:view",
            "incident:list",
            "incident:view",
            "incident:addupdate",
            // "user:manage",
            "organization:view",
          ]
    },
    manager: {
      static: [
        "home:view",
        "incident:list",
        "incident:view",
        "incident:addupdate",
        "incident:unpublish",
        "user:manage",
        "organization:view",
        "assign:approver"
      ]
    },
    senior: {
      static: [
        "home:view",
        "incident:list",
        "incident:view",
        "incident:addupdate",
        "incident:unpublish",
        "user:manage",
        "organization:view"
      ]
    },
    office_data_editor: {
      static: [
        "home:view",
        "incident:list",
        "incident:view",
        "incident:addupdate",
        "incident:unpublish",
        "user:manage",
        "location:list",
        "organization:view",
        "location:manage"  //manage is superior to view for locations. if a user has the right to manage, he can view and edit
      ]
    },
    insurance_only: {
      static: [
        // "home:view",
        // "incident:list",
        // "incident:view",
        // "incident:addupdate",
        // "incident:unpublish",
        // "user:manage",
        "location:list",
        "organization:view",
        "location:manage"  //manage is superior to view for locations. if a user has the right to manage, he can view and edit
      ]
    },
    approver: {
      static: [
        "home:view",
        "incident:list",
        "incident:view",
        "incident:addupdate",
        "incident:unpublish",
        "incident:approve",
        "user:manage",
        "organization:view"
      ]
    },
    approver: {
      static: [
        "home:view",
        "incident:list",
        "incident:view",
        "incident:addupdate",
        "incident:unpublish",
        "incident:approve",
        "user:manage",
        "organization:view"
      ]
    } 
}

export default rules;
