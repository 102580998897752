import React, {lazy} from 'react';
import "antd/dist/antd.less";
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import {  getUserRole } from './services/api/axiosDefaults';
import Layout from "./containers/Layout";
import { history } from './util/helpers/browserHistory';
import Can from './util/helpers/Can';
import { messageConfig } from './util/configs';
import { notificationConfig } from './util/configs';
import checkAuth from "./util/helpers/checkAuth";
import { Creators } from './services/redux/incidents/actions';
import store from './store';
import AuthContainer from  "./containers/Auth";

//function to retry lazy imports up to x times if it fails, before it errors out
const retry = (fn, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};


// code splitting the routes to make the app bundle smaller and make the app load faster

const HomePromise = import("./containers/Home");
const Home = lazy(() => retry(() => HomePromise));

const NotfoundPromise = import("./containers/NotFound");
const Notfound = lazy(() => retry(() => NotfoundPromise));

const ChangePasswordPromise = import("./containers/Auth/components/ChangePassword");
const ChangePassword = lazy(() => retry(() => ChangePasswordPromise));

const LocationPromise = import("./containers/Location/list-locations");
const Location = lazy(() => retry(() => LocationPromise));

const UpdateLocationPromise = import("./containers/Location/edit-location");
const UpdateLocation = lazy(() => retry(() => UpdateLocationPromise));

const OfficeRevisionPromise = import("./containers/Location/office-revision");
const OfficeRevision = lazy(() => retry(() => OfficeRevisionPromise));

const OrganizationPromise = import("./containers/Organization/organization");
const Organization = lazy(() => retry(() => OrganizationPromise));

const NewUserAccountPromise = import("./containers/Organization/user-account");
const NewUserAccount = lazy(() => retry(() => NewUserAccountPromise));

const ManageInsuranceReportPromise = import("./containers/InsuranceReport/manage-insurance-report");
const ManageInsuranceReport = lazy(() => retry(() => ManageInsuranceReportPromise));

const ListInsuranceReportsPromise = import("./containers/InsuranceReport/list-insurance-reports");
const ListInsuranceReports = lazy(() => retry(() => ListInsuranceReportsPromise));

const SingleInsuranceReportPromise = import("./containers/InsuranceReport/view-insurance-reports");
const SingleInsuranceReport = lazy(() => retry(() => SingleInsuranceReportPromise));

const AddUpdateIncidentPromise = import("./containers/Incidents/add-update-incident");
const AddUpdateIncident = lazy(() => retry(() => AddUpdateIncidentPromise));

const ListIncidentsPromise = import("./containers/Incidents/list-incidents");
const ListIncidents = lazy(() => retry(() => ListIncidentsPromise));

const ViewIncidentPromise = import("./containers/Incidents/view-incident");
const ViewIncident = lazy(() => retry(() => ViewIncidentPromise));

const ApproveIncidentPromise = import("./containers/Incidents/approve-incident");
const ApproveIncident = lazy(() => retry(() => ApproveIncidentPromise));

const EmailRedirectPromise = import("./containers/Incidents/email-redirects");
const EmailRedirectComponent = lazy(() => retry(() => EmailRedirectPromise));

const UpdateDraftPromise = import("./containers/Drafts/update-draft");
const UpdateDraft = lazy(() => retry(() => UpdateDraftPromise));

const ListDraftsPromise = import("./containers/Drafts/list-drafts");
const ListDrafts = lazy(() => retry(() => ListDraftsPromise));

const ViewDraftPromise = import("./containers/Drafts/view-draft");
const ViewDraft = lazy(() => retry(() => ViewDraftPromise));


history.listen((location) => {
  const path = location && location.pathname
  const state = store.getState();
  if (path !== '/update/incident' && path !== '/add/incident' && path !== '/update/draft') {
    // console.log("isEditting on history listen ", state)
    const { isEditting } = state.incidents;
    if (!isEditting || (isEditting && path !== '/view/incident' && path !== '/view/draft')) {
      store.dispatch(Creators.stopAutoSave());
      store.dispatch(Creators.resetForm());
    }
  }
});

const role = getUserRole();
// console.log(role)
const AuthenticatedRoute = ({ component: Component, perform,  ...rest }) => (
  <Route
    {...rest}
    render={props =>
      checkAuth() ? (
          <Can
          role={role}
          perform={perform}
          yes={() => (
              <Component {...props} />
          )}
          no={() =>  <Redirect to={{ pathname: "/home" }} />}
        />
      
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    } />
);

export default class App extends React.Component {
    render() {
      messageConfig();
      notificationConfig();
     
      return (
          <Router history={history}>
            <Layout>
              <Switch>
                <Route path="/" exact component={AuthContainer} />
                <Route path="/change-password" exact component={ChangePassword} />
                <AuthenticatedRoute exact path="/home" component={Home} perform="home:view"/>
                <AuthenticatedRoute path="/locations" component={Location} perform="location:list"/>
                <AuthenticatedRoute path="/revisions" component={OfficeRevision} perform="location:revision"/>
                <AuthenticatedRoute path="/location/:id" component={UpdateLocation} perform="location:manage"/>
                <AuthenticatedRoute path="/organizations" component={Organization} perform="organization:view"/>
                <AuthenticatedRoute path={["/add-user", "/user/:id"]} component={NewUserAccount} perform="user:manage"/>
                <AuthenticatedRoute path={["/reports/create-insurance-report", "/reports/update-insurance-report"]} component={ManageInsuranceReport} perform="insurance:manage"/>
                <AuthenticatedRoute path="/reports/insurance-reports" component={ListInsuranceReports}  perform="insurance:list"/>
                <AuthenticatedRoute path="/reports/insurance-report/:id" component={SingleInsuranceReport} perform="insurance:view"/>
                <AuthenticatedRoute path={["/add/incident", "/update/incident"]} component={AddUpdateIncident} perform="incident:addupdate"/>
                <AuthenticatedRoute exact path={["/incidents", "/incidents/pending-approval"]} component={ListIncidents} perform="incident:list"/>
                <AuthenticatedRoute path="/view/incident" component={ViewIncident} perform="incident:view"/>
                <AuthenticatedRoute path="/approve/incident" component={ApproveIncident} perform="incident:approve"/>
                {/* email redirects */}
                <AuthenticatedRoute path={["/incident/:id", "/incidents/:id/approve/"]} component={EmailRedirectComponent} perform="incident:view"/>
                {/* permission to be added for drafts only owner can see their drafts */}
                <AuthenticatedRoute path="/update/draft" component={UpdateDraft} perform="incident:addupdate"/>
                <AuthenticatedRoute path="/drafts" component={ListDrafts} perform="incident:list"/>
                <AuthenticatedRoute path="/view/draft" component={ViewDraft} perform="incident:view"/>
                {/* permission to be added for drafts only owner can see their drafts */}
                <Route component={Notfound} />
              </Switch>
            </Layout>
          </Router>
      );
  }
}
