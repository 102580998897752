import apiInstance, { axiosInstance, axiosInstanceFormData, axiosInstanceOthers } from './axiosDefaults';

export const apiRequest = (method, url, data={}) => {
	const response = axiosInstance()({
	  method,
	  url,
	  data,
	  withCredentials: false,
	});
	console.log("apiRequest ", response)
	return response;
}

export const apiRequestOthers = (method, url, data={}, headerType, responseType) => {
	const response = axiosInstanceOthers(headerType, responseType)({
	  method,
	  url,
	  data,
	  withCredentials: false,
	});
	console.log("apiRequest ", response)
	return response;
}

export const api = (method, url, data={}) => {
	const response = apiInstance({
		method,
		url,
		data
	})

	return response;
}