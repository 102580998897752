import { combineReducers } from 'redux';
import auth from './services/redux/auth/reducers';
import incidents from './services/redux/incidents/reducers';
import organization from './services/redux/organization/reducers';
import insurance from './services/redux/insurance-reports/reducers'
import pagination from './services/redux/pagination/reducers'
import location from './services/redux/locations/reducers'

const rootReducer = combineReducers({
  auth,
  incidents,
  organization,
  insurance,
  pagination,
  location
});

export default rootReducer;