import { all, fork } from "redux-saga/effects";
import {
  watchLogin,
  watchResetPassword,
  watchLogout,
  watchChangePassword,
  watchGetHowToFile,
} from "./auth";
import {
  watchGetRoles,
  watchCreateUser,
  watchResetAccount,
  watchGetOrganization,
  watchGetUserDetails,
  watchUpdateUser,
} from "./organization";
import {
  watchFetchIncidents,
  watchFetchIncident,
  watchFetchCategories,
  watchCreateIncident,
  watchUpdateIncident,
  watchSaveDraftIncident,
  watchStartAutoSave,
  watchAutoComplete,
  watchUploadFile,
  watchDownloadIncidentReport,
  watchApproveIncident,
  watchPostComment,
  watchDownloadPdf,
  watchFetchStaffImpactDetails,
  watchCreateStaffImpactDetails,
  watchUpdateStaffImpactDetails,
  watchDeleteStaffImpactDetails,
  watchDeleteIncidentComment,
  watchDeleteIncident,
  watchFetchStatistics,
  watchDeleteIncidentFile,
} from "./incidents";
import {
  watchCreateInsuranceReport,
  watchGetClaimTypes,
  watchGetStatus,
  watchFetchInsuranceReports,
  watchFetchSingleReport,
  watchUpdateReport,
  watchDownloadReport,
  watchDeleteAttachment,
  watchDeleteClaim,
  watchDeleteOwnComment,
  watchDeleteReport,
  watchAddComment,
  watchUpdateClaim,
  watchDownloadInsurenceReportXls,
} from "./insurance-reports";
import {
  watchGetOffices,
  watchLocationTypes,
  watchPropertyStatuses,
  watchGetOffice,
  watchVehicleStatuses,
  watchUpdateOffice,
  watchAddProperties,
  watchAddVehicles,
  watchDeleteProperties,
  watchDeleteVehicles,
  watchUpdateProperties,
  watchUpdateVehicle,
  watchVehicleConditions,
  watchGetChildrenOffices,
  watchFetchOfficeRevisions,
  watchApproveRevision,
  watchDeclineRevision,
  watchDownloadOffice,
  watchUpdateRecords,
  watchGetAssetTypes,
  watchAddAssets,
  watchUpdateAssets,
  watchDeleteAsset,
} from "./locations";

export default function* rootSaga() {
  yield all([
    watchLogin(),
    watchResetPassword(),
    watchChangePassword(),
    watchLogout(),
    watchFetchIncidents(),
    watchFetchIncident(),
    watchFetchCategories(),
    watchCreateIncident(),
    watchUpdateIncident(),
    watchSaveDraftIncident(),
    watchStartAutoSave(),
    watchGetOffices(),
    watchGetRoles(),
    watchCreateUser(),
    watchResetAccount(),
    watchGetClaimTypes(),
    watchGetStatus(),
    watchCreateInsuranceReport(),
    watchFetchInsuranceReports(),
    watchFetchSingleReport(),
    watchUpdateReport(),
    watchDownloadReport(),
    watchDeleteAttachment(),
    watchDeleteClaim(),
    watchAutoComplete(),
    watchGetOrganization(),
    watchVehicleStatuses(),
    watchPropertyStatuses(),
    watchLocationTypes(),
    watchGetOffice(),
    watchUpdateOffice(),
    watchAddProperties(),
    watchAddVehicles(),
    watchUploadFile(),
    watchUpdateProperties(),
    watchUpdateVehicle(),
    watchDeleteProperties(),
    watchDeleteVehicles(),
    watchVehicleConditions(),
    watchGetUserDetails(),
    watchGetChildrenOffices(),
    watchUpdateUser(),
    watchGetHowToFile(),
    watchFetchOfficeRevisions(),
    watchApproveRevision(),
    watchDeclineRevision(),
    watchDeleteOwnComment(),
    watchDeleteReport(),
    watchAddComment(),
    watchDownloadIncidentReport(),
    watchApproveIncident(),
    watchPostComment(),
    watchUpdateClaim(),
    watchDownloadOffice(),
    watchUpdateRecords(),
    watchDownloadPdf(),
    watchFetchStaffImpactDetails(),
    watchCreateStaffImpactDetails(),
    watchUpdateStaffImpactDetails(),
    watchDeleteStaffImpactDetails(),
    watchDeleteIncident(),
    watchDeleteIncidentComment(),
    watchDownloadInsurenceReportXls(),
    watchFetchStatistics(),
    watchDeleteIncidentFile(),
    watchGetAssetTypes(),
    watchAddAssets(),
    watchUpdateAssets(),
    watchDeleteAsset(),
  ]);
}
