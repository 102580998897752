import {
  call,
  put,
  delay,
  cancel,
  cancelled,
  fork,
  take,
  select,
  takeLeading,
  takeEvery,
} from "redux-saga/effects";
import { Creators, Types } from "../../redux/incidents/actions";
import { history } from "../../../util/helpers/browserHistory";
import {
  createIncident as createIncidentApi,
  fetchIncidents as fetchIncidentsApi,
  fetchStaffImpactDetails as fetchStaffImpactDetailsApi,
  createStaffImpactDetails as createStaffImpactDetailsApi,
  updateStaffImpactDetails as updateStaffImpactDetailsApi,
  deleteStaffImpactDetails as deleteStaffImpactDetailsApi,
  deleteIncident as deleteIncidentApi,
  fetchIncident as fetchIncidentApi,
  updateIncident as updateIncidentApi,
  autoComplete as autoCompleteApi,
  uploadFile as uploadFileApi,
  deleteIncidentFile as deleteIncidentFileApi,
  downloadIncidentReport as downloadIncidentReportApi,
  fetchCategories as fetchCategoriesApi,
  downloadPdf as downloadPdfApi,
  deleteIncidentComment as deleteIncidentCommentApi,
  fetchStatistics as fetchStatisticsApi,
} from "../../api/incidents";
import { getUser } from "../../api/axiosDefaults";
import { notification, message } from "antd";
import { radio_field_names } from "../../../util/helpers/form-field-options";
import { cleanData } from "../../../util/helpers/reusable-functions";
import isEqual from "lodash/isEqual";
import some from "lodash/some";

export function* fetchIncidents(actions) {
  try {
    const { query } = actions;
    const response = yield call(fetchIncidentsApi, query);
    const responseData = response.data && response.data.results;
    const recordsCount = response.data && response.data.count;
    const nextUrl = response.data && response.data.next;
    const prevUrl = response.data && response.data.previous;
    yield put(
      Creators.fetchIncidentsSuccess(
        responseData,
        recordsCount,
        nextUrl,
        prevUrl
      )
    );
  } catch (error) {
    yield put(Creators.fetchIncidentsFailure(error && error.message));
  }
}

export function* fetchStaffImpactDetails(actions) {
  try {
    const { incidentId } = actions;
    const response = yield call(fetchStaffImpactDetailsApi, incidentId);
    const responseData = response.data;
    console.log(
      "responseData in fetchStaffImpactDetails ",
      responseData,
      response
    );
    yield put(Creators.fetchStaffImpactDetailsSuccess(responseData));
  } catch (error) {
    yield put(Creators.fetchStaffImpactDetailsFailure(error && error.message));
  }
}

export function* createStaffImpactDetails(actions) {
  try {
    const { incidentId, payload, indexToModify, arrayToModify } = actions;
    const response = yield call(
      createStaffImpactDetailsApi,
      incidentId,
      payload
    );
    const responseData = response.data;
    console.log("responseData in createStaffImpactDetails ", responseData);
    arrayToModify[indexToModify] = responseData[0];
    yield put(Creators.createStaffImpactDetailsSuccess(arrayToModify));
    message.success("Saving a draft...");
  } catch (error) {
    yield put(Creators.createStaffImpactDetailsFailure(error && error.message));
  }
}

export function* updateStaffImpactDetails(actions) {
  try {
    const { incidentId, victimId, payload } = actions;
    const response = yield call(
      updateStaffImpactDetailsApi,
      incidentId,
      victimId,
      payload
    );
    const responseData = response.data;
    yield put(Creators.updateStaffImpactDetailsSuccess(responseData));
    message.success("Saving a draft...");
  } catch (error) {
    yield put(Creators.updateStaffImpactDetailsFailure(error && error.message));
  }
}

export function* deleteStaffImpactDetails(actions) {
  try {
    const { incidentId, victimId } = actions;
    const response = yield call(
      deleteStaffImpactDetailsApi,
      incidentId,
      victimId
    );
    const responseData = response.data;
    yield put(Creators.deleteStaffImpactDetailsSuccess(responseData));
    message.success("Staff impact item details deleted successfully!");
  } catch (error) {
    yield put(Creators.deleteStaffImpactDetailsFailure(error && error.message));
  }
}

export function* deleteIncident(actions) {
  try {
    const { incidentId } = actions;
    const response = yield call(deleteIncidentApi, incidentId);
    const responseData = response.data;
    yield put(Creators.deleteIncidentSuccess(responseData));
    message.success("Incident deleted successfully!");
    history.goBack();
  } catch (error) {
    yield put(Creators.deleteIncidentFailure(error && error.message));
  }
}

export function* fetchIncident(actions) {
  try {
    const { id } = actions;
    const response = yield call(fetchIncidentApi, id);
    const responseData = response.data;
    delete responseData.victims;
    yield put(Creators.fetchIncidentSuccess(responseData));
  } catch (error) {
    yield put(Creators.fetchIncidentFailure(error && error.message));
  }
}

export function* fetchCategories(actions) {
  try {
    const response = yield call(fetchCategoriesApi);
    // yield put(Creators.fetchCategoriesSuccess(response && response.data && response.data.results))
    yield put(Creators.fetchCategoriesSuccess(response && response.data));
  } catch (error) {
    yield put(Creators.fetchCategoriesFailure(error && error.message));
  }
}

export function* createIncident(actions) {
  try {
    const { payload } = actions;
    payload.reporter = getUser();
    const response = yield call(createIncidentApi, payload);
    yield put(Creators.createIncidentSuccess(response.data));
  } catch (error) {
    let errorsFound = error.response && error.response.data;
    yield put(Creators.createIncidentFailure(errorsFound));
  }
}

export function* updateIncident(actions) {
  try {
    const { payload, id } = actions;
    let clonedPayload = { ...payload };

    delete clonedPayload.id;
    delete clonedPayload.simson_reference;
    delete clonedPayload.approval_status;

    if (
      clonedPayload &&
      clonedPayload.victims &&
      clonedPayload.victims.length > 0
    ) {
      // let isVictimAvailable = checkVictims(clonedPayload.victims)
      // if (isVictimAvailable) delete clonedPayload.victims
      delete clonedPayload.victims;
    }
    let cleanedObj = cleanData(clonedPayload);

    const response = yield call(updateIncidentApi, cleanedObj, id);
    yield put(Creators.updateIncidentSuccessful(response.data));
  } catch (error) {
    let errorsFound = error.response && error.response.data;
    yield put(Creators.updateIncidentFailure(errorsFound));
  }
}

export function* uploadFile(actions) {
  try {
    const { file, id } = actions;

    const formData = new FormData();
    formData.append("file", file);

    const data = yield call(uploadFileApi, formData, id);
    yield put(Creators.uploadFileSuccess(data));
  } catch (error) {
    let errorsFound = error.response && error.response.data;
    yield put(Creators.uploadFileFailure(errorsFound));
    message.error("File is too large...");
  }
}

export function* downloadIncidentReport(actions) {
  try {
    const { id } = actions;
    const response = yield call(downloadIncidentReportApi, id);
    const file = new Blob([response && response.data], {
      type: "application/pdf",
    });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_blank");
    yield put(
      Creators.downloadIncidentReportSuccess(response && response.data)
    );
  } catch (error) {
    let errorsFound = error.response && error.response.data;
    yield put(Creators.downloadIncidentReportFailure(errorsFound));
    message.error("Failed to download file");
  }
}

export function* downloadPdf(actions) {
  try {
    const { query } = actions;
    const response = yield call(downloadPdfApi, query);
    const file = new Blob([response && response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_blank");
    yield put(Creators.downloadPdfSuccess(response && response.data));
  } catch (error) {
    let errorsFound = error.response && error.response.data;
    yield put(Creators.downloadPdfFailure(errorsFound));
    message.error("Failed to download file");
  }
}

const checkVictims = (arr) => {
  return some(arr, function (e) {
    return e.gender === "" || e.age === "" || e.impact === "" || e.group === "";
  });
};

function* syncData() {
  try {
    const state = yield select();
    const { draft, isIdRetrieved, draftCopy } = state.incidents;

    if ((draft && draft.id) || isIdRetrieved) {
      // check if draft and draftCopy objects are the same, if not continue...
      if (draftCopy !== null && !isEqual(draft, draftCopy)) {
        let draftObj = { ...draft };

        delete draftObj.id;
        delete draftObj.simson_reference;
        delete draftObj.approval_status;

        if (draftObj && draftObj.victims && draftObj.victims.length > 0) {
          delete draftObj.victims;
        }

        let cleanedObj = cleanData(draftObj);

        const result = yield call(updateIncidentApi, cleanedObj, draft.id);
        yield put(Creators.saveDraftSuccessful(result && result.data)); //here you can also set copy in state to match draft.
        message.success("Saving a draft...");
      }
    } else {
      console.log("syncData on non-draft", draft);
      if (draft && draft.victims && draft.victims.length > 0)
        delete draft.victims;
      yield put(Creators.createIncident(draft));
    }
  } catch (error) {
    yield put(Creators.saveDraftFailed(error));
    message.error("Could not save the draft...");
  }
}

function* saveDraft() {
  try {
    const state = yield select();
    const { draft, isIdRetrieved, draftCopy } = state.incidents;

    if ((draft && draft.id) || isIdRetrieved) {
      let draftObj = { ...draft };

      delete draftObj.id;
      delete draftObj.simson_reference;
      delete draftObj.approval_status;

      if (draftObj && draftObj.victims && draftObj.victims.length > 0) {
        delete draftObj.victims;
      }

      let cleanedObj = cleanData(draftObj);

      const result = yield call(updateIncidentApi, cleanedObj, draft.id);
      yield put(Creators.saveDraftSuccessful(result && result.data)); //here you can also set copy in state to match draft.
      message.success("Saving a draft...");
    } else {
      yield put(Creators.createIncident(draft));
    }
  } catch (error) {
    yield put(Creators.saveDraftFailed(error));
    message.error("Could not save the draft...");
  }
}

function* bgSync() {
  try {
    while (true) {
      yield fork(syncData);
      yield delay(5000);
    }
  } finally {
    if (yield cancelled()) {
      // yield put(actions.requestFailure('Sync cancelled!'))
      console.log("task cancelled");
    }
  }
}

export function* autoComplete(actions) {
  try {
    const { query } = actions;
    const response = yield call(autoCompleteApi, query);
    yield put(
      Creators.autoCompleteSuccessful(response.data && response.data.results)
    );
  } catch (error) {
    yield put(Creators.autoCompleteFailure(error && error.message));
  }
}

export function* approveIncident(actions) {
  try {
    const { id, payload } = actions;
    const data = yield call(updateIncidentApi, payload, id);
    yield put(Creators.approveIncidentSuccess(data));
  } catch (error) {
    let errorsFound = error.response && error.response.data;
    yield put(Creators.approveIncidentFailure(errorsFound));
  }
}

export function* postComment(actions) {
  try {
    const { id, payload } = actions;
    const response = yield call(updateIncidentApi, payload, id);
    yield put(Creators.postCommentSuccess(response.data));
  } catch (error) {
    let errorsFound = error.response && error.response.data;
    yield put(Creators.postCommentFailure(errorsFound));
  }
}

export function* deleteIncidentComment(actions) {
  try {
    const { incidentId, noteId } = actions;
    const response = yield call(deleteIncidentCommentApi, incidentId, noteId);
    const responseData = response.data;
    yield put(Creators.deleteIncidentCommentSuccess(responseData));
    yield call(fetchIncident, { id: incidentId });
    message.success("Comment deleted successfully!");
  } catch (error) {
    yield put(Creators.deleteIncidentCommentFailure(error && error.message));
  }
}

export function* fetchStatistics(actions) {
  try {
    const { query } = actions;
    const response = yield call(fetchStatisticsApi, query);
    yield put(Creators.fetchStatisticsSuccess(response && response.data));
  } catch (error) {
    yield put(Creators.fetchStatisticsFailure(error && error.message));
  }
}

export function* watchFetchIncidents() {
  yield takeEvery(Types.FETCH_INCIDENTS, fetchIncidents);
}

export function* watchFetchIncident() {
  yield takeEvery(Types.FETCH_INCIDENT, fetchIncident);
}

export function* watchFetchStaffImpactDetails() {
  yield takeEvery(Types.FETCH_STAFF_IMPACT_DETAILS, fetchStaffImpactDetails);
}

export function* watchCreateStaffImpactDetails() {
  yield takeEvery(Types.CREATE_STAFF_IMPACT_DETAILS, createStaffImpactDetails);
}

export function* watchUpdateStaffImpactDetails() {
  yield takeEvery(Types.UPDATE_STAFF_IMPACT_DETAILS, updateStaffImpactDetails);
}

export function* watchDeleteStaffImpactDetails() {
  yield takeEvery(Types.DELETE_STAFF_IMPACT_DETAILS, deleteStaffImpactDetails);
}

export function* watchDeleteIncident() {
  yield takeEvery(Types.DELETE_INCIDENT, deleteIncident);
}

export function* watchCreateIncident() {
  yield takeLeading(Types.CREATE_INCIDENT, createIncident);
}

export function* watchUpdateIncident() {
  yield takeLeading(Types.UPDATE_INCIDENT, updateIncident);
}

export function* watchSaveDraftIncident() {
  yield takeLeading(Types.SAVE_DRAFT, saveDraft);
}

export function* watchStartAutoSave() {
  while (yield take(Types.START_AUTO_SAVE)) {
    let bgSyncTask = null;
    const state = yield select();

    if (!state.autoSaveOn) {
      // starts the task in the background
      bgSyncTask = yield fork(bgSync);
    }
    // wait for the user stop action
    yield take(Types.STOP_AUTO_SAVE);
    // user clicked stop. cancel the background task
    // this will cause the forked bgSync task to jump into its finally block
    yield cancel(bgSyncTask);
  }
}

export function* deleteIncidentFile(actions) {
  try {
    const { incidentId, fileId } = actions;
    console.log("actions: ", actions);
    const response = yield call(deleteIncidentFileApi, incidentId, fileId);
    const respdata = response.data;

    yield put(Creators.deleteIncidentFileSuccess(respdata));
    message.success("File deleted successfully.");
  } catch (e) {
    yield put(Creators.deleteIncidentFileFailure(e));
    message.error("Error deleting Media.");
  }
}

export function* watchAutoComplete() {
  yield takeLeading(Types.AUTO_COMPLETE, autoComplete);
}

export function* watchUploadFile() {
  yield takeEvery(Types.UPLOAD_FILE, uploadFile);
}

export function* watchDownloadIncidentReport() {
  yield takeEvery(Types.DOWNLOAD_INCIDENT_REPORT, downloadIncidentReport);
}

export function* watchFetchCategories() {
  yield takeEvery(Types.FETCH_CATEGORIES, fetchCategories);
}

export function* watchApproveIncident() {
  yield takeEvery(Types.APPROVE_INCIDENT, approveIncident);
}

export function* watchPostComment() {
  yield takeEvery(Types.POST_COMMENT, postComment);
}

export function* watchDownloadPdf() {
  yield takeEvery(Types.DOWNLOAD_PDF, downloadPdf);
}

export function* watchDeleteIncidentComment() {
  yield takeEvery(Types.DELETE_INCIDENT_COMMENT, deleteIncidentComment);
}

export function* watchFetchStatistics() {
  yield takeEvery(Types.FETCH_STATISTICS, fetchStatistics);
}

export function* watchDeleteIncidentFile() {
  yield takeEvery(Types.DELETE_INCIDENT_FILE, deleteIncidentFile);
}
