import { apiRequest, apiRequestOthers } from "../apiRequest";
import {
  INCIDENTS_ENDPOINT,
  INCIDENT_FILE_UPLOAD_ENDPOINT,
  INCIDENT_CATEGORIES_ENDPOINT,
  GET_STATISTICS_ENDPOINT,
} from "../urls";
import moment from "moment";

export const fetchIncidents = (query = { is_draft: false }) => {
  let search_term = query && query.search_term;
  let category = query && query.category;
  let queryImpact = query && query.impact;
  let incident_impact = null;
  let program_impact = null;
  if (queryImpact) {
    switch (queryImpact) {
      case "injuries_physical":
        incident_impact = "injuries_physical";
        break;
      case "injuries_social":
        incident_impact = "injuries_social";
        break;
      case "fatality":
        incident_impact = "fatalities";
        break;
      case "kidnapped":
        incident_impact = "kidnappings";
        break;
      default:
        program_impact = queryImpact;
    }
  }
  let reporter_office = query && query.reporter_office;
  let limit = query && query.limit;
  let offset = query && query.offset;
  let is_draft = query && query.is_draft;
  let report_date = query && query.report_date;
  let start_date = query && query.start_date;
  let end_date = query && query.end_date;
  let approval_status = query && query.approval_status;
  let approved = query && query.approved;

  let created_from_date = null;
  let created_to_date = null;
  let date_occured_from_date = null;
  let date_occured_to_date = null;

  if (report_date) {
    if (report_date === "incident_date") {
      date_occured_from_date = start_date;
      date_occured_to_date = end_date;
    } else {
      created_from_date = start_date;
      created_to_date = end_date;
    }
  }

  return apiRequest(
    "GET",
    `${INCIDENTS_ENDPOINT}?is_draft=${is_draft}
		${approval_status ? `&approval_status=${approval_status}` : ""}
		${`&approved=${approved}`}
		${search_term ? `&search=${search_term}` : ""}
		${limit ? `&limit=${limit}` : ""}
		${offset ? `&offset=${offset}` : ""}
		${category ? `&category=${category}` : ""}
		${program_impact ? `&program_impact=${program_impact}` : ""}
		${incident_impact ? `&${incident_impact}=true` : ""}
		${reporter_office ? `&reporter_office=${reporter_office}` : ""}
		${
      date_occured_from_date
        ? `&date_occured_from_date=${moment(date_occured_from_date).format(
            "YYYY-MM-DD"
          )}`
        : ""
    }
		${
      date_occured_to_date
        ? `&date_occured_to_date=${moment(date_occured_to_date).format(
            "YYYY-MM-DD"
          )}`
        : ""
    }
		${
      created_from_date
        ? `&created_from_date=${moment(created_from_date).format("YYYY-MM-DD")}`
        : ""
    }
		${
      created_to_date
        ? `&created_to_date=${moment(created_to_date).format("YYYY-MM-DD")}`
        : ""
    }`
  );
};

export const fetchStaffImpactDetails = (incidentId) => {
  return apiRequest("GET", `${INCIDENTS_ENDPOINT}/${incidentId}/victims/`);
};

export const createStaffImpactDetails = (incidentId, payload) => {
  return apiRequest(
    "POST",
    `${INCIDENTS_ENDPOINT}/${incidentId}/victims/`,
    payload
  );
};

export const updateStaffImpactDetails = (incidentId, victimId, payload) => {
  return apiRequest(
    "PATCH",
    `${INCIDENTS_ENDPOINT}/${incidentId}/victims/${victimId}/`,
    payload
  );
};

export const deleteStaffImpactDetails = (incidentId, victimId) => {
  return apiRequest(
    "DELETE",
    `${INCIDENTS_ENDPOINT}/${incidentId}/victims/${victimId}/`
  );
};

export const deleteIncident = (incidentId) => {
  return apiRequest("DELETE", `${INCIDENTS_ENDPOINT}/${incidentId}`);
};

export const deleteIncidentComment = (incidentId, noteId) => {
  return apiRequest(
    "DELETE",
    `${INCIDENTS_ENDPOINT}/${incidentId}/notes/${noteId}/`
  );
};

export const createIncident = (data) => {
  return apiRequest("POST", INCIDENTS_ENDPOINT, data);
};

export const updateIncident = (data, id) => {
  return apiRequest("PATCH", `${INCIDENTS_ENDPOINT}/${id}/`, data);
};

export const fetchIncident = (id) => {
  return apiRequest("GET", `${INCIDENTS_ENDPOINT}/${id}/`);
};

export const fetchCategories = () => {
  return apiRequest("GET", `${INCIDENT_CATEGORIES_ENDPOINT}/`);
};

export const autoComplete = (query = { is_draft: false }) => {
  let is_draft = query && query.is_draft;
  let search_term = query && query.searchTerm;

  return apiRequest(
    "GET",
    `${INCIDENTS_ENDPOINT}?is_draft=${is_draft}
		${search_term ? `&search=${search_term}` : ""}
		&limit=10&offset=0`
  );
};

export const uploadFile = (data, id) => {
  return apiRequestOthers(
    "PUT",
    `${INCIDENTS_ENDPOINT}/${id}/${INCIDENT_FILE_UPLOAD_ENDPOINT}`,
    data,
    "*/*"
  );
};

export const downloadIncidentReport = (id) => {
  return apiRequestOthers(
    "GET",
    `${INCIDENTS_ENDPOINT}/${id}/`,
    {},
    "application/pdf",
    "arraybuffer"
  );
};

// export const approveIncident = (id, data) => {
// 	return apiRequest('PATCH', `${INCIDENTS_ENDPOINT}/${id}/approve/`, data);
// }

// export const postComment = (id, data) => {

// }

export const deleteIncidentFile = (incidentId, fileId) => {
  return apiRequest(
    "DELETE",
    `${INCIDENTS_ENDPOINT}/${incidentId}/${INCIDENT_FILE_UPLOAD_ENDPOINT}${fileId}/`
  );
};

export const downloadPdf = (query = { is_draft: false }) => {
  let search_term = query && query.search_term;
  let category = query && query.category;
  let program_impact = query && query.impact;
  let reporter_office = query && query.reporter_office;
  let limit = query && query.limit;
  let offset = query && query.offset;
  let is_draft = query && query.is_draft;
  let report_date = query && query.report_date;
  let start_date = query && query.start_date;
  let end_date = query && query.end_date;
  let approval_status = query && query.approval_status;
  let approved = query && query.approved;

  let created_from_date = null;
  let created_to_date = null;
  let date_occured_from_date = null;
  let date_occured_to_date = null;

  if (report_date) {
    if (report_date === "incident_date") {
      date_occured_from_date = start_date;
      date_occured_to_date = end_date;
    } else {
      created_from_date = start_date;
      created_to_date = end_date;
    }
  }

  return apiRequestOthers(
    "GET",
    `${INCIDENTS_ENDPOINT}?is_draft=${is_draft}
		${approval_status ? `&approval_status=${approval_status}` : ""}
		${`&approved=${approved}`}
		${search_term ? `&search=${search_term}` : ""}
		${limit ? `&limit=${limit}` : ""}
		${offset ? `&offset=${offset}` : ""}
		${category ? `&category=${category}` : ""}
		${program_impact ? `&program_impact=${program_impact}` : ""}
		${reporter_office ? `&reporter_office=${reporter_office}` : ""}
		${
      date_occured_from_date
        ? `&date_occured_from_date=${moment(date_occured_from_date).format(
            "YYYY-MM-DD"
          )}`
        : ""
    }
		${
      date_occured_to_date
        ? `&date_occured_to_date=${moment(date_occured_to_date).format(
            "YYYY-MM-DD"
          )}`
        : ""
    }
		${
      created_from_date
        ? `&created_from_date=${moment(created_from_date).format("YYYY-MM-DD")}`
        : ""
    }
		${
      created_to_date
        ? `&created_to_date=${moment(created_to_date).format("YYYY-MM-DD")}`
        : ""
    }`,
    {},
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "arraybuffer"
  );
};

export const fetchStatistics = (query = { is_draft: false }) => {
  let report_date = query && query.report_date;
  let start_date = query && query.start_date;
  let end_date = query && query.end_date;
  let is_draft = query && query.is_draft;
  let created_from_date = null;
  let created_to_date = null;
  let date_occured_from_date = null;
  let date_occured_to_date = null;

  if (report_date) {
    if (report_date === "incident_date") {
      date_occured_from_date = start_date;
      date_occured_to_date = end_date;
    } else {
      created_from_date = start_date;
      created_to_date = end_date;
    }
  }
  return apiRequest(
    "GET",
    `${GET_STATISTICS_ENDPOINT}?is_draft=${is_draft}
		${
      date_occured_from_date
        ? `&date_occured_from_date=${moment(date_occured_from_date).format(
            "YYYY-MM-DD"
          )}`
        : ""
    }
		${
      date_occured_to_date
        ? `&date_occured_to_date=${moment(date_occured_to_date).format(
            "YYYY-MM-DD"
          )}`
        : ""
    }
		${
      created_from_date
        ? `&created_from_date=${moment(created_from_date).format("YYYY-MM-DD")}`
        : ""
    }
		${
      created_to_date
        ? `&created_to_date=${moment(created_to_date).format(
            "YYYY-MM-DD HH:mm:ss"
          )}`
        : ""
    }`
  );
};
