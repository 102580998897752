import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
  {
    fetchIncidents: ["query"],
    fetchIncidentsSuccess: ["responseData", "count", "nextUrl", "prevUrl"],
    fetchIncidentsFailure: ["errors"],

    fetchIncident: ["id"],
    fetchIncidentSuccess: ["responseData"],
    fetchIncidentFailure: ["errors"],

    createIncident: ["payload"],
    createIncidentSuccess: ["responseData"],
    createIncidentFailure: ["errors"],

    updateIncident: ["payload", "id"],
    updateIncidentSuccessful: ["responseData"],
    updateIncidentFailure: ["errors"],

    moveNext: ["incidentsPayload", "nextStep", "victimsPayload"],
    moveBack: ["step"],
    resetSteps: null,
    setDraftForEdit: null,

    startAutoSave: ["payload"],
    stopAutoSave: null,

    saveDraft: null,
    saveDraftSuccessful: ["responseData"],
    saveDraftFailed: ["errors"],

    resetForm: null,

    setCoordinates: ["latLng"],

    autoComplete: ["query"],
    autoCompleteSuccessful: ["responseData"],
    autoCompleteFailure: ["errors"],

    resetAutoComplete: null,

    uploadFile: ["file", "id"],
    uploadFileSuccess: ["responseData"],
    uploadFileFailure: ["errors"],

    downloadIncidentReport: ["id"],
    downloadIncidentReportSuccess: ["responseData"],
    downloadIncidentReportFailure: ["errors"],

    fetchCategories: null,
    fetchCategoriesSuccess: ["responseData"],
    fetchCategoriesFailure: ["errors"],

    approveIncident: ["id", "payload"],
    approveIncidentSuccess: ["responseData"],
    approveIncidentFailure: ["errors"],

    postComment: ["id", "payload"],
    postCommentSuccess: ["responseData"],
    postCommentFailure: ["errors"],

    downloadPdf: ["query"],
    downloadPdfSuccess: ["responseData"],
    downloadPdfFailure: ["errors"],

    saveFilters: ["filters"],

    createStaffImpactDetails: [
      "incidentId",
      "payload",
      "indexToModify",
      "arrayToModify",
    ],
    createStaffImpactDetailsSuccess: ["responseData"],
    createStaffImpactDetailsFailure: ["errors"],

    updateStaffImpactDetails: ["incidentId", "victimId", "payload"],
    updateStaffImpactDetailsSuccess: ["responseData"],
    updateStaffImpactDetailsFailure: ["errors"],

    deleteStaffImpactDetails: ["incidentId", "victimId"],
    deleteStaffImpactDetailsSuccess: ["responseData"],
    deleteStaffImpactDetailsFailure: ["errors"],

    deleteIncident: ["incidentId"],
    deleteIncidentSuccess: ["responseData"],
    deleteIncidentFailure: ["errors"],

    deleteIncidentComment: ["incidentId", "noteId"],
    deleteIncidentCommentSuccess: ["responseData"],
    deleteIncidentCommentFailure: ["errors"],

    fetchStaffImpactDetails: ["incidentId"],
    fetchStaffImpactDetailsSuccess: ["responseData"],
    fetchStaffImpactDetailsFailure: ["errors"],

    fetchStatistics: ["query"],
    fetchStatisticsSuccess: ["responseData"],
    fetchStatisticsFailure: ["errors"],

    deleteIncidentFile: ["incidentId", "fileId"],
    deleteIncidentFileSuccess: ["responseData"],
    deleteIncidentFileFailure: ["error"],
  },
  {}
);
