import { call, put, takeLeading, takeEvery } from 'redux-saga/effects';
import {message } from "antd"
import { Creators, Types } from '../../redux/organization/actions'
import {
	getRoles as getRolesAPi,
	createUsers as createUserApi,
	resetNewAccount as resetAccountApi,
	getOrganization as getOrganizationApi,
	getUserById,
	updateUser
} from '../../api/organization';


export function* fetchOrganization() {
	try {
		const response = yield call(getOrganizationApi);
		const data = response.data;
		if (response.status === 200 || response.status === 201) {

            console.log(data);
			yield put(Creators.getOrganizationSuccess(data));
			
		} 	
	} catch (e) {
		const message = "Error fetching organization.";
		yield put(Creators.getOrganizationFailure(message));
	}
}

export function* fetchRoles() {
	try {
		const response = yield call(getRolesAPi);
		const data = response.data;
		if (response.status === 200 || response.status === 201) {

            console.log(data);
			yield put(Creators.getRolesSuccess(data));
			
		} 	
	} catch (e) {
		const message = "Error fetching roles.";
		yield put(Creators.getRolesFailure(message));
	}
}

export function* getUserDetails(actions) {
	try {
		const { user_id } = actions;
		const response = yield call(getUserById, user_id);
		const data = response.data;
		if (response.status === 200 || response.status === 201) {

            console.log(data);
			yield put(Creators.getUserSuccess(data));
			
		} 	
	} catch (e) {
		const message = "Error fetching record.";
		yield put(Creators.getUserFailure(message));
	}
}


export function* createUser(actions) {
	try {
		console.log(actions);
		const { data } = actions;
		const response = yield call(createUserApi, data);
		const respdata = response.data;
		if (response.status === 200 || response.status === 201) {

			console.log(respdata);
			const message = `A welcome message with further instructions has been e-mailed to the new user ${data.email}`;

			// call reset password endpoint 
			yield call(resetNewAccount, { email: data.email});
			yield put(Creators.createUserSuccess(message));

		} 	
	} catch (e) {
		var err = { e }.e.response;
		const message = err.data && Object.values(err.data).length > 0 ? Object.values(err.data)[0] : "Error creating user. Please try again."; 
		yield put(Creators.createUserFailure(message));
	}
}

export function* updateUserRecord(actions) {
	try {
		console.log(actions);
		const { user_id, data } = actions;
		const response = yield call(updateUser, user_id, data);
		const respdata = response.data;
		if (response.status === 200 || response.status === 201) {

			console.log(respdata);
			yield put(Creators.updateUserSuccess(respdata));
			message.success("Record updated successfully.");

		} 	
	} catch (e) {
		var err = { e }.e.response;
		const message = err.data && Object.values(err.data).length > 0 ? Object.values(err.data)[0] : "Error updating user. Please try again."; 
		yield put(Creators.updateUserFailure(message));
	}
}

export function* resetNewAccount(requestData) {
	try {
		console.log(requestData);
		const response = yield call(resetAccountApi, requestData);
		const respdata = response.data;
		if (response.status === 200 || response.status === 201) {

			console.log(respdata);
			const message = "";
			yield put(Creators.resetNewAccountSuccess(message));

		} 	
	} catch (e) {
		var err = { e }.e.response;
		const message = err.data && Object.values(err.data).length > 0 ? Object.values(err.data)[0] :
		 "Error creating user. Please try again."; 
		yield put(Creators.resetNewAccountFailure(message));
	}
}


export function* watchGetOrganization() {
	yield takeEvery(Types.GET_ORGANIZATION, fetchOrganization);
}

export function* watchGetRoles() {
    yield takeEvery(Types.GET_ROLES, fetchRoles);
}

export function* watchCreateUser() {
    yield takeEvery(Types.CREATE_USER, createUser);
}

export function* watchUpdateUser() {
    yield takeEvery(Types.UPDATE_USER, updateUserRecord);
}

export function* watchResetAccount() {
    yield takeEvery(Types.RESET_NEW_ACCOUNT, resetNewAccount);
}

export function* watchGetUserDetails() {
    yield takeEvery(Types.GET_USER, getUserDetails);
}

