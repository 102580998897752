import { createTypes } from 'reduxsauce'
 
export default createTypes(`
  GET_ROLES
  GET_ROLES_SUCCESS
  GET_ROLES_FAILURE

  CREATE_USER
  CREATE_USER_SUCCESS
  CREATE_USER_FAILURE

  GET_USER
  GET_USER_SUCCESS
  GET_USER_FAILURE

  UPDATE_USER
  UPDATE_USER_SUCCESS
  UPDATE_USER_FAILURE

  RESET_NEW_ACCOUNT
  RESET_NEW_ACCOUNT_SUCCESS
  RESET_NEW_ACCOUNT_FAILURE

  GET_ORGANIZATION
  GET_ORGANIZATION_SUCCESS
  GET_ORGANIZATION_FAILURE

`, {});
