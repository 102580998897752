/* Base Urls */

export const BASE_URL = process.env.REACT_APP_BASE_URL;

/* Auth endpoints */
export const LOGIN_ENDPOINT = "token/";
export const RESET_PASSWORD_ENDPOINT = "password_reset_request/";
export const CHANGE_PASSWORD_ENDPOINT = "password_reset_confirm/";
export const FORGOT_PASSWORD_ENDPOINT = "forgot/password";
export const REFRESH_TOKEN_ENDPOINT = "refresh/token";
export const HOW_TO_USE_SIMSON_ENDPOINT = "how-to-use-simson/";

/* user account endpoints */
export const GET_ROLES_ENDPOINT = "roles/";
export const USERS_ENDPOINT = "users/";
export const USER_PROFILE_ENDPOINT = "me/";
export const RESET_NEW_ACCOUNT_ENDPOINT = "reset_new_account_password/";

/* Insurance report endpoints */
export const GET_CLAIM_TYPES_ENDPOINT = "insurance-report-claim-types/";
export const GET_CLAIM_STATUS_ENDPOINT = "insurance-report-statuses/";
export const INSURANCE_REPORT_ENDPOINT = "insurance-reports/";

/* organization endpoints */
export const GET_ORGANIZATION = "my_organization/";

/* location endpoints */
export const GET_OFFICES_ENDPOINT = "offices/";
export const GET_LOCATION_TYPES = "office-property-location-types/";
export const GET_PROPERTY_OWNERSHIP_STATUSES =
  "office-property-ownership-statuses/";
export const GET_VEHICLE_OWNERSHIP_TYPES = "office-vehicle-ownership-types/";
export const GET_VEHICLE_CONDITION = "office-vehicle-conditions/";
export const GET_CHILDREN_OFFICES = "children-offices/";
export const OFFICE_REVISION_ENDPOINT = "office-revisions/";

/** Asset Types EndPoints */
export const GET_ASSET_TYPES = "office-asset-types/";

/* Incident endpoints */
// export const INCIDENTS_ENDPOINT = 'geocode-address?address=kampala&&api-key=wEIM1OYzR6qqvUYkyFMxcOcgwB54JdMZWT41al4WmrfknlEk&&user-id=waspa';
// export const INCIDENTS_ENDPOINT = 'geocode-address?address=kampala&&api-key=SUBQR7mLxWletegr2f3MjJqhvEZ9aFVmpgCJKEoi3yUozGcI&&user-id=lara';
export const INCIDENTS_ENDPOINT = "incidents";
export const INCIDENT_CATEGORIES_ENDPOINT = "incidents/categories";
export const INCIDENT_FILE_UPLOAD_ENDPOINT = "media/";
export const GET_STATISTICS_ENDPOINT = "statistics";

// just for test, SINGLE_INCIDENTS_ENDPOINT is wierd labelling of an endpoint
export const SINGLE_INCIDENT_ENDPOINT =
  "ip-info?ip=162.209.104.195&&api-key=uYRphhNU8AvfxguSX2vTjfyslOoNTLXxQsTrrzg7LyRk5z4o&&user-id=waspa";
