import axios from 'axios';
import { BASE_URL } from './urls';
import decode from 'jwt-decode';
import { roles as RolesEnum } from "../../util/helpers/enum"

// for endpoints that do not require tokens
export default axios.create({
  baseURL: BASE_URL
});

export const getToken = () => {
  //get token from localstorage
  let user = JSON.parse(localStorage.getItem('user-access'));
  let token = user && user.access;
  return token;
}

export const getUserDetails = () => {
  //get user details from localstorage
  let user = localStorage.getItem('user-details') && JSON.parse(localStorage.getItem('user-details'));
  return user;
}

export const getUserRole = () => {
  //get user details from localstorage
  let user = getUserDetails();
  let roles = [];

  if (user && user.roles && user.roles.length > 0) {
    user.roles.forEach(u => {
      
      const role = RolesEnum.filter(r => r.id === u);
      if (role.length > 0) {
        roles.push(role[0].roleName);
      } else {
        roles.push("regular_user");
      }
    })
  } else {
    roles.push("regular_user");
  }

  return roles;
}

export const getUser = () => {
  const { user_id } = decode(getToken());
  return user_id;
}

export const refreshToken = () => {

}

export const axiosInstance = () => {
  var AUTH_TOKEN = getToken();
  const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + AUTH_TOKEN
    }
  });
  // maybe we can add an interceptor, which checks if token is expired and refreshes it.

  return instance;
}


// this is for other request types i.e application/pdf or formdata etc
export const axiosInstanceOthers = (headerType, responseType) => {
  var AUTH_TOKEN = getToken();
  console.log(headerType)
  const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
      Accept: headerType,
      Authorization: 'Bearer ' + AUTH_TOKEN,
      'Content-Type': 'multipart/form-data'
    },
    responseType: responseType,
  });

  return instance;
}