import { connect } from 'react-redux';
import { Creators } from '../../services/redux/auth/actions';
import LayoutPage from "./components/LayoutPage";

const mapStateToProps = state => {
  const { autoSaving } = state.incidents;
  return {
    auth: state.auth,
    autoSaving
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => {
      dispatch(Creators.logout());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LayoutPage);

