import { createActions } from 'reduxsauce'
 
export const { Types, Creators } = createActions({
  loginRequest: ['username', 'password'],
  loginSuccess: ['responseData'],
  loginFailure: ['error'],
  logout: null,
  resetPasswordRequest: ['email', 'captchaToken'],
  resetPasswordSuccess: ['responseData'],
  resetPasswordFailure: ['error'],
  changePasswordRequest: ['password', 'token'],
  changePasswordSuccess: ['responseData'],
  changePasswordFailure: ['error'],
  GetHelpFile: null,
  GetHelpFileSuccess: ['responseData'],
  GetHelpFileFailure: ['error']
}, {});
