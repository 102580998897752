import { createTypes } from "reduxsauce";

export default createTypes(
  `
  GET_OFFICES
  GET_OFFICES_SUCCESS
  GET_OFFICES_FAILURE
  
  GET_CHILDREN_OFFICES
  GET_CHILDREN_OFFICES_SUCCESS
  GET_CHILDREN_OFFICES_FAILURE

  GET_OFFICE
  GET_OFFICE_SUCCESS
  GET_OFFICE_FAILURE

  DOWNLOAD_OFFICE
  DOWNLOAD_OFFICE_SUCCESS
  DOWNLOAD_OFFICE_FAILURE

  GET_OFFICE_REVISIONS
  GET_OFFICE_REVISIONS_SUCCESS
  GET_OFFICE_REVISIONS_FAILURE

  UPDATE_OFFICE
  UPDATE_OFFICE_SUCCESS
  UPDATE_OFFICE_FAILURE

  UPDATE_OFFICE_RECORDS
  UPDATE_OFFICE_RECORDS_SUCCESS
  UPDATE_OFFICE_RECORDS_FAILURE

  APPROVE_REVISION
  APPROVE_REVISION_SUCCESS
  APPROVE_REVISION_FAILURE

  DECLINE_REVISION
  DECLINE_REVISION_SUCCESS
  DECLINE_REVISION_FAILURE

  ADD_PROPERTIES
  ADD_PROPERTIES_SUCCESS
  ADD_PROPERTIES_FAILURE

  UPDATE_PROPERTIES
  UPDATE_PROPERTIES_SUCCESS
  UPDATE_PROPERTIES_FAILURE

  DELETE_PROPERTY
  DELETE_PROPERTY_SUCCESS
  DELETE_PROPERTY_FAILURE

  ADD_VEHICLES
  ADD_VEHICLES_SUCCESS
  ADD_VEHICLES_FAILURE

  DELETE_VEHICLE
  DELETE_VEHICLE_SUCCESS
  DELETE_VEHICLE_FAILURE

  UPDATE_VEHICLES
  UPDATE_VEHICLES_SUCCESS
  UPDATE_VEHICLES_FAILURE

  GET_LOCATION_TYPES
  GET_LOCATION_TYPES_SUCCESS
  GET_LOCATION_TYPES_FAILURE

  GET_VEHICLE_CONDITION
  GET_VEHICLE_CONDITION_SUCCESS
  GET_VEHICLE_CONDITION_FAILURE

  GET_PROPERTY_STATUSES
  GET_PROPERTY_STATUSES_SUCCESS
  GET_PROPERTY_STATUSES_FAILURE

  GET_VEHICLE_STATUSES
  GET_VEHICLE_STATUSES_SUCCESS
  GET_VEHICLE_STATUSES_FAILURE

  GET_ASSET_TYPES
  GET_ASSET_TYPES_SUCCESS
  GET_ASSET_TYPES_FAILURE

  ADD_ASSETS
  ADD_ASSETS_SUCCESS
  ADD_ASSETS_FAILURE

  UPDATE_ASSETS
  UPDATE_ASSETS_SUCCESS
  UPDATE_ASSETS_FAILURE

  DELETE_ASSET
  DELETE_ASSET_SUCCESS
  DELETE_ASSET_FAILURE

  NEXT_PAGE
  PREVIOUS_PAGE
  UPDATE_LOCATION_DATA
  RESET_LOCATION_DATA

`,
  {}
);
