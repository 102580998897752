import React from "react";
import {Layout, Row, Col} from "antd";
import  "../../styles/header.less";
import MobileHeader from "./components/MobileHeader";
import WebHeader from "./components/WebHeader";
import { withRouter } from 'react-router-dom';
import LoginComponent from "../../containers/Auth/components/LoginComponent";
import { connect } from 'react-redux';
import { Creators } from '../../services/redux/auth/actions';
import { getUserDetails } from '../../services/api/axiosDefaults';
import { Creators as OrganizationCreator } from '../../services/redux/organization/actions';




const { Header} = Layout;

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          loginModalVisible: false
        }
      }
    
      showModal = () => {
        this.setState({
          loginModalVisible: true,
        });
      };
    
      handleCancel = e => {
        this.setState({
          loginModalVisible: false,
        });
      };
      
      componentDidUpdate(prevProps) {
        const { auth, history, userDetails } = this.props;
        if (auth.user !== prevProps.auth.user && auth.user) {
          this.setState(previousState => (
            { loginModalVisible: false }
          ), () => {
            // history.push('/home');
          });
        }

        //download help file
        if (auth.simsonHelpFile !== prevProps.auth.simsonHelpFile && auth.simsonHelpFile) {
          console.log(auth.simsonHelpFile.document_link)                   
          //Open the URL on new Window
              window.open(auth.simsonHelpFile.document_link, "_blank");
        }

        //redirect to profile edit when the details have been gotten 
        if (userDetails && userDetails !== prevProps.userDetails) {
            history.push(`/user/${userDetails.id}`)
        }

      }

      editProfile = (e) => {
        const { getUser } = this.props;
        const user = getUserDetails();
        getUser(user.id);
      }


    render() {
        const { loggedInUser, logoutMethod, isEditing } = this.props;
        const { loginModalVisible } = this.state;

        return (
            <>
            <Header className="header">
            <div id="main">
                <div id="topbanner"></div>
            <Row>
                {/* web header  */}
                <Col xs={0} sm={0} md={24} >
                    <WebHeader 
                      loggedInUser={loggedInUser} 
                      showLoginModal={this.showModal} 
                      logout={this.props.logoutMethod} 
                      isEditing={isEditing}
                      editProfile={this.editProfile}
                      {...this.props}
                    />
                </Col>
                {/* mobile header  */}
                <Col xs={24} sm={24} md={0} >
                    <MobileHeader 
                      loggedInUser={loggedInUser} 
                      showLoginModal={this.showModal} 
                      logout={this.props.logoutMethod} 
                      isEditing={isEditing}
                      editProfile={this.editProfile}
                      {...this.props}
                    />
                </Col> 
            </Row>
                              
            </div>
                
            </Header>

            {/* login modal component  */}
           <LoginComponent 
            showModal={loginModalVisible} 
            onCancel={this.handleCancel}
            />
            </>
      );
    }
}

const mapStateToProps = state => {
  const { isEditing } = state.incidents;
  const { userDetails } = state.organization;
  return {
    auth: state.auth,
    isEditing,
    userDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    downloadHelpFile: () => {
      dispatch(Creators.GetHelpFile());
    },
    getUser: (user_id) => {
      dispatch(OrganizationCreator.getUser(user_id));
  },
  };
};


export default withRouter(
  connect(mapStateToProps,
          mapDispatchToProps)(Index)
);