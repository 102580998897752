import { call, put, takeEvery, all } from "redux-saga/effects";
import { Creators, Types } from "../../redux/locations/actions";
import {
  getOffices as getOfficesApi,
  getOffice,
  getLocationTypes,
  getPropertyOwnershipStatuses,
  getVehicleOwnershipStatuses,
  updateOffice as updateOfficeApi,
  addLocationProperties,
  addLocationVehicles,
  updateLocationProperties,
  deleteLocationProperties,
  updateLocationVehicles,
  deleteLocationVehicles,
  getVehicleCondition,
  getChildrenOffices,
  getOfficeRevisions,
  declineRevision as declineRevisionApi,
  approveRevision as approveRevisionApi,
  downloadOfficeData as downloadOfficeApi,
  getAssetTypesApi,
  addAssetsApi,
  updateAssetsApi,
  deleteAssetApi,
} from "../../api/locations";
import { message } from "antd";
import { history } from "../../../util/helpers/browserHistory";

export function* fetchOffices(actions) {
  try {
    const { page, search } = actions;
    const response = yield call(getOfficesApi, page, search);
    const data = response.data;
    console.log(data);
    if (response.status === 200 || response.status === 201) {
      console.log(data);
      yield put(Creators.getOfficesSuccess(data));
    }
  } catch (e) {
    const message = "Error fetching offices.";
    yield put(Creators.getOfficesFailure(message));
  }
}

export function* fetchOfficeRevisions(actions) {
  try {
    const { page } = actions;
    const response = yield call(getOfficeRevisions, page);
    const data = response.data;
    console.log(data);
    if (response.status === 200 || response.status === 201) {
      console.log(data);
      yield put(Creators.getOfficeRevisionsSuccess(data));
    }
  } catch (e) {
    const message = "Error fetching offices.";
    console.log(e);
    yield put(Creators.getOfficeRevisionsFailure(message));
  }
}

export function* fetchChildrenOffices() {
  try {
    const response = yield call(getChildrenOffices);
    const data = response.data;
    console.log(data);
    if (response.status === 200 || response.status === 201) {
      console.log(data);
      yield put(Creators.getChildrenOfficesSuccess(data));
    }
  } catch (e) {
    const message = "Error fetching offices.";
    yield put(Creators.getChildrenOfficesFailure(message));
  }
}

export function* fetchOffice(actions) {
  try {
    const { office_id } = actions;
    const response = yield call(getOffice, office_id);
    const data = response.data;
    if (response.status === 200 || response.status === 201) {
      console.log(data);
      yield put(Creators.getOfficeSuccess(data));
    }
  } catch (e) {
    const message = "Error fetching office.";
    yield put(Creators.getOfficeFailure(message));
  }
}

export function* downloadOffice(actions) {
  try {
    const { id } = actions;
    const download_resp = yield call(downloadOfficeApi, id);

    const response = download_resp && download_resp.data;
    const file = new Blob([response], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_blank");

    yield put(Creators.downloadOfficeSuccess(response));
  } catch (e) {
    yield put(Creators.downloadOfficeFailure(e));
  }
}

export function* updateOffice(actions) {
  try {
    const { office_id, data } = actions;
    console.log(office_id);
    const response = yield call(updateOfficeApi, office_id, data);
    const respdata = response.data;
    if (response.status === 200 || response.status === 201) {
      console.log(respdata);
      yield put(Creators.updateOfficeSuccess(respdata));
    }
  } catch (e) {
    var err = { e }.e.response;

    if (err.data && err.data.detail) {
      message.error(err.data.detail);
    } else {
      message.error("Error occurred while updating office record.");
    }
    yield put(Creators.updateOfficeFailure(err && err.data));
  }
}

export function* updateOfficeRecords(actions) {
  try {
    const { office_id, data, originalOfficeRecord } = actions;

    // to prevent modification to the original data
    Object.freeze(data);

    // for new properties
    let allProperties = [];
    if (data.newProperties) allProperties.push(...data.newProperties);

    console.log(allProperties);
    if (allProperties.length > 0) {
      const newPropertiesData = allProperties.filter((m) => !m.id);
      console.log(newPropertiesData);
      if (newPropertiesData.length > 0)
        yield call(addProperties, { office_id, data: newPropertiesData });

      //update existing properties
      const updatePropertyData = allProperties.filter((m) => m.id);
      console.log(updatePropertyData);
      if (updatePropertyData.length > 0) {
        yield all(
          updatePropertyData.map((u) =>
            call(updateProperties, { office_id, data: u, property_id: u.id })
          )
        );
      }
    }

    // since motorcycle and vehicles use the same endpoint, merge both records
    let allVehicles = [];
    if (data.newMotorcycles) allVehicles.push(...data.newMotorcycles);
    if (data.newVehicles) allVehicles.push(...data.newVehicles);
    console.log(allVehicles);

    //change this to new vehicle; if it's a new vehicle, call the add endpoint, if it's existing call update vehicle
    if (allVehicles.length > 0) {
      const newVehicleData = allVehicles.filter((m) => !m.id);
      if (newVehicleData.length > 0)
        yield call(addVehicles, { office_id, data: newVehicleData });

      //update existing vehicle
      const updateVehicleData = allVehicles.filter((m) => m.id);
      if (updateVehicleData.length > 0) {
        yield all(
          updateVehicleData.map((u) =>
            call(updateVehicles, { office_id, data: u, vehicle_id: u.id })
          )
        );
      }
    }

    // since motorcycle and assets use the same endpoint, merge both records
    let allAssets = [];
    if (data.newAssets) allAssets.push(...data.newAssets);

    console.log("allAssets:", allAssets);

    //change this to new asset; if it's a new asset, call the add endpoint, if it's existing call update asset
    if (allAssets.length > 0) {
      const newAssetData = allAssets.filter((m) => !m.id);
      if (newAssetData.length > 0)
        yield call(addAssets, { office_id, data: newAssetData });

      //update existing asset
      const updateAssetData = allAssets.filter((m) => m.id);
      if (updateAssetData.length > 0) {
        yield all(
          updateAssetData.map((u) =>
            call(updateAssets, { office_id, data: u, asset_id: u.id })
          )
        );
      }
    }

    // if there are chnages to office data driectly then update office
    const officeData = JSON.parse(JSON.stringify(data)); // to deep copy
    officeData.newProperties = undefined;
    officeData.newVehicles = undefined;
    officeData.newMotorcycles = undefined;
    officeData.properties = undefined;
    officeData.vehicles = undefined;
    officeData.office_type = undefined;
    officeData.approver = undefined;
    officeData.downloadbtn = undefined;
    officeData.assets = undefined;
    officeData.newAssets = undefined;

    const oldOfficeData = originalOfficeRecord;
    oldOfficeData.newProperties = undefined;
    oldOfficeData.newVehicles = undefined;
    oldOfficeData.newMotorcycles = undefined;
    oldOfficeData.properties = undefined;
    oldOfficeData.vehicles = undefined;
    oldOfficeData.office_type = undefined;
    oldOfficeData.approver = undefined;
    oldOfficeData.assets = undefined;
    oldOfficeData.newAssets = undefined;

    // check if there is a change in the office data before posting
    if (JSON.stringify(officeData) !== JSON.stringify(oldOfficeData))
      yield call(updateOffice, { office_id, data: officeData });

    //call download method if it's download
    if (data.downloadbtn) yield call(downloadOffice, { id: data.id });

    const respdata = "Success";
    yield put(Creators.updateOfficeRecordsSuccess(respdata));
    message.success(" Changes have been submitted for review");
    history.push("/locations");
  } catch (e) {
    console.log(e);
    const error = " An error occurred";
    yield put(Creators.updateOfficeRecordsFailure(error));
  }
}

export function* addProperties(actions) {
  try {
    const { office_id, data } = actions;
    console.log(data);
    const response = yield call(addLocationProperties, office_id, data);
    const respdata = response.data;
    if (response.status === 200 || response.status === 201) {
      console.log(respdata);
      yield put(Creators.addPropertiesSuccess(respdata));
      // message.success("Properties data has been submitted for review.");
    }
  } catch (e) {
    var err = { e }.e.response;

    if (err.data && err.data.detail) {
      message.error(err.data.detail);
    } else {
      message.error("Error occurred while creating property.");
    }
    yield put(Creators.addPropertiesFailure(e));
  }
}

export function* declineRevision(actions) {
  try {
    const { office_id, revision_id } = actions;
    const response = yield call(declineRevisionApi, office_id, revision_id);
    const respdata = response.data;

    console.log(respdata);
    yield put(Creators.declineRevisionSuccess(respdata));
    yield call(fetchOfficeRevisions, "");
    message.success("Revision declined.");
  } catch (e) {
    yield put(Creators.declineRevisionFailure(e));
    message.error("Error occurred while updating record.");
  }
}

export function* approveRevision(actions) {
  try {
    const { office_id, revision_id } = actions;
    const response = yield call(approveRevisionApi, office_id, revision_id);
    const respdata = response.data;
    if (response.status === 200 || response.status === 201) {
      console.log(respdata);
      yield put(Creators.approveRevisionSuccess(respdata));
      yield call(fetchOfficeRevisions, "");
      message.success("Revision accepted");
    }
  } catch (e) {
    yield put(Creators.approveRevisionFailure(e));
    message.error("Error occurred while updating record.");
  }
}

export function* updateProperties(actions) {
  try {
    const { office_id, data, property_id } = actions;
    console.log(data);
    const response = yield call(
      updateLocationProperties,
      office_id,
      data,
      property_id
    );
    const respdata = response.data;
    if (response.status === 200 || response.status === 201) {
      console.log(respdata);
      yield put(Creators.updatePropertiesSuccess(respdata));
      // message.success("Properties data has been submitted for review.");
    }
  } catch (e) {
    var err = { e }.e.response;

    if (err.data && err.data.detail) {
      message.error(err.data.detail);
    } else {
      message.error("Error occurred while updating property.");
    }
    yield put(Creators.updatePropertiesFailure(e));
  }
}

export function* deleteProperty(actions) {
  try {
    const { office_id, property_id } = actions;
    const response = yield call(
      deleteLocationProperties,
      office_id,
      property_id
    );
    const respdata = response.data;

    console.log(respdata);
    yield put(Creators.deletePropertySuccess(respdata));
    yield call(fetchOffice, { office_id });
    message.success("Change has been submitted for review");
  } catch (e) {
    var err = { e }.e.response;

    if (err.data && err.data.detail) {
      message.error(err.data.detail);
    } else {
      message.error("Error occurred while deleting record.");
    }
    yield put(Creators.deletePropertyFailure(e));
  }
}

export function* addVehicles(actions) {
  try {
    const { office_id, data } = actions;
    const response = yield call(addLocationVehicles, office_id, data);
    const respdata = response.data;
    if (response.status === 200 || response.status === 201) {
      console.log(respdata);
      yield put(Creators.addVehiclesSuccess(respdata));
      // history.push("/locations");
      //message.success("Vehicles data has been submitted for review.");
    }
  } catch (e) {
    var err = { e }.e.response;

    if (err.data && err.data.detail) {
      message.error(err.data.detail);
    } else {
      message.error("Error occurred while creating vehicles.");
    }
    yield put(Creators.addVehiclesFailure(e));
  }
}

export function* updateVehicles(actions) {
  try {
    const { office_id, data, vehicle_id } = actions;
    const response = yield call(
      updateLocationVehicles,
      office_id,
      data,
      vehicle_id
    );
    const respdata = response.data;
    if (response.status === 200 || response.status === 201) {
      console.log(respdata);
      yield put(Creators.updateVehiclesSuccess(respdata));
      // history.push("/locations");
      // message.success("Vehicles data has been submitted for review.");
    }
  } catch (e) {
    var err = { e }.e.response;

    if (err.data && err.data.detail) {
      message.error(err.data.detail);
    } else {
      message.error("Error occurred while updating vehicles.");
    }
    yield put(Creators.updateVehiclesFailure(e));
  }
}

export function* deleteVehicle(actions) {
  try {
    const { office_id, vehicle_id } = actions;
    const response = yield call(deleteLocationVehicles, office_id, vehicle_id);
    const respdata = response.data;

    console.log(respdata);
    yield put(Creators.deleteVehicleSuccess(respdata));
    yield call(fetchOffice, { office_id });
    message.success("Change has been submitted for review");
  } catch (e) {
    var err = { e }.e.response;

    if (err.data && err.data.detail) {
      message.error(err.data.detail);
    } else {
      message.error("Error occurred while deleting record.");
    }
    yield put(Creators.deleteVehicleFailure(e));
  }
}

export function* fetchLocationTypes() {
  try {
    const response = yield call(getLocationTypes);
    const data = response.data;
    if (response.status === 200 || response.status === 201) {
      console.log(data);
      yield put(Creators.getLocationTypesSuccess(data));
    }
  } catch (e) {
    const message = "Error fetching records.";
    yield put(Creators.getLocationTypesFailure(message));
  }
}

export function* getVehicleConditions() {
  try {
    const response = yield call(getVehicleCondition);
    const data = response.data;
    if (response.status === 200 || response.status === 201) {
      console.log(data);
      yield put(Creators.getVehicleConditionSuccess(data));
    }
  } catch (e) {
    const message = "Error fetching records.";
    yield put(Creators.getVehicleConditionFailure(message));
  }
}

export function* fetchPropertyStatuses() {
  try {
    const response = yield call(getPropertyOwnershipStatuses);
    const data = response.data;
    if (response.status === 200 || response.status === 201) {
      console.log(data);
      yield put(Creators.getPropertyStatusesSuccess(data));
    }
  } catch (e) {
    const message = "Error fetching records.";
    yield put(Creators.getPropertyStatusesFailure(message));
  }
}

export function* fetchVehicleStatuses() {
  try {
    const response = yield call(getVehicleOwnershipStatuses);
    const data = response.data;
    if (response.status === 200 || response.status === 201) {
      console.log(data);
      yield put(Creators.getVehicleStatusesSuccess(data));
    }
  } catch (e) {
    const message = "Error fetching records.";
    yield put(Creators.getVehicleStatusesFailure(message));
  }
}

export function* fetchAssetTypesSaga() {
  try {
    console.log("fetchAssetTypesSaga fetching AssetTypes records.");
    const response = yield call(getAssetTypesApi);
    const data = response.data;
    if (response.status === 200 || response.status === 201) {
      console.log(data);
      yield put(Creators.getAssetTypesSuccess(data));
    }
  } catch (e) {
    const message = "Error fetch AssetTypes records.";
    yield put(Creators.getAssetTypesFailure(message));
  }
}

export function* addAssets(actions) {
  try {
    const { office_id, data } = actions;
    const response = yield call(addAssetsApi, office_id, data);
    const respdata = response.data;
    if (response.status === 200 || response.status === 201) {
      console.log(respdata);
      yield put(Creators.addAssetsSuccess(respdata));
    }
  } catch (e) {
    var err = { e }.e.response;

    if (err.data && err.data.detail) {
      message.error(err.data.detail);
    } else {
      message.error("Error occurred while creating OfficeAssets.");
    }
    yield put(Creators.addAssetsFailure(e));
  }
}

export function* updateAssets(actions) {
  try {
    const { office_id, data, asset_id } = actions;
    const response = yield call(updateAssetsApi, office_id, data, asset_id);
    const respdata = response.data;
    if (response.status === 200 || response.status === 201) {
      console.log(respdata);
      yield put(Creators.updateAssetsSuccess(respdata));
    }
  } catch (e) {
    var err = { e }.e.response;

    if (err.data && err.data.detail) {
      message.error(err.data.detail);
    } else {
      message.error("Error occurred while updating assets.");
    }
    yield put(Creators.updateAssetsFailure(e));
  }
}

export function* deleteAsset(actions) {
  try {
    const { office_id, asset_id } = actions;
    const response = yield call(deleteAssetApi, office_id, asset_id);
    const respdata = response.data;
    yield put(Creators.deleteAssetSuccess(respdata));
    yield call(fetchOffice, { office_id });
    message.success("Change has been submitted for review");
  } catch (e) {
    var err = { e }.e.response;

    if (err.data && err.data.detail) {
      message.error(err.data.detail);
    } else {
      message.error("Error occurred while deleting record.");
    }
    yield put(Creators.deleteAssetFailure(e));
  }
}

export function* watchGetOffices() {
  yield takeEvery(Types.GET_OFFICES, fetchOffices);
}

export function* watchGetChildrenOffices() {
  yield takeEvery(Types.GET_CHILDREN_OFFICES, fetchChildrenOffices);
}

export function* watchGetOffice() {
  yield takeEvery(Types.GET_OFFICE, fetchOffice);
}

export function* watchDownloadOffice() {
  yield takeEvery(Types.DOWNLOAD_OFFICE, downloadOffice);
}

export function* watchUpdateRecords() {
  yield takeEvery(Types.UPDATE_OFFICE_RECORDS, updateOfficeRecords);
}

export function* watchFetchOfficeRevisions() {
  yield takeEvery(Types.GET_OFFICE_REVISIONS, fetchOfficeRevisions);
}

export function* watchLocationTypes() {
  yield takeEvery(Types.GET_LOCATION_TYPES, fetchLocationTypes);
}

export function* watchVehicleConditions() {
  yield takeEvery(Types.GET_VEHICLE_CONDITION, getVehicleConditions);
}

export function* watchPropertyStatuses() {
  yield takeEvery(Types.GET_PROPERTY_STATUSES, fetchPropertyStatuses);
}

export function* watchVehicleStatuses() {
  yield takeEvery(Types.GET_VEHICLE_STATUSES, fetchVehicleStatuses);
}

export function* watchUpdateOffice() {
  yield takeEvery(Types.UPDATE_OFFICE, updateOffice);
}

export function* watchAddProperties() {
  yield takeEvery(Types.ADD_PROPERTIES, addProperties);
}

export function* watchUpdateProperties() {
  yield takeEvery(Types.UPDATE_PROPERTIES, updateProperties);
}

export function* watchDeleteProperties() {
  yield takeEvery(Types.DELETE_PROPERTY, deleteProperty);
}

export function* watchAddVehicles() {
  yield takeEvery(Types.ADD_VEHICLES, addVehicles);
}

export function* watchUpdateVehicle() {
  yield takeEvery(Types.UPDATE_VEHICLES, updateVehicles);
}

export function* watchDeleteVehicles() {
  yield takeEvery(Types.DELETE_VEHICLE, deleteVehicle);
}

export function* watchApproveRevision() {
  yield takeEvery(Types.APPROVE_REVISION, approveRevision);
}

export function* watchDeclineRevision() {
  yield takeEvery(Types.DECLINE_REVISION, declineRevision);
}

export function* watchGetAssetTypes() {
  yield takeEvery(Types.GET_ASSET_TYPES, fetchAssetTypesSaga);
}

export function* watchAddAssets() {
  yield takeEvery(Types.ADD_ASSETS, addAssets);
}

export function* watchUpdateAssets() {
  yield takeEvery(Types.UPDATE_ASSETS, updateAssets);
}

export function* watchDeleteAsset() {
  yield takeEvery(Types.DELETE_ASSET, deleteAsset);
}
