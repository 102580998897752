import { radio_field_names } from "./form-field-options";
import { useEffect, useRef } from "react";
import store from "../../store";
import get from "lodash/get";
import moment from "moment";

export const getMapValue = (obj, key) => {
  if (obj.hasOwnProperty(key)) return obj[key];
};

export const processData = (values, state) => {
  const categories = state && state.categories;

  if (categories && categories.length > 0) {
    categories.forEach((field) => {
      const modifiedCategory = field.option.split(" ").join("_");
      values[`${modifiedCategory}_check_box`] = false;
      values[`${modifiedCategory}`] = "";
      if (field.option === (values.category && values.category.type)) {
        values[`${modifiedCategory}`] =
          values.category && values.category.option;
        values[`${modifiedCategory}_check_box`] = true;
      }
    });
  }

  // values.incident_date = values.date_occured
  values.incident_date = moment(values.date_occured).format("YYYY-MM-DD");
  values.incident_time = moment(values.date_occured).utc().format("HH:mm:ss");
  // values.incident_time = values.date_occured.split('T')[1].split('Z')[0]
  console.log(
    "values.date_occured.split('T')[1].split('Z')[0]",
    values.date_occured.split("T")[1].split("Z")[0]
  );
  values._date = values.date_occured;
  values.country = values.location && values.location.country;
  values.province = values.location && values.location.province;
  values.city = values.location && values.location.city;

  let coordinates = {
    lat: values.location && values.location.latitude,
    lng: values.location && values.location.longitude,
  };
  values.coordinates = { ...coordinates };

  values.injuredPc_international_staff =
    values.incident_impact &&
    values.incident_impact.injuredPc.international_stuff;
  values.injuredPsy_international_staff =
    values.incident_impact &&
    values.incident_impact.injuredPsy.international_stuff;
  values.fatalities_international_staff =
    values.incident_impact &&
    values.incident_impact.fatalities.international_stuff;
  values.kidnapped_international_staff =
    values.incident_impact &&
    values.incident_impact.kidnapped.international_stuff;
  values.injuredPc_national_staff =
    values.incident_impact && values.incident_impact.injuredPc.national_stuff;
  values.injuredPsy_national_staff =
    values.incident_impact && values.incident_impact.injuredPsy.national_stuff;
  values.fatalities_national_staff =
    values.incident_impact && values.incident_impact.fatalities.national_stuff;
  values.kidnapped_national_staff =
    values.incident_impact && values.incident_impact.kidnapped.national_stuff;
  values.injuredPc_other_persons =
    values.incident_impact && values.incident_impact.injuredPc.other;
  values.injuredPsy_other_persons =
    values.incident_impact && values.incident_impact.injuredPsy.other;
  values.fatalities_other_persons =
    values.incident_impact && values.incident_impact.fatalities.other;
  values.kidnapped_other_persons =
    values.incident_impact && values.incident_impact.kidnapped.other;

  values.estimated_cost = parseFloat(values.estimated_cost).toFixed(2);

  // delete values.incident_impact

  console.log("values in processData are ", values);

  return values;
};

export const isIsoDate = (str) => {
  if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return true;
  if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/.test(str)) return true;
  // var d = new Date(str);
  // return d.toISOString()===str;
  return false;
};

export const convertToIsoString = (str) => {
  if (str) {
    const splits = str.split(" ");
    return splits[0] + "T" + splits[1] + "Z";
  }
  return null;
};

const deleteKeys = (obj, key) => {
  if (obj.hasOwnProperty(key)) {
    delete obj[key];
  }
  return obj;
};

export const cleanData = (obj) => {
  let cleanedObj = null;
  const keysArray = [
    "incident_date",
    "incident_time",
    "_date",
    "country",
    "province",
    "city",
    "coordinates",
    "injuredPc_international_staff",
    "injuredPsy_international_staff",
    "fatalities_international_staff",
    "kidnapped_international_staff",
    "injuredPc_national_staff",
    "injuredPsy_national_staff",
    "fatalities_national_staff",
    "kidnapped_national_staff",
    "injuredPc_other_persons",
    "injuredPsy_other_persons",
    "fatalities_other_persons",
    "kidnapped_other_persons",
  ];

  keysArray.forEach((key) => {
    cleanedObj = deleteKeys(obj, key);
  });

  console.log("obj are ", obj);
  console.log("radio_field_names are ", radio_field_names);

  const state = store.getState();
  const { categories } = state.incidents;
  console.log("categories are ", categories);

  radio_field_names.forEach((field) => {
    cleanedObj = deleteKeys(obj, `${field.key}_check_box`);
    cleanedObj = deleteKeys(obj, `${field.key}`);
  });
  console.log("cleanedObj after are ", cleanedObj);
  return cleanedObj;
};

export const uppercaseFirstLetter = (str) => {
  if (str) return str.charAt(0).toUpperCase() + str.slice(1);
  return null;
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const getImpactCount = (values) => {
  let count = 0;
  count += get(values, "injuredPc_international_staff");
  count += get(values, "injuredPc_national_staff");
  count += get(values, "injuredPc_other_persons");
  count += get(values, "injuredPsy_international_staff");
  count += get(values, "injuredPsy_national_staff");
  count += get(values, "injuredPsy_other_persons");
  count += get(values, "fatalities_international_staff");
  count += get(values, "fatalities_national_staff");
  count += get(values, "fatalities_other_persons");
  count += get(values, "kidnapped_international_staff");
  count += get(values, "kidnapped_national_staff");
  count += get(values, "kidnapped_other_persons");
  console.log("count is ", count);
  return count;
};

export const formatAmountWithSpaces = (value) => {
  // if value ends with dot then do nothing otherwise format
  if (/\.$/.test(value)) {
    return;
  }
  const parseNumber = parseFloat(value.replace(/[^0-9.]/g, ""));
  const toLocale = parseNumber
    .toLocaleString("en-US", { maximumFractionDigits: 2 })
    .replace(/,/g, " ");
  return toLocale;
};
export const downloadBlob = (blob, filename) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename || "download";
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener("click", clickHandler);
    }, 150);
  };
  a.addEventListener("click", clickHandler, false);
  a.click();
  a.remove();
};

export const getFileNameFromResponse = (response) => {
  const contentDisposition = response.headers["content-disposition"];
  if (contentDisposition) {
    const matches = contentDisposition
      .split(";")
      .find((n) => n.includes("filename="))
      .replace("filename=", "")
      .trim();
    if (matches && matches.length > 1) {
      return matches;
    }
  }
  return null;
};
