import React from "react";
import { Icon, Row, Col, Button } from "antd";
import logo from "../../../assets/web/logo_Desktop.svg";
import  "../../../styles/header.less";
import { Link} from 'react-router-dom';
import Can from '../../../util/helpers/Can'
import { getUserRole } from '../../../services/api/axiosDefaults';

class MobileHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            overlay: 'overlay'
        }
    }
    
    toggleOverlay = () => {
        if (this.state.overlay === 'overlay') {
            this.setState({overlay : 'overlay open'});
        } else {
            this.setState({overlay : 'overlay'});
        }
    }

    render() {
        const { overlay } = this.state;
        const { loggedInUser, showLoginModal,logout, downloadHelpFile, editProfile } = this.props;

         //get user role from localstorage
         const role = getUserRole();

        return (
            <>
                
                <Row id="lowerheader" className="mobile-header">
                    <Col span={9} id="logo">
                        <Link to="/">
                        <img src={logo} alt="logo" />
                        </Link>
                    </Col>
                    
                <Col span={13} className="mobile-right-col">

                {loggedInUser && 
                <Link to="/add/incident">
                    <Button  className="orange_buttons">Report Incident</Button>
                    </Link> }
                <Icon type="bars" className="harmburger" onClick={this.toggleOverlay}/>
                <div id="myNav" className={overlay}>
                    {!loggedInUser ? (
                            <div className="overlay-content">
                               <ul>
                                   <li>
                                       <hr/>
                                       <Link to="/" onClick={this.toggleOverlay}>Home</Link> {/* close overlay on click of every link */}
                                   </li>
                                   <li>
                                       <Link to="/" onClick={() => {
                                           this.toggleOverlay();
                                           downloadHelpFile()
                                           }}>How SIMSon Works</Link>
                                   </li>
                                   <li>
                                       <Link to="/" className="orange-text" onClick={() => {
                                           this.toggleOverlay();
                                           showLoginModal();
                                       }}>Login</Link>
                                   </li>
                               </ul>
                            </div>
                        ) :
                        (
                            <div className="overlay-content" id="logged-in-menu">
                                <ul>
                                    <li>
                                        <Link to="/organizations" className="orange-text" onClick={this.toggleOverlay}>My Organization</Link>
                                        <hr/>
                                    </li>
                                    <li>
                                        <Link to="/organizations" onClick={this.toggleOverlay}>Care Organization</Link>
                                    </li>
                                    <Can
                                                role={role}
                                                perform="location:list"
                                                yes={() => (
                                                    <li>
                                                        <Link to="/locations" onClick={this.toggleOverlay}>Care Offices</Link>
                                                    </li>
                                                )}
                                                no={() => <li hidden="true"></li>}
                                            /> 

                                        <Can
                                                role={role}
                                                perform="location:revision"
                                                yes={() => (
                                                    <li>
                                                        <Link to="/revisions" onClick={this.toggleOverlay}>Office Revisions</Link>
                                                    </li>
                                                )}
                                                no={() => <li hidden="true"></li>}
                                            /> 
                                   
                                    <li>
                                        <Link to="/" className="orange-text">Reports</Link>
                                        <hr/>
                                    </li>
                                    <li>
                                        <Link to="/incidents" onClick={this.toggleOverlay}>Incident Reports</Link>
                                    </li>
                                    {role.includes("approver")&& <li>
                                        <Link to="/incidents/pending-approval" onClick={this.toggleOverlay}>Incident Approval</Link>
                                    </li>}
                                    
                                    <li>
                                        <Link to="/drafts" onClick={this.toggleOverlay}>My Drafts</Link>
                                    </li>
                                    <Can
                                                role={role}
                                                perform="insurance:manage"
                                                yes={() => (
                                                    <li>
                                                    <   Link to="/reports/insurance-reports" onClick={this.toggleOverlay}>Insurance Reports</Link>
                                                    </li>
                                                )}
                                                no={() => <li hidden={true}></li>}
                                            /> 
                                    <li>
                                       <Link to="/" onClick={() => {
                                           this.toggleOverlay();
                                           downloadHelpFile()
                                           }}>How SIMSon Works</Link>
                                   </li>
                                   
                                    <li>
                                        <Link to="/" onClick={this.toggleOverlay}>Report Analysis</Link>
                                    </li>
                                    <li>
                                        <hr/>
                                        <Link to="/" onClick={(e) => {
                                            this.toggleOverlay();
                                            editProfile(e);
                                        }}>
                                        Profile Edit</Link>
                                    </li>
                                    {/* <li>
                                        <Link to="/" onClick={this.toggleOverlay}>Settings</Link>
                                    </li> */}
                                    <li>
                                        <Link to="/" onClick={logout}>Logout</Link>
                                    </li>
                                </ul>
                            </div>
                        )
                    }
                        </div>  
                        </Col>
                        
                    </Row>
                </>
      );
    }
}


export default MobileHeader;