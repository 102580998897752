import React from "react";
import { Form, Input, InputNumber } from "antd";
import { Formik, Field } from "formik";
import { getMapValue } from "../../util/helpers/reusable-functions";

const FormItem = Form.Item;

export const InputField = ({
  label,
  field,
  form: { touched, errors },
  ...props
}) => {
  let errorMsg = errors[field.name];

  const { formerrors } = props;
  const errorsFound =
    touched &&
    formerrors &&
    formerrors.hasOwnProperty(field.name) &&
    getMapValue(formerrors, field.name).map((err) => {
      return err;
    });
  if (errorsFound) {
    errorMsg = errorsFound;
  }
  // console.log("field MinhVN:", field);
  const limitToTwodecimalPlaces = (e) => {
    var decimal_index = e.target.value.indexOf(".");
    if (decimal_index > -1) {
      var decimals = e.target.value.substring(
        decimal_index,
        e.target.value.length + 1
      );
      if (decimals.length > 2 && e.keyCode !== 8) {
        e.preventDefault();
      }
    }
  };
  if (
    (props.type === "number" || props.type === "claims-number") &&
    field &&
    field.value &&
    typeof field.value === "string"
  ) {
    field.value = field.value.replace(/\s+/g, "");
  }
  return (
    <FormItem
      label={label}
      validateStatus={errorMsg ? "error" : ""}
      help={errorMsg}
    >
      {props.type === "claims-number" ? (
        <InputNumber
          {...field}
          {...props}
          step={0.1}
          onChange={(e) => {
            e = {
              target: {
                value: e,
                name: "claim_settlements.${index}.claim_amount",
              },
            };
            field.onChange(e);
            props.onChange && props.onChange(e);
          }}
          onKeyDown={(e) => limitToTwodecimalPlaces(e)}
          style={{ ...(props.styles || props.style) }}
          onBlur={(e) => props.onBlur && props.onBlur(e)}
        />
      ) : (
        <Input
          {...field}
          {...props}
          type={props.type ? props.type : "text"}
          onInput={
            props.type === "number"
              ? (e) => (e.target.value = Math.abs(e.target.value))
              : undefined
          }
          step={
            props.type === "number"
              ? props.steps
                ? props.steps
                : ".01"
              : undefined
          }
          onKeyDown={
            props.type === "number"
              ? (e) => limitToTwodecimalPlaces(e)
              : undefined
          }
          onChange={(e) => {
            field.onChange(e);
            props.onChange && props.onChange(e);
          }}
          style={{ ...(props.styles || props.style) }}
        />
      )}
    </FormItem>
  );
};
