import { createReducer } from 'reduxsauce'
import Types from './actionTypes'
 
export const INITIAL_STATE = 
  { 
    error: false, 
    user: null, 
    loginloading: false, 
    loginmessage: '',
    resetloading: false,
    resetmessage: '',
    changeloading: false,
    changemessage: '',
    simsonHelpFile: null,
    helpFileMessage: '',
    helpFileLoading: false
  }

export const login = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, user: action.user, loginloading: true, loginmessage: '' }
}
 
export const loginSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, user: action.responseData, loginloading: false }
}
 
export const loginFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, user: null, loginloading: false, loginmessage: action.error }
}

export const reset = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, user: action.user, resetloading: true, resetmessage: '' }
}
 
export const resetSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, user: null, resetmessage: action.responseData, resetloading: false }
}
 
export const resetFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, user: null, resetloading: false, resetmessage: action.error }
}

export const change = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, user: action.user, changeloading: true, changemessage: '' }
}
 
export const changeSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, user: null, changemessage: action.responseData, changeloading: false }
}
 
export const changeFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, user: null, changeloading: false, changemessage: action.error }
}


export const getHelpFile = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, helpFileLoading: true, helpFileMessage: '', simsonHelpFile: null }
}
 
export const getHelpFileSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, simsonHelpFile: action.responseData, helpFileLoading: false }
}
 
export const getHelpFileFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, helpFileLoading: false, helpFileMessage: action.error }
}

export const logout = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, user: null}
}

export const HANDLERS = {
  [Types.LOGIN_REQUEST]: login,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,
  [Types.RESET_PASSWORD_REQUEST]: reset,
  [Types.RESET_PASSWORD_SUCCESS]: resetSuccess,
  [Types.RESET_PASSWORD_FAILURE]: resetFailure,
  [Types.CHANGE_PASSWORD_REQUEST]: change,
  [Types.CHANGE_PASSWORD_SUCCESS]: changeSuccess,
  [Types.CHANGE_PASSWORD_FAILURE]: changeFailure,
  [Types.GET_HELP_FILE]: getHelpFile,
  [Types.GET_HELP_FILE_SUCCESS]: getHelpFileSuccess,
  [Types.GET_HELP_FILE_FAILURE]: getHelpFileFailure,
  [Types.LOGOUT]: logout
}
 
export default createReducer(INITIAL_STATE, HANDLERS);
