import React from 'react';
import { Form, Modal } from 'antd';
import { withFormik, Field } from 'formik';
import { validationSchemaResetPwd } from '../../../util/validations';
import { InputField } from '../../../reusable-components/InputField';
import "../../../styles/modals.less";
import { connect } from 'react-redux';
import { Creators } from '../../../services/redux/auth/actions';
import Spinner from "../../../reusable-components/Spinner";
import Notify from "../../../reusable-components/Notify";
import ReCAPTCHA from "react-google-recaptcha";
import { captchaSiteKeys, urls } from "../../../util/helpers/enum";


const FormItem = Form.Item;

class RequestPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      captchaToken: ''
    }
  }

   onChange(value) {
    console.log("Captcha value:", value);
    this.setState({captchaToken: value});
  }

    render() {
      const {
        handleSubmit,
        showModal,
        onCancel,
        auth,
      } = this.props;
    
      console.log(urls)
      
      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 24 },
          md: { span: 24}
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 24 },
          md: { span: 24}
        },
      };

        return (
            <Modal
            className="modal"
            title = ""
            visible={showModal}
            onCancel={onCancel}
            footer={
                <>
                    <p key="lower-text" 
                    className="footer-lower-text" 
                    style={{marginTop: "15px"}}
                    onClick={onCancel}>
                         Back to Login </p>
                 </>   
              }
          >
            <Form onSubmit={handleSubmit} {...formItemLayout}>
                <p className="modal-header">Request New Password</p>
                
                <div id="form-container">

                  <div className="subtitle-bar">
                      <p id="subtitle">Enter your SIMSon account email to receive a link allowing you to reset your password</p>
                    </div>

                     {/* Notify the user of any issues */}
                 {auth.resetmessage && 
                  <Notify message={auth.resetmessage} variant={auth.error? "error" : "success"} /> }

                    <FormItem>
                        <Field
                        name='email'
                        placeholder="Username/Email"
                        component={InputField}
                        className="input-field"
                        />
                    </FormItem>
                   
                    <FormItem>
                        <Field
                        name='captchaToken'
                        render={({ field, form: { touched, errors } }) => (
                          <>
                          <ReCAPTCHA
                            sitekey={window.location.origin !== urls.prod ? captchaSiteKeys.prod : captchaSiteKeys.testing}
                            onChange={(value) => field.onChange( {
                              target: {
                              value,
                              name: 'captchaToken'
                            }}
                            )}
                          /> 
                          {errors[field.name] && <div style={{color: "red"}}>{errors[field.name]}</div>}
                         </>
                        )
                      }
                        className="input-field"
                        />
                    </FormItem>
                               
                    <FormItem>
                    {!auth.resetloading ? 
                        <button type="submit">Request Password</button> :
                        <Spinner /> }
                    </FormItem>
                </div>
            </Form> 
          </Modal>
        );
      }
}

const RequestPasswordComponent = withFormik({
    validationSchema: validationSchemaResetPwd,
     
    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(true);
        console.log(values);
        props.resetPassword(values.email, values.captchaToken);
    },
  
    displayName: 'RequestPasswordComponent',
  })(RequestPassword);
  
  const mapStateToProps = state => {
    return {
      auth: state.auth,
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
      resetPassword: (email, captchaToken) => {
        dispatch(Creators.resetPasswordRequest(email, captchaToken));
      },
    };
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(RequestPasswordComponent);