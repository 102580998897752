import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
  {
    getOffices: ["page", "search"],
    getOfficesSuccess: ["responseData"],
    getOfficesFailure: ["error"],

    getChildrenOffices: null,
    getChildrenOfficesSuccess: ["responseData"],
    getChildrenOfficesFailure: ["error"],

    getOffice: ["office_id"],
    getOfficeSuccess: ["responseData"],
    getOfficeFailure: ["error"],

    downloadOffice: ["id"],
    downloadOfficeSuccess: ["responseData"],
    downloadOfficeFailure: ["error"],

    getOfficeRevisions: ["page"],
    getOfficeRevisionsSuccess: ["responseData"],
    getOfficeRevisionsFailure: ["error"],

    approveRevision: ["office_id", "revision_id"],
    approveRevisionSuccess: ["responseData"],
    approveRevisionFailure: ["error"],

    declineRevision: ["office_id", "revision_id"],
    declineRevisionSuccess: ["responseData"],
    declineRevisionFailure: ["error"],

    updateOffice: ["office_id", "data"],
    updateOfficeSuccess: ["responseData"],
    updateOfficeFailure: ["error"],

    addProperties: ["office_id", "data"],
    addPropertiesSuccess: ["responseData"],
    addPropertiesFailure: ["error"],

    updateProperties: ["office_id", "data", "property_id"],
    updatePropertiesSuccess: ["responseData"],
    updatePropertiesFailure: ["error"],

    deleteProperty: ["office_id", "property_id"],
    deletePropertySuccess: ["responseData"],
    deletePropertyFailure: ["error"],

    addVehicles: ["office_id", "data"],
    addVehiclesSuccess: ["responseData"],
    addVehiclesFailure: ["error"],

    updateVehicles: ["office_id", "data", "vehicle_id"],
    updateVehiclesSuccess: ["responseData"],
    updateVehiclesFailure: ["error"],

    deleteVehicle: ["office_id", "vehicle_id"],
    deleteVehicleSuccess: ["responseData"],
    deleteVehicleFailure: ["error"],

    getLocationTypes: null,
    getLocationTypesSuccess: ["responseData"],
    getLocationTypesFailure: ["error"],

    getPropertyStatuses: null,
    getPropertyStatusesSuccess: ["responseData"],
    getPropertyStatusesFailure: ["error"],

    getVehicleCondition: null,
    getVehicleConditionSuccess: ["responseData"],
    getVehicleConditionFailure: ["error"],

    getVehicleStatuses: null,
    getVehicleStatusesSuccess: ["responseData"],
    getVehicleStatusesFailure: ["error"],

    updateOfficeRecords: ["office_id", "data", "originalOfficeRecord"],
    updateOfficeRecordsSuccess: ["responseData"],
    updateOfficeRecordsFailure: ["error"],

    getAssetTypes: null,
    getAssetTypesSuccess: ["responseData"],
    getAssetTypesFailure: ["error"],

    addAssets: ["office_id", "data"],
    addAssetsSuccess: ["responseData"],
    addAssetsFailure: ["error"],

    updateAssets: ["office_id", "data", "asset_id"],
    updateAssetsSuccess: ["responseData"],
    updateAssetsFailure: ["error"],

    deleteAsset: ["office_id", "asset_id"],
    deleteAssetSuccess: ["responseData"],
    deleteAssetFailure: ["error"],

    nextPage: ["data", "nextStep"],
    previousPage: ["step"],
    updateLocationData: ["data"],
    resetLocationData: null,
  },
  {}
);
