import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { Creators } from '../../services/redux/auth/actions';
import WelcomePage from './components/WelcomePage';
import React from "react";
import checkAuth  from '../../util/helpers/checkAuth';

class Index extends React.Component {
state = {
  isAuth: checkAuth()
};
// this is to ensure that the map page becomes the landing page once you are logged in
  render() {
    if (this.state.isAuth) {
      return <Redirect to={{ pathname: "/home" }} />
    } 
    return (
      <WelcomePage {...this.props}/>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loginUser: (username, password) => {
      dispatch(Creators.loginRequest(username, password));
    },
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Index)
);
