import { createActions } from 'reduxsauce'
 
export const { Types, Creators } = createActions({
  getRoles: null,
  getRolesSuccess: ['responseData'],
  getRolesFailure: ['error'],

  createUser: ['data'],
  createUserSuccess: ['responseData'],
  createUserFailure: ['error'],

  getUser: ['user_id'],
  getUserSuccess: ['responseData'],
  getUserFailure: ['error'],

  updateUser: ['user_id', 'data'],
  updateUserSuccess: ['responseData'],
  updateUserFailure: ['error'],

  resetNewAccount: ['email'],
  resetNewAccountSuccess: ['responseData'],
  resetNewAccountFailure: ['error'],

  getOrganization: null,
  getOrganizationSuccess: ['responseData'],
  getOrganizationFailure: ['error']
}, {});
