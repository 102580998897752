import * as yup from 'yup';
import { Field } from 'formik';
import { getConditionsFailure } from '../../services/redux/locations/reducers';

export const emailNotLongEnough = 'Email must be atleast 3 characters';
export const passwordNotLongEnough = 'Password must be atleast 3 characters';
export const invalidEmail = 'Email must be a valid email';
export const fieldRequired = 'Field is required';
export const positiveNumber = 'Positive number is required';

// setup reusable yup fxns...
export const registerPasswordValidation = yup
	.string()
	.min(3, passwordNotLongEnough)
	.max(255)
	.required('Password is required')
	.nullable()

// you construct your schema for the current form
export const validationSchema = yup.object().shape({
	email: yup
		.string()
		.min(3, emailNotLongEnough)
		.max(255)
		.email(invalidEmail)
		.required()
		.nullable(),
	password: registerPasswordValidation
})

export const validationSchemaIncidentFormStepOne = yup.object().shape({
	reporter_ref_string: yup
		.string()
		.max(10),
	category: yup
		.string()
		.required(fieldRequired)
		.nullable(),
	incident_date: yup
		.string()
		.required(fieldRequired)
		.nullable(),
	incident_time: yup
		.string()
		.required(fieldRequired)
		.nullable(),
	country: yup
		.string()
		.required(fieldRequired)
		.nullable()
})

export const validationSchemaIncidentFormStepTwo = yup.object().shape({
	incident_date: yup
		.string()
		.required(fieldRequired)
		.nullable(),
	incident_time: yup
		.string()
		.required(fieldRequired)
		.nullable(),
	country: yup
		.string()
		.required(fieldRequired)
		.nullable()
})

export const validationSchemaIncidentFormStepThree = yup.object().shape({
	incident: yup.object().shape({
		significant_change_in_local_context: yup
			.string()
			.required(fieldRequired)
			.nullable(),
	}),
})

export const validationSchemaIncidentFormStepFour = yup.object().shape({
	incident_date: yup
		.string()
		.required(fieldRequired)
		.nullable(),
	incident_time: yup
		.string()
		.required(fieldRequired)
		.nullable(),
	country: yup
		.string()
		.required(fieldRequired)
		.nullable()
})

// you construct your schema for the current form
export const validationSchemaResetPwd = yup.object().shape({
	email: yup
		.string()
		.min(3, emailNotLongEnough)
		.max(255)
		// .email(invalidEmail)
		.required('Email/Username field is required')
		.nullable(),
	captchaToken: yup
				.string()
				.required('Captcha is required')
				.nullable(),
})

export const validationSchemaChangePwd = yup.object({
	password: yup
			.string()
			.required('Password is required')
			.nullable(),
	confirmPassword: yup
			.string()
			.required('Confirm Password is required')
			   .oneOf([yup.ref('password'), null], 'Passwords do not match')
			   .nullable(),
  });


  export const validationSchemaNewUser = yup.object({
	// name: yup
	// 		.string()
	// 		.required('Account Name is required'),
	manager: yup
			.string()
			.required('Manager is required')
			.nullable(),
	email: yup
			.string()
			.email(invalidEmail)
			.required('Email is required')
			.nullable(),
	contact_person: yup
			.string()
			.required('Contact person is required')
			.nullable(),
	office: yup
			.string()
			.required('office is required')
			.nullable(),
  });

  export const validationSchemaNewLocationPageOne = yup.object({
	  // when vehicles_maintain_al is selected
	vehicles_maintain_al: yup.boolean(),
	vehicle_al_limit: yup.string().when('vehicles_maintain_al', {
		is: true,
		then: yup.string().required(fieldRequired)
	}).nullable(),
	vehicle_al_deductible: yup.string().when('vehicles_maintain_al', {
		is: true,
		then: yup.string().required(fieldRequired),
		otherwise: yup.string()
	}).nullable(),
	vehicle_al_currency: yup.string().when('vehicles_maintain_al', {
		is: true,
		then: yup.string().required(fieldRequired),
		otherwise: yup.string()
	}).nullable(),

	 // when vehicles_maintain_al is selected
	 vehicles_maintain_pd: yup.boolean(),
	 vehicle_pd_limit: yup.string().when('vehicles_maintain_pd', {
		is: true,
		then: yup.string().required(fieldRequired)
	}).nullable(),
	vehicle_pd_deductible: yup.string().when('vehicles_maintain_pd', {
		is: true,
		then: yup.string().required(fieldRequired)
	}).nullable(),
	vehicle_pd_currency: yup.string().when('vehicles_maintain_pd', {
		is: true,
		then: yup.string().required(fieldRequired)
	}).nullable(),
	

	// when property_has_insurance is selected
	property_has_insurance: yup.boolean(),
	property_insurance_limit: yup.string().when('property_has_insurance', {
		is: true,
		then: yup.string().required(fieldRequired)
	}).nullable(),
	property_insurance_deductible: yup.string().when('property_has_insurance', {
		is: true,
		then: yup.string().required(fieldRequired)
	}).nullable(),
	property_insurance_currency: yup.string().when('property_has_insurance', {
		is: true,
		then: yup.string().required(fieldRequired)
	}).nullable(),


	// when other_coverage_required is selected
	other_coverage_required:  yup.boolean(),
	other_coverage_specification: yup.string().when('other_coverage_required', {
		is: true,
		then: yup.string().required(fieldRequired)
	}).nullable(),

	// when ops_require_compensation is selected
	ops_require_compensation: yup.boolean(),
	ops_compensation_specification: yup.string().when('ops_require_compensation', {
		is: true,
		then: yup.string().required(fieldRequired)
	}).nullable(),

	// when opted_for_coverage_non_required_by_govt is selected
	opted_for_coverage_non_required_by_govt: yup.boolean(),
	coverage_non_required_by_govt_specification: yup.string().when('opted_for_coverage_non_required_by_govt', {
		is: true,
		then: yup.string().required(fieldRequired)
	}).nullable(),

  });
  
  export const validationSchemaNewLocationPageTwo = yup.object().shape({
	newVehicles: yup.array()
    .of(
      yup.object().shape({
        ownership_type: yup.string()
		  .required(fieldRequired)
		  .nullable(), 
		  year: yup.string()
		  .required(fieldRequired)
		  .nullable(),
		  original_procurement_date: yup.string()
		  .required(fieldRequired)
		  .nullable(),
		  original_procurement_value: yup.string()
		  .required(fieldRequired)
		  .nullable(),
		  current_deprecation_value: yup.string()
		  .required(fieldRequired)
		  .nullable(),
		  chassis_number: yup.string()
		  .nullable(),
	  })
	),
	newMotorcycles: yup.array()
    .of(
      yup.object().shape({
        ownership_type: yup.string()
		  .required(fieldRequired)
		  .nullable(),
		  year: yup.string()
		  .required(fieldRequired)
		  .nullable(),
		  original_procurement_date: yup.string()
		  .required(fieldRequired)
		  .nullable(),
		  original_procurement_value: yup.string()
		  .required(fieldRequired)
		  .nullable(),
		  current_deprecation_value: yup.string()
		  .required(fieldRequired)
		  .nullable(),
		  chassis_number: yup.string()
		  .nullable(),
	  })
	),
	  newProperties: yup.array()
	  .of(
		yup.object().shape({
			real_property: yup.string().when('ownership_status', {
				is: 'owned',
				then: yup.number().integer().min(0).required(fieldRequired) //.positive().integer()
			}).nullable(),
			// personal_property: yup.number().integer()
			// .required(fieldRequired)
			// .nullable(),
			total_properties: yup.number().integer().min(0)
			.required(fieldRequired)
			//.positive().integer()
			.nullable(),
			ownership_status: yup.string()
			.required(fieldRequired)
			.nullable(),
			location_type: yup.string()
			.required(fieldRequired)
			.nullable(),
		})
	)
	// country: yup
	// 		.string()
	// 		.required('Country is required'),
	// city: yup
	// 		.string()
	// 		.required('City is required'),
	// street: yup
	// 		.string()
	// 		.required('City is required')
  });

  export const validationSchemaNewInsurance = yup.object({
	claim_number: yup
			.string()
			.required('Claim number is required')
			.nullable(),
	status: yup
			.number()
			.required('Status is required')
			.nullable(),
	incident_type: yup
			.string()
			.required('Incident type is required')
			.nullable(),
	incident_specific_location: yup
			.string()
			.required('Incident location is required')
			.nullable(),
	claim_types: yup
			.string()
			.required('Claim types is required')
			.nullable(),
	insurance_description: yup
			.string()
			.required('Insurance description is required')
			.nullable(),
	claim_settlements: yup.array()
    .of(
      yup.object().shape({
		claim_amount: yup.string()
 		  .nullable(),
		  claim_type: yup.string()
		//   .required('Claim type is required')
		  .nullable(),
		  date_on_cheque: yup.string()
		//   .required('Claim date is required')
		  .nullable(),
		  claim_comment: yup.string()
		//   .required('Claim comment is required')
		  .nullable(),
      })
    )
  });
