import { createReducer } from "reduxsauce";
import Types from "./actionTypes";

export const INITIAL_STATE = {
  error: false,
  errors: null,
  statuses: null,
  claimTypes: null,
  loading: false,
  message: "",
  variant: "",
  insuranceReports: null,
  insuranceReportsLoading: false,
  insuranceReportsMessage: "",
  insuranceReportsData: null,
  insuranceReport: null,
  insuranceReportLoading: false,
  insuranceReportMessage: "",
  downloadLoading: false,
  downloadMessage: "",
  downloadVariant: "",
  downloadFile: null,
  claimSettlements: null,
  isXlsDownloading: false,
  xlsDownloadErrors: null,
};

export const getClaimStatus = (state = INITIAL_STATE, action) => {
  return { ...state, error: false };
};

export const getClaimStatusSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, statuses: action.responseData };
};

export const getClaimStatusFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, errors: action.error };
};

export const getClaimTypes = (state = INITIAL_STATE, action) => {
  return { ...state, error: false };
};

export const getClaimTypesSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, claimTypes: action.responseData };
};

export const getClaimTypesFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, typesLoading: false, errors: action.error };
};

export const createInsuranceReport = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, loading: true };
};

export const createInsuranceReportSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    insuranceReportsData: action.responseData,
    loading: false,
  };
};

export const createInsuranceReportFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, loading: false, errors: action.error };
};

export const getInsuranceReports = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, insuranceReportsLoading: true };
};

export const getInsuranceReportsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    insuranceReportsLoading: false,
    insuranceReports: action.responseData,
    insuranceReport: null,
  };
};

export const getInsuranceReportsFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: true,
    insuranceReportsLoading: false,
    insuranceReportsMessage: action.error,
  };
};

export const getSingleReport = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    insuranceReportLoading: true,
    insuranceReport: null,
  };
};

export const getSingleReportSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    insuranceReportLoading: false,
    insuranceReport: action.responseData,
  };
};

export const getSingleReportFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: true,
    insuranceReportLoading: false,
    insuranceReportMessage: action.error,
  };
};

export const updateReport = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, loading: true };
};

export const updateReportSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    insuranceReportsData: action.responseData,
    insuranceReport: action.responseData,
    loading: false,
  };
};

export const updateReportFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, loading: false, errors: action.error };
};

export const downloadReport = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, downloadLoading: true };
};

export const downloadReportSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    downloadMessage: "Success",
    downloadFile: action.responseData,
    downloadLoading: false,
    downloadVariant: "success",
  };
};

export const downloadreportFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: true,
    downloadLoading: false,
    downloadMessage: action.error,
    downloadVariant: "error",
  };
};

export const deleteAttachment = (state = INITIAL_STATE, action) => {
  return { ...state, error: false };
};

export const deleteAttachmentSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, error: false };
};

export const deleteAttachmentFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, errors: action.error };
};

export const deleteClaim = (state = INITIAL_STATE, action) => {
  return { ...state, error: false };
};

export const deleteClaimSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, claimSettlements: action.responseData };
};

export const deleteClaimFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, errors: action.error };
};

export const updateClaim = (state = INITIAL_STATE, action) => {
  return { ...state, error: false };
};

export const updateClaimSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, claimSettlements: action.responseData };
};

export const updateClaimFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, errors: action.error };
};

export const deleteReport = (state = INITIAL_STATE, action) => {
  return { ...state, error: false };
};

export const deleteReportSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, error: false };
};

export const deleteReportFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, errors: action.error };
};

export const addComment = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, loading: true };
};

export const addCommentSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, loading: false };
};

export const addCommentFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, errors: action.error, loading: false };
};

export const deleteOwnComment = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, loading: true };
};

export const deleteOwnCommentSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, loading: false };
};

export const deleteOwnCommentFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, errors: action.error, loading: false };
};

export const resetInsuranceReport = (state = INITIAL_STATE, action) => {
  return { ...state, insuranceReport: {} };
};

export const downloadInsurenceReportXls = (state = INITIAL_STATE, action) => {
  return { ...state, isXlsDownloading: true, xlsDownloadErrors: null };
};

export const downloadInsurenceReportXlsSuccess = (
  state = INITIAL_STATE,
  action
) => {
  return {
    ...state,
    isXlsDownloading: false,
    currentUploadedFile: action.responseData,
  };
};

export const downloadInsurenceReportXlsFailure = (
  state = INITIAL_STATE,
  action
) => {
  return {
    ...state,
    isXlsDownloading: false,
    xlsDownloadErrors: action.errors,
  };
};

export const HANDLERS = {
  [Types.GET_CLAIM_STATUS]: getClaimStatus,
  [Types.GET_CLAIM_STATUS_SUCCESS]: getClaimStatusSuccess,
  [Types.GET_CLAIM_STATUS_FAILURE]: getClaimTypesFailure,

  [Types.GET_CLAIM_TYPES]: getClaimTypes,
  [Types.GET_CLAIM_TYPES_SUCCESS]: getClaimTypesSuccess,
  [Types.GET_CLAIM_TYPES_FAILURE]: getClaimTypesFailure,

  [Types.CREATE_INSURANCE_REPORT]: createInsuranceReport,
  [Types.CREATE_INSURANCE_REPORT_SUCCESS]: createInsuranceReportSuccess,
  [Types.CREATE_INSURANCE_REPORT_FAILURE]: createInsuranceReportFailure,

  [Types.GET_INSURANCE_REPORTS]: getInsuranceReports,
  [Types.GET_INSURANCE_REPORTS_SUCCESS]: getInsuranceReportsSuccess,
  [Types.GET_INSURANCE_REPORTS_FAILURE]: getInsuranceReportsFailure,

  [Types.GET_SINGLE_REPORT]: getSingleReport,
  [Types.GET_SINGLE_REPORT_SUCCESS]: getSingleReportSuccess,
  [Types.GET_SINGLE_REPORT_FAILURE]: getSingleReportSuccess,

  [Types.UPDATE_REPORT]: updateReport,
  [Types.UPDATE_REPORT_SUCCESS]: updateReportSuccess,
  [Types.UPDATE_REPORT_FAILURE]: updateReportFailure,

  [Types.DOWNLOAD_REPORT]: downloadReport,
  [Types.DOWNLOAD_REPORT_SUCCESS]: downloadReportSuccess,
  [Types.DOWNLOAD_REPORT_FAILURE]: downloadreportFailure,

  [Types.DELETE_ATTACHMENT]: deleteAttachment,
  [Types.DELETE_ATTACHMENT_SUCCESS]: deleteAttachmentSuccess,
  [Types.DELETE_ATTACHMENT_FAILURE]: deleteAttachmentFailure,

  [Types.DELETE_CLAIM]: deleteClaim,
  [Types.DELETE_CLAIM_SUCCESS]: deleteClaimSuccess,
  [Types.DELETE_CLAIM_FAILURE]: deleteClaimFailure,

  [Types.UPDATE_CLAIM]: updateClaim,
  [Types.UPDATE_CLAIM_SUCCESS]: updateClaimSuccess,
  [Types.UPDATE_CLAIM_FAILURE]: updateClaimFailure,

  [Types.DELETE_OWN_COMMENT]: deleteOwnComment,
  [Types.DELETE_OWN_COMMENT_SUCCESS]: deleteOwnCommentSuccess,
  [Types.DELETE_OWN_COMMENT_FAILURE]: deleteOwnCommentFailure,

  [Types.DELETE_REPORT]: deleteReport,
  [Types.DELETE_REPORT_SUCCESS]: deleteReportSuccess,
  [Types.DELETE_REPORT_FAILURE]: deleteReportFailure,

  [Types.ADD_COMMENT]: addComment,
  [Types.ADD_COMMENT_SUCCESS]: addCommentSuccess,
  [Types.ADD_COMMENT_FAILURE]: addCommentFailure,

  [Types.RESET_INSURANCE_REPORT]: resetInsuranceReport,

  [Types.DOWNLOAD_INSURENCE_REPORT_XLS]: downloadInsurenceReportXls,
  [Types.DOWNLOAD_INSURENCE_REPORT_XLS_SUCCESS]:
    downloadInsurenceReportXlsSuccess,
  [Types.DOWNLOAD_INSURENCE_REPORT_XLS_FAILURE]:
    downloadInsurenceReportXlsFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
