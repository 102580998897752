import { createTypes } from "reduxsauce";

export default createTypes(
  `
  GET_CLAIM_STATUS
  GET_CLAIM_STATUS_SUCCESS
  GET_CLAIM_STATUS_FAILURE

  GET_CLAIM_TYPES
  GET_CLAIM_TYPES_SUCCESS
  GET_CLAIM_TYPES_FAILURE

  CREATE_INSURANCE_REPORT
  CREATE_INSURANCE_REPORT_SUCCESS
  CREATE_INSURANCE_REPORT_FAILURE

  GET_INSURANCE_REPORTS
  GET_INSURANCE_REPORTS_SUCCESS
  GET_INSURANCE_REPORTS_FAILURE

  GET_SINGLE_REPORT
  GET_SINGLE_REPORT_SUCCESS
  GET_SINGLE_REPORT_FAILURE

  UPDATE_REPORT
  UPDATE_REPORT_SUCCESS
  UPDATE_REPORT_FAILURE

  DOWNLOAD_REPORT
  DOWNLOAD_REPORT_SUCCESS
  DOWNLOAD_REPORT_FAILURE

  DELETE_ATTACHMENT
  DELETE_ATTACHMENT_SUCCESS
  DELETE_ATTACHMENT_FAILURE

  DELETE_CLAIM
  DELETE_CLAIM_SUCCESS
  DELETE_CLAIM_FAILURE

  UPDATE_CLAIM
  UPDATE_CLAIM_SUCCESS
  UPDATE_CLAIM_FAILURE

  ADD_COMMENT
  ADD_COMMENT_SUCCESS
  ADD_COMMENT_FAILURE

  DELETE_OWN_COMMENT
  DELETE_OWN_COMMENT_SUCCESS
  DELETE_OWN_COMMENT_FAILURE

  DELETE_REPORT
  DELETE_REPORT_SUCCESS
  DELETE_REPORT_FAILURE

  RESET_INSURANCE_REPORT

  DOWNLOAD_INSURENCE_REPORT_XLS
  DOWNLOAD_INSURENCE_REPORT_XLS_SUCCESS
  DOWNLOAD_INSURENCE_REPORT_XLS_FAILURE

`,
  {}
);
