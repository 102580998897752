import React from "react";
import {Layout, Row, Col} from "antd";
import logo from "../assets/web/Logo_white_Desktop.svg";
import "../styles/footer.less";

const { Footer } = Layout;
const PageFooter = (props) => {
  return (
      <>
      <Footer id="footer">
            <Row className="footer_row" >
                <Col xs={24} sm={24} md={8} id="first">
                   <img src={logo} alt="logo" />
                  
                </Col>
                <Col xs={24} sm={24} md={8} gutter={16}>
                <p className="top_text">CARE International</p>
                <p>Chemin de Balexert 7 - 9 <br/>Chatelaine Geneva 1219, <br/> Switzerland </p> <br />
                <p>Email: <a href="mailto:sscg@careinternational.org"> sscg@careinternational.org</a> <br/> 
                 <a href="https://www.careinternational.org" target="_blank">www.careinternational.org </a></p>
                </Col>
                <Col xs={24} sm={24} md={8} gutter={16}>
                <p className="top_text">CI Safety & Security <br/> Coordination Group</p>
                <p>Email: <a href="mailto:sscg@careinternational.org">sscg@careinternational.org</a></p>
                </Col>
            </Row>
            <p id="copyright">(C) Copyright 2019, SIMSon</p>
      </Footer>
        
        </>
  );
}

export default PageFooter;