import { call, put, takeLeading } from 'redux-saga/effects';
import { Creators, Types } from '../../redux/auth/actions';
import {
	login as loginApi,
	resetPassword as resetPasswordApi,
	changePassword as changePasswordApi,
	getUserDetails as userDetailsApi,
	GetSimsonHelpFile
} from '../../api/auth';
import { message } from 'antd';
import { history } from '../../../util/helpers/browserHistory';
import get from 'lodash/get';

export function* login(actions) {
	try {
		const { username, password } = actions;
		const response = yield call(loginApi, { email: username, password });
		const data = response.data;
		if (response.status === 200 || response.status === 201) {			

			// save token to localstorage
			localStorage.setItem("user-access", JSON.stringify(data));
			
			//get user details
			const user_response = yield call(userDetailsApi);
			if (user_response.status === 200 || user_response.status === 201) {

				console.log(user_response.data);
				localStorage.setItem("user-details", JSON.stringify(user_response.data));
				
				yield put(Creators.loginSuccess(data));
				if (get(history, 'location.state.from.pathname')) {
					const prevUrl = get(history, 'location.state.from.pathname');
					window.location.href = prevUrl;
				} else {
					window.location.href = '/home';
				}
			}		
			// navigate to home page
			//yield history.push('/app');
		} 	
	} catch (e) {
		const message = "Invalid Credentials";
		yield put(Creators.loginFailure(message));
	}
}

export function* resetPassword(actions) {
	try {
		const { email, captchaToken } = actions;
		console.log(actions);
		const response = yield call(resetPasswordApi, { email, captchaToken});
		const data = response.data;
		if (response.status === 200 || response.status === 201) {
			const message="An email has been sent to your inbox. Kindly click on the link in it to complete your password change."
			yield put(Creators.resetPasswordSuccess(message));
		} 	
	} catch (e) {
		var err = { e }.e.response;
		console.log(err);
		const message = err && err.data && Object.values(err.data).length > 0 ? Object.values(err.data)[0] : "An error occurred. please try again."; // set message to proper message returned by api
		yield put(Creators.resetPasswordFailure(message));
	}
}

export function* changePassword(actions) {
	try {
		const { password, token } = actions;
		console.log(actions);
		const response = yield call(changePasswordApi, { password, token });
		const data = response.data;
		if (response.status === 200 || response.status === 201) {
			const message="Password has been changed successfully.";
			yield put(Creators.changePasswordSuccess(message));
		} 	
	} catch (e) {
		var err = { e }.e.response;
		console.log(err);
		const message = err.data.email ? err.data.password[0] : "An error occurred. please try again."; // set message to proper message returned by api
		yield put(Creators.changePasswordFailure(message));
	}
}


export function* getHowToFIle() {
	try {
		const response = yield call(GetSimsonHelpFile);
		const data = response.data;

		if (response.status === 200 || response.status === 201) {
			yield put(Creators.GetHelpFileSuccess(data));
		} 	
	} catch (e) {
		message.error("Error downloading file.")
		yield put(Creators.GetHelpFileFailure(e));
	}
}

export function* logout() {
		// clear localStorage 
		localStorage.removeItem("user-access");
		localStorage.removeItem("user-details");

		yield put(Creators.logout());

		// redirect to landing page
		window.location.href = "/";
}

export function* watchLogin() {
  yield takeLeading(Types.LOGIN_REQUEST, login);
}

export function* watchResetPassword() {
	yield takeLeading(Types.RESET_PASSWORD_REQUEST, resetPassword);
}

export function* watchChangePassword() {
	yield takeLeading(Types.CHANGE_PASSWORD_REQUEST, changePassword);
}
  export function* watchLogout() {
	yield takeLeading(Types.LOGOUT, logout);
  }

  export function* watchGetHowToFile() {
	yield takeLeading(Types.GET_HELP_FILE, getHowToFIle);
  }