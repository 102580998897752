import { createTypes } from 'reduxsauce'
 
export default createTypes(`
  LOGIN_REQUEST
  LOGIN_SUCCESS
  LOGIN_FAILURE
 
  CHANGE_PASSWORD_REQUEST
  CHANGE_PASSWORD_SUCCESS
  CHANGE_PASSWORD_FAILURE

  RESET_PASSWORD_REQUEST
  RESET_PASSWORD_SUCCESS
  RESET_PASSWORD_FAILURE

  GET_HELP_FILE
  GET_HELP_FILE_SUCCESS
  GET_HELP_FILE_FAILURE
 
  LOGOUT
`, {});
