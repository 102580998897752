import moment from "moment";

import {
  apiRequest,
  apiRequestFormData,
  apiRequestOthers,
} from "../apiRequest";
import {
  GET_CLAIM_STATUS_ENDPOINT,
  GET_CLAIM_TYPES_ENDPOINT,
  INSURANCE_REPORT_ENDPOINT,
} from "../urls";
import { pageSize } from "../../../util/helpers/enum";

export const getClaimTypes = () => {
  return apiRequest("GET", GET_CLAIM_TYPES_ENDPOINT, {});
};

export const getClaimStatus = () => {
  return apiRequest("GET", GET_CLAIM_STATUS_ENDPOINT, {});
};

export const getInsuranceReports = (
  page,
  search,
  status,
  office,
  from_date,
  end_date
) => {
  let url = `${INSURANCE_REPORT_ENDPOINT}?page_size=${pageSize}`;
  if (page) {
    url = `${url}&page=${page}`;
  }
  if (search) {
    url = `${url}&search=${search}`;
  }
  if (status) {
    url = `${url}&status=${status}`;
  }
  if (office) {
    url = `${url}&office=${office}`;
  }
  if (from_date) {
    url = `${url}&from_date=${moment(from_date).format("YYYY-MM-DD")}`;
  }
  if (end_date) {
    url = `${url}&end_date=${moment(end_date).format("YYYY-MM-DD")}`;
  }
  return apiRequest("GET", url, {});
};

export const downloadInsurenceReportXls = (
  search,
  status,
  office,
  from_date,
  end_date
) => {
  let url = `${INSURANCE_REPORT_ENDPOINT}?`;
  if (search) {
    url = `${url}&search=${search}`;
  }
  if (status) {
    url = `${url}&status=${status}`;
  }
  if (office) {
    url = `${url}&office=${office}`;
  }
  if (from_date) {
    url = `${url}&from_date=${moment(from_date).format("YYYY-MM-DD")}`;
  }
  if (end_date) {
    url = `${url}&end_date=${moment(end_date).format("YYYY-MM-DD")}`;
  }

  return apiRequestOthers(
    "GET",
    url,
    {},
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "arraybuffer"
  );
};

export const getSingleReport = (id) => {
  return apiRequest("GET", `${INSURANCE_REPORT_ENDPOINT}${id}/`, {});
};

export const downloadReport = (id) => {
  return apiRequestOthers(
    "GET",
    `${INSURANCE_REPORT_ENDPOINT}${id}`,
    {},
    "application/pdf",
    "arraybuffer"
  );
};

export const updateReport = (data) => {
  return apiRequest("PATCH", `${INSURANCE_REPORT_ENDPOINT}${data.id}/`, data);
};

export const createInsuranceReport = (data) => {
  return apiRequest("POST", INSURANCE_REPORT_ENDPOINT, data);
};

export const uploadAttachment = (report_id, data) => {
  console.log(report_id);
  return apiRequestOthers(
    "POST",
    `${INSURANCE_REPORT_ENDPOINT}${report_id}/attachments/`,
    data,
    "*/*"
  );
};

export const deleteAttachment = (report_id, attachment_id) => {
  return apiRequest(
    "DELETE",
    `${INSURANCE_REPORT_ENDPOINT}${report_id}/attachments/${attachment_id}/`,
    {}
  );
};

export const deleteClaimSettlement = (report_id, claim_id) => {
  return apiRequest(
    "DELETE",
    `${INSURANCE_REPORT_ENDPOINT}${report_id}/claim-settlements/${claim_id}/`,
    {}
  );
};

export const updateClaimSettlement = (report_id, claim_id, data) => {
  return apiRequest(
    "PATCH",
    `${INSURANCE_REPORT_ENDPOINT}${report_id}/claim-settlements/${claim_id}/`,
    data
  );
};

export const getClaimSettlement = (report_id) => {
  return apiRequest(
    "GET",
    `${INSURANCE_REPORT_ENDPOINT}${report_id}/claim-settlements/`,
    {}
  );
};

export const addComment = (report_id, data) => {
  return apiRequest(
    "POST",
    `${INSURANCE_REPORT_ENDPOINT}${report_id}/comments/`,
    data
  );
};

export const deleteOwnComment = (report_id, comment_id) => {
  return apiRequest(
    "DELETE",
    `${INSURANCE_REPORT_ENDPOINT}${report_id}/comments/${comment_id}`,
    {}
  );
};

export const deleteInsuranceReport = (report_id) => {
  return apiRequest("DELETE", `${INSURANCE_REPORT_ENDPOINT}${report_id}/`, {});
};
