import {apiRequest, api, apiRequestOthers} from "../apiRequest";
import { LOGIN_ENDPOINT, 
		CHANGE_PASSWORD_ENDPOINT, 
		RESET_PASSWORD_ENDPOINT, 
		USER_PROFILE_ENDPOINT,
		HOW_TO_USE_SIMSON_ENDPOINT } from '../urls';

export const login = (data) => { 
	// where data is { email, password }
	return api('POST', LOGIN_ENDPOINT, data);
}

export const resetPassword = (data) => { 
	console.log(data)
	return api('POST', RESET_PASSWORD_ENDPOINT, data);
}

export const changePassword = (data) => {
	return api('POST', CHANGE_PASSWORD_ENDPOINT, data);
}

export const getUserDetails = () => {
	return apiRequest('GET', USER_PROFILE_ENDPOINT, {})
}

export const GetSimsonHelpFile = () => { 
	return api('GET', HOW_TO_USE_SIMSON_ENDPOINT, {});
}