import { createTypes } from "reduxsauce";

export default createTypes(
  `
	FETCH_INCIDENTS
  FETCH_INCIDENTS_SUCCESS
  FETCH_INCIDENTS_FAILURE

  FETCH_INCIDENT
  FETCH_INCIDENT_SUCCESS
  FETCH_INCIDENT_FAILURE

  CREATE_INCIDENT
  CREATE_INCIDENT_SUCCESS
  CREATE_INCIDENT_FAILURE

  MOVE_NEXT
  MOVE_BACK
  RESET_STEPS
  SET_DRAFT_FOR_EDIT

  START_AUTO_SAVE
  STOP_AUTO_SAVE

  UPDATE_INCIDENT
  UPDATE_INCIDENT_SUCCESSFUL
  UPDATE_INCIDENT_FAILURE

  SAVE_DRAFT
  SAVE_DRAFT_SUCCESSFUL
  SAVE_DRAFT_FAILED

  RESET_FORM

  SET_COORDINATES

  AUTO_COMPLETE
  AUTO_COMPLETE_SUCCESSFUL
  AUTO_COMPLETE_FAILURE

  RESET_AUTO_COMPLETE

  UPLOAD_FILE
  UPLOAD_FILE_SUCCESS
  UPLOAD_FILE_FAILURE

  DOWNLOAD_INCIDENT_REPORT
  DOWNLOAD_INCIDENT_REPORT_SUCCESS
  DOWNLOAD_INCIDENT_REPORT_FAILURE

  FETCH_CATEGORIES
  FETCH_CATEGORIES_SUCCESS
  FETCH_CATEGORIES_FAILURE

  APPROVE_INCIDENT
  APPROVE_INCIDENT_SUCCESS
  APPROVE_INCIDENT_FAILURE

  POST_COMMENT
  POST_COMMENT_SUCCESS
  POST_COMMENT_FAILURE

  DOWNLOAD_PDF
  DOWNLOAD_PDF_SUCCESS
  DOWNLOAD_PDF_FAILURE

  SAVE_FILTERS

  CREATE_STAFF_IMPACT_DETAILS
  CREATE_STAFF_IMPACT_DETAILS_SUCCESS
  CREATE_STAFF_IMPACT_DETAILS_FAILURE

  UPDATE_STAFF_IMPACT_DETAILS
  UPDATE_STAFF_IMPACT_DETAILS_SUCCESS
  UPDATE_STAFF_IMPACT_DETAILS_FAILURE

  DELETE_STAFF_IMPACT_DETAILS
  DELETE_STAFF_IMPACT_DETAILS_SUCCESS
  DELETE_STAFF_IMPACT_DETAILS_FAILURE

  DELETE_INCIDENT
  DELETE_INCIDENT_SUCCESS
  DELETE_INCIDENT_FAILURE

  FETCH_STAFF_IMPACT_DETAILS
  FETCH_STAFF_IMPACT_DETAILS_SUCCESS
  FETCH_STAFF_IMPACT_DETAILS_FAILURE

  DELETE_INCIDENT_COMMENT
  DELETE_INCIDENT_COMMENT_SUCCESS
  DELETE_INCIDENT_COMMENT_FAILURE

  FETCH_STATISTICS
  FETCH_STATISTICS_SUCCESS
  FETCH_STATISTICS_FAILURE

  DELETE_INCIDENT_FILE
  DELETE_INCIDENT_FILE_SUCCESS
  DELETE_INCIDENT_FILE_FAILURE
  
`,
  {}
);
