import { createReducer } from 'reduxsauce'
import Types from './actionTypes'
 
export const INITIAL_STATE = 
  { 
    error: false, 
    errors: null,
    roles: null, 
    userLoading: false,
    userMessage: "",
    userVariant: "",
    resetAcctMessage: "",
    organization: null,
    message: "",
    variant: "",
    loading: false,
    userDetails: null,
    isUserUpdated: false
  }


export const getOrganization = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, loading: true }
}
 
export const getOrganizationSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, organization: action.responseData, loading: false }
}
 
export const getOrganizationFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, loading: false, errors: action.error }
}

export const getRoles = (state = INITIAL_STATE, action) => {
    return { ...state, error: false, loading: true }
}
   
export const getRolesSuccess = (state = INITIAL_STATE, action) => {
    return { ...state, error: false, roles: action.responseData, loading: false }
}
   
export const getRolesFailure = (state = INITIAL_STATE, action) => {
    return { ...state, error: true, loading: false, message: action.error }
}

export const getUser = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, loading: true, isUserUpdated: false, userMessage: "" }
}
 
export const getUserSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, userDetails: action.responseData, loading: false }
}
 
export const getUserFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, loading: false, message: action.error }
}

export const createUser = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, userLoading: true }
}
 
export const createUserSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, userMessage: action.responseData, userLoading: false, userVariant: "success" }
}
 
export const createUserFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, userLoading: false, userMessage: action.error, userVariant: "error" }
}

export const updateUser = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, userLoading: true }
}
 
export const updateUserSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, userLoading: false, isUserUpdated: true }
}
 
export const updateUserFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, userLoading: false, userMessage: action.error, userVariant: "error" }
}

export const resetNewAccount = (state = INITIAL_STATE, action) => {
  return { ...state, error: false }
}
 
export const resetNewAccountSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, resetAcctMessage: action.responseData }
}
 
export const resetNewAccountFailure = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, resetAcctMessage: action.error }
}
  



export const HANDLERS = {
  [Types.GET_ORGANIZATION]: getOrganization,
  [Types.GET_ORGANIZATION_SUCCESS]: getOrganizationSuccess,
  [Types.GET_ORGANIZATION_FAILURE]: getOrganizationFailure,
  [Types.GET_ROLES]: getRoles,
  [Types.GET_ROLES_SUCCESS]: getRolesSuccess,
  [Types.GET_ROLES_FAILURE]: getRolesFailure,
  [Types.GET_USER]: getUser,
  [Types.GET_USER_SUCCESS]: getUserSuccess,
  [Types.GET_USER_FAILURE]: getUserFailure,
  [Types.CREATE_USER]: createUser,
  [Types.CREATE_USER_SUCCESS]: createUserSuccess,
  [Types.CREATE_USER_FAILURE]: createUserFailure,
  [Types.UPDATE_USER]: updateUser,
  [Types.UPDATE_USER_SUCCESS]: updateUserSuccess,
  [Types.UPDATE_USER_FAILURE]: updateUserFailure,
  [Types.RESET_NEW_ACCOUNT]: resetNewAccount,
  [Types.RESET_NEW_ACCOUNT_SUCCESS]: resetNewAccountSuccess,
  [Types.RESET_NEW_ACCOUNT_FAILURE]: resetNewAccountFailure
}
 
export default createReducer(INITIAL_STATE, HANDLERS);
